import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Col, Container, Row } from 'reactstrap'
import { bindActionCreators } from 'redux'
import { changePage } from '@Store/template/actions'
import Header from '@Components/Layout/Elements/Header'
import Modals from '@Modals'

class NonAuthHybrid extends React.Component {

	constructor (props) {
		super(props)
		this.state = {
			page: props.page,
			user: props.user,
			isAuthorized: props.user.fullName,
		}
	}

	componentDidUpdate (prevProps, prevState, snapshot) {
		if (this.props.page !== prevProps.page) this.setState({ page: this.props.page })
		if (this.props.user !== prevProps.user) {
			const newState = { user: this.props.user }
			if (this.props.user && this.props.user.fullName) newState.isAuthorized = true
			this.setState(newState)
		}
	}

	render () {
		const { page, isAuthorized } = this.state
		if (!page) return null

		return (
			<React.Fragment>
				<div className="main-content">
					<div className="page-content" style={{ overflowY: 'auto' }} id="page-scroll">
						<Container fluid style={{ height: '100%' }} id={`page-${page.name}`}>
							<Row>
								{/* MOBILE */}
								<Col
									xl={{ size: 4, offset: 4 }}
									lg={{ size: 6, offset: 3 }}
									md={{ size: 8, offset: 2 }}
									sm={{ size: 10, offset: 1 }}
									xs={12}
									className="d-flex flex-column"
								>
									<Header
										clickLogo={() => {
											if (isAuthorized) {
												return (window.location.href = process.env.REACT_APP_USER_AREA_URL)
											} else {
												return (window.location.href = process.env.REACT_APP_WIRAKI_WEBSITE)
											}
										}}/>
									{React.cloneElement(this.props.children, { isAuthorized })}
								</Col>
							</Row>
						</Container>
					</div>
				</div>
				{/* LOAD MODALS */}
				<Modals.ModalShareCertificate/>
			</React.Fragment>
		)
	}
}

const mapStateToProps = state => {
	return {
		page: state.Template.page,
		user: state.User.user,
	}
}
const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			changePage
			// add other watcher sagas to this object to map them to props
		},
		dispatch
	)
}
NonAuthHybrid.propTypes = {
	children: PropTypes.any,
	location: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NonAuthHybrid))
