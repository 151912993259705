import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import Countdown from '@Atoms/countdown'
import { Trans, withTranslation } from 'react-i18next'
import moment from 'moment'
import { Card, CardBody } from 'reactstrap'
import WarningRoundedIcon from '@mui/icons-material/WarningRounded'
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded'
import { resendEmailVerify } from '@Store/actions'

class CardCountdownNotVerified extends React.Component {

	constructor (props) {
		super(props)
		this.state = {
			user: props.user,
			network: props.network,
			colorInverted: props.colorInverted || false,
			contest: props.contest,
		}
	}

	componentDidUpdate (prevProps, prevState, snapshot) {
		if (!_.isEqual(this.props.user, prevProps.user)) this.setState({ user: this.props.user })
		if (!_.isEqual(this.props.contest, prevProps.contest)) this.setState({ contest: this.props.contest })
	}

	render () {

		const { user, network } = this.state

		if (
			!user ||
			!network ||
			!network.university
		) return null

		if (user.verified) return null
		if (moment(user.bonusVerifyBefore) < moment()) return null

		return (
			<Card className="align-self-center card-countdown bg-primary-yellow">
				<CardBody className="d-flex flex-column">
					<h3 className="title mb-2"><Trans i18nKey="cardCountdownNotVerified.title"/></h3>
					<span className="caption-small text-center"><Trans i18nKey="cardCountdownNotVerified.subtitle"/></span>
					<div className="text-center mb-2">
						<Countdown
							compact
							className={`countdown countdown-bordered text-primary bg-white rounded`}
							date={user.bonusVerifyBefore}
						/>
					</div>
					<div className="d-flex align-items-center">
						{user.receivedVotes ? (
							<React.Fragment>
								<StarBorderRoundedIcon className="text-warning me-2"/>
								<div className="caption-small">
									<Trans i18nKey="cardCountdownNotVerified.verifyEmailGotVoted"/>
								</div>
							</React.Fragment>
						) : (
							<React.Fragment>
								<WarningRoundedIcon className="font-size-14 text-primary me-2"/>
								<div className="caption-small">
									<Trans i18nKey="cardCountdownNotVerified.verifyEmail" values={{ university: network.university.name }}/>
								</div>
							</React.Fragment>
						)}
					</div>


					<div className="d-flex align-items-center">
						<WarningRoundedIcon className="font-size-14 text-primary me-2"/>
						<span className="caption-small">
								<Trans i18nKey="cardCountdownNotVerified.inviteToActivateVotes"/>
							</span>
					</div>
					<div className="d-flex align-items-center flex-column">
						<div
							className="link mt-2 mb-1"
							onClick={() => this.props.resendEmailVerify()}
						>
							<Trans i18nKey="cardCountdownNotVerified.sendEmail" />
						</div>
						<div className="caption-small">to: {user.email}</div>
					</div>
				</CardBody>
			</Card>
		)
	}
}

const mapStateToProps = state => {
	return {
		user: state.User.user,
		network: state.Network.network,
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			resendEmailVerify,
			// add other watcher sagas to this object to map them to props
		},
		dispatch
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(CardCountdownNotVerified)))
