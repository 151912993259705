export const LOAD_INITIAL_DATA = 'LOAD_INITIAL_DATA'


export const LOAD_INVITE_CODE = 'LOAD_INVITE_CODE'

export const SAVE_INVITE_CODE = 'SAVE_INVITE_CODE'

export const ACTIVATE_INVITE_CODE = 'ACTIVATE_INVITE_CODE'

export const SUBMIT_REQUEST_REWARD = 'SUBMIT_REQUEST_REWARD'
export const RESULT_SUBMIT_REQUEST_REWARD = 'RESULT_SUBMIT_REQUEST_REWARD'

export const RESEND_EMAIL_VERIFY = 'RESEND_EMAIL_VERIFY'
export const EMAIL_VERIFY_SENT = 'EMAIL_VERIFY_SENT'

export const REQUEST_REWARD = 'REQUEST_REWARD'

export const TUTORIAL_CLAIM_ENABLE = 'TUTORIAL_CLAIM_ENABLE'
export const TUTORIAL_CLAIM_DISABLE = 'TUTORIAL_CLAIM_DISABLE'
