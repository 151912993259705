export const MODAL_SHARE = 'MODAL_SHARE'
//export const MODAL_WELCOME = 'MODAL_WELCOME'
export const MODAL_MERITOCRACY_EXPLAIN = 'MODAL_MERITOCRACY_EXPLAIN'
export const MODAL_AMBASSADOR = 'MODAL_AMBASSADOR'
export const MODAL_VERIFY_EMAIL_SENT = 'MODAL_VERIFY_EMAIL_SENT'
export const MODAL_GOAL = 'MODAL_GOAL'
export const MODAL_RANK = 'MODAL_RANK'
export const MODAL_RANK_CONFIDENCE = 'MODAL_RANK_CONFIDENCE'
export const MODAL_CHECK_EMAIL = 'MODAL_CHECK_EMAIL'
export const MODAL_WITHDRAW = 'MODAL_WITHDRAW'
export const MODAL_WELCOME_VERIFY_EMAIL = 'MODAL_WELCOME_VERIFY_EMAIL'
export const MODAL_FIRST_VOTE = 'MODAL_FIRST_VOTE'
export const MODAL_VOTE = 'MODAL_VOTE'
export const MODAL_VOTE_UNLOCKED = 'MODAL_VOTE_UNLOCKED'
export const MODAL_LINK_INVITE_TO_VOTE = 'MODAL_LINK_INVITE_TO_VOTE'
export const MODAL_ADDITIONAL_VOTE_LOCKED = 'MODAL_ADDITIONAL_VOTE_LOCKED'
export const MODAL_CONTEST = 'MODAL_CONTEST'
export const MODAL_VOTE_URGENCY = 'MODAL_VOTE_URGENCY'
export const MODAL_SHARE_CERTIFICATE = 'MODAL_SHARE_CERTIFICATE'

// Modal Forgot Password
export const MODAL_FORGOT_PASSWORD = 'MODAL_FORGOT_PASSWORD'
export const MODAL_FORGOT_PASSWORD_CONFIRM = 'MODAL_FORGOT_PASSWORD_CONFIRM'
