import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { Card, CardBody } from 'reactstrap'
import _ from 'lodash'
import { CARD_LEADERBOARD } from '@Constants/constants'
import AskNotification from '@Atoms/askNotification'
import { Trans } from 'react-i18next'

class CardLeaderboardUnlock extends React.Component {

	constructor (props) {
		super(props)
		this.state = {
			network: props.network
		}
	}

	componentDidUpdate (prevProps, prevState, snapshot) {
		if (!_.isEqual(this.props.network, prevProps.network)) this.setState({ network: this.props.network })
	}

	render () {
		const { network } = this.state

		if (
			!network ||
			!network.participants
		) return null

		const partecipantsVerified = network.participants.filter(p => p.verified)

		return (
			<Card className={`d-flex flex-direction-row align-items-center card-short ${this.state.className}`}>
				<CardBody className="text-center pt-3">
					<div className="caption"><Trans i18nKey="cardLeaderboardUnlock.additionalParticipants" /></div>
					<div className="caption-big text-primary my-3 font-weight-bold" style={{ fontSize: '34px' }}>
						{CARD_LEADERBOARD.NUM_PARTICIPANTS_TO_UNLOCK - partecipantsVerified.length}
					</div>
					<div className="caption"><Trans i18nKey="cardLeaderboardUnlock.certificates" /></div>
					<AskNotification>
						<hr/>
						<div className="link">
							<Trans i18nKey="cardLeaderboardUnlock.notify" />
						</div>
					</AskNotification>
				</CardBody>
			</Card>
		)
	}
}

const mapStateToProps = state => {
	return {
		network: state.Network.network,
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			// add other watcher sagas to this object to map them to props
		},
		dispatch
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CardLeaderboardUnlock))
