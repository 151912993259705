import React, { useEffect, useState } from 'react'
import { Col, Row } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Trans, useTranslation } from 'react-i18next'
import { loginUser } from '@Store/auth/login/actions'
import { useForm } from 'react-hook-form'
import InputBox from '@Molecules/InputBox'
import Input from '@Atoms/Input'
import { toggleModal } from '@Store/actions'
import { MODAL_FORGOT_PASSWORD } from '@Modals/ModalInfos/modals'
import Modals from '@Modals/index'
import { Helmet } from 'react-helmet'
import routes from '@Routes/routeConfig'

const Login = props => {

	const { t } = useTranslation()
	const dispatch = useDispatch()

	// handleValidSubmit
	const submit = ({ inputEmail: email, inputPassword: password }) => {
		dispatch(loginUser({ email, password }, props.history))
	}

	const errorData = useSelector(state => state.Login.error)

	const [error, setError] = useState(errorData)

	useEffect(() => setError(errorData), [errorData])

	useEffect( () => {
		window.dataLayer.push({
			event: 'pageview',
			page: { url: routes.LOGIN.route, title: 'Page Login' }
		})
	}, [])

	const {
		register,
		handleSubmit,
		formState: { errors },
		formState,
		setValue,
		watch,
	} = useForm({
		mode: 'onBlur',
		reValidateMode: 'onChange',
	})

	return (
		<React.Fragment>
			<Helmet>
				<title>Login | Wiraki</title>
			</Helmet>
			<div className="account-pages my-4 pt-sm-5">
				<Row className="justify-content-center">
					<Col xs={12} sm={8} md={5} lg={4} xl={3}>
						<div className="h2 mb-3">👋<br/><Trans i18nKey="pageLogin.title"/></div>

						<div className="container-form">
							<form onSubmit={handleSubmit(submit)}>
								<InputBox
									errors={error || errors.inputEmail}
									touched={formState.touchedFields.inputEmail || !!watch().inputEmail}
									label={t(`pageLogin.inputEmail.label`)}
								>
									<Input
										required
										register={register}
										name="inputEmail"
										errors={error || errors.inputEmail}
										touched={formState.touchedFields.inputEmail || !!watch().inputEmail}
										placeholder={t(`pageLogin.inputEmail.placeholder`)}
										registerOptions={{ required: true, validate: async (value) => { return /^.*@.*\..*$/.test(value)} }}
										type="email"/>
								</InputBox>

								<InputBox
									errors={error}
									touched={formState.touchedFields.inputPassword || !!watch().inputPassword}
									label={t(`pageLogin.inputPassword.label`)}
								>
									<Input
										required
										register={register}
										name="inputPassword"
										errors={error}
										touched={formState.touchedFields.inputPassword || !!watch().inputPassword}
										placeholder={t(`pageLogin.inputPassword.placeholder`)}
										inputErrorMessage={error}
										registerOptions={{ required: true }}
										type="password"/>
								</InputBox>
								<div
									className="mb-4 text-secondary clickable" onClick={
									() => dispatch(toggleModal(MODAL_FORGOT_PASSWORD, { data: watch().inputEmail }))
								}>
									<Trans i18nKey="pageLogin.forgotPassword"/>
								</div>

								<button className="btn btn-primary btn-rounded btn-block w-100" type="submit">
									<Trans i18nKey="pageLogin.button"/>
								</button>

								<div className="text-center">
									<Trans
										i18nKey="pageLogin.goVote"
										components={{
											a: <a href={process.env.REACT_APP_VOTE_URL}/>
										}}/>
								</div>
							</form>
						</div>
					</Col>
				</Row>
			</div>
			<Modals.ModalForgotPassword/>
			<Modals.ModalForgotPasswordConfirm/>
		</React.Fragment>
	)
}

export default Login
