import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import { toggleModal } from '@Store/template/actions'
import { Card, CardBody } from 'reactstrap'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import { Trans } from 'react-i18next'
import HourglassEmptyRoundedIcon from '@mui/icons-material/HourglassEmptyRounded'
import Explorer from '@Icons/Explorer'
import Badge from '@Atoms/badge'
import { MODAL_GOAL } from '@Modals/ModalInfos/modals'
import { openShare } from '@Store/actions'
import { NUM_PARTICIPANTS_TO_SHOW } from '@Constants/constants'

class CardParticipantsList extends React.Component {

	constructor (props) {
		super(props)
		this.state = {
			user: props.user,
			network: props.network,
		}
	}

	componentDidUpdate (prevProps, prevState, snapshot) {
		if (!_.isEqual(this.props.network, prevProps.network)) this.setState({ network: this.props.network })
		if (!_.isEqual(this.props.user, prevProps.user)) this.setState({ user: this.props.user })
	}

	addFakeParticipants (count) {
		const listFakeNames = ['Carlo Martini', 'Luca Mastrostefano', 'Antonio Romano', 'Sbirulino Formaggino']
		let elems = []
		for (let i = 0; i < count; i++) {
			elems.push(
				<Participant
					key={i}
					veritifed={false}
					icon=""
					title=""
					name={listFakeNames[i]}
					isMyself={false}
					style={{ filter: 'blur(3px)' }}
				/>
			)
		}
		return elems.length === 0 ? null : elems
	}

	render () {
		const { network, user } = this.state

		if (
			!network ||
			!user
		) return null

		const partecipantsVerified = network.participants.filter(p => p.verified)

		return (
			<Card className="card-list-participants">
				<CardBody className="d-flex flex-column">
					<h3 className="title mb-2">
						<Trans i18nKey="cardListParticipants.title"/>
						{partecipantsVerified.length >= NUM_PARTICIPANTS_TO_SHOW && (
							<React.Fragment>&nbsp;{partecipantsVerified.length}</React.Fragment>
						)}
					</h3>
					{!user.verified && (
						<div className="caption mb-2"><Trans i18nKey="cardListParticipants.verifyEmail"/></div>
					)}

					<table className="w-100 clickable" onClick={() => this.props.toggleModal(MODAL_GOAL)}>
						<thead>
						<tr>
							<th/>
							<th/>
							<th/>
							<th/>
						</tr>
						</thead>
						<tbody>
						{network.participants.map((p, k) => {

							return (
								<Participant
									key={k+10}
									verified={p.verified}
									icon={p.icon}
									title={p.title}
									name={p.name}
									isMyself={p.name === user.fullName}
									isHired={p.hired}
								/>
							)
						})}


						{/* If list participants is shorter than NUM_PARTICIPANTS_TO_SHOW, add blurred names */}
						{this.addFakeParticipants(NUM_PARTICIPANTS_TO_SHOW - network.participants.length)}
						</tbody>
					</table>
					{partecipantsVerified.length < NUM_PARTICIPANTS_TO_SHOW && (
						<div className="text-center my-2">
							<Explorer style={{ width: '75px' }}/>
							<div className="caption mt-2">
								<Trans i18nKey="cardListParticipants.congrats"/>
							</div>
							<div
								className="link mt-2"
								onClick={() => this.props.openShare()}
							>
								<Trans i18nKey="cardListParticipants.anyColleague"/>
							</div>
						</div>
					)}
				</CardBody>
			</Card>
		)
	}
}

const Participant = props => {

	return (
		<tr style={props.style}>
			<td className={`text-center ${props.verified ? '' : 'text-gray-light'}`}>
				<PersonOutlineOutlinedIcon style={{ width: '18px' }}/>
			</td>
			<td className={`text-start caption ${props.verified ? '' : 'text-gray-light'}`}>
				{props.isHired && (
					<span className="font-weight-bold text-success">{props.name}&nbsp;🎉</span>
				)}
				{props.isMyself && !props.isHired && (
					<span className="font-weight-bold">{props.name}</span>
				)}
				{!props.isMyself && !props.isHired && (
					<React.Fragment>{props.name}</React.Fragment>
				)}
			</td>
			<td className={`text-end caption ${props.verified ? '' : 'text-gray-light'}`}>
				{props.isMyself ? (
					<span className="font-weight-bold">
						{props.verified ?
							<Trans i18nKey={`goals.${props.title}`}/>
							:
							<Trans i18nKey="cardListParticipants.notVerified"/>}
					</span>
				) : (
					<React.Fragment>
						{props.verified ?
							<Trans i18nKey={`goals.${props.title}`}/>
							:
							<Trans i18nKey="cardListParticipants.notVerified"/>
						}
					</React.Fragment>
				)}
			</td>
			<td className={`text-center ${props.verified ? '' : 'text-gray-light'}`}>
				{!props.verified && (
					<HourglassEmptyRoundedIcon style={{ width: '16px' }}/>
				)}
				{props.verified && props.icon && (
					<div className="d-inline-flex">
						<Badge color={props.icon} small/>
					</div>
				)}
			</td>
		</tr>
	)
}

const mapStateToProps = state => {
	return {
		network: state.Network.network,
		user: state.User.user,
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			toggleModal,
			openShare,
			// add other watcher sagas to this object to map them to props
		},
		dispatch
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CardParticipantsList))
