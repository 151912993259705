import { BALANCE_RESET_DATA, LOAD_BALANCE_DATA, REQUEST_WITHDRAW, REQUEST_WITHDRAW_ERROR, REQUEST_WITHDRAW_SUCCESS } from '@Store/balance/actionTypes'

export const loadBalanceData = () => ({
	type: LOAD_BALANCE_DATA,
	payload: null,
})

export const requestWithdraw = () => ({
	type: REQUEST_WITHDRAW,
	payload: null
})
export const requestWithdrawError = message => ({
	type: REQUEST_WITHDRAW_ERROR,
	payload: message
})
export const requestWithdrawSuccess = () => ({
	type: REQUEST_WITHDRAW_SUCCESS,
	payload: null
})

export const balanceResetData = () => ({
	type: BALANCE_RESET_DATA,
	payload: null
})
