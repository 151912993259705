import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { Card, CardBody } from 'reactstrap'
import _ from 'lodash'
import { Trans, withTranslation } from 'react-i18next'

class CardNotify extends React.Component {

	constructor (props) {
		super(props)
		this.state = {
			user: props.user,
		}
	}

	componentDidUpdate (prevProps, prevState, snapshot) {
		if (!_.isEqual(this.props.user, prevProps.user)) this.setState({ user: this.props.user })
	}

	render () {

		if (!this.state.user) return null

		return (
			<React.Fragment>
				<Card className="align-self-center card-share">
					<CardBody className="d-flex flex-column">
						<h3 className="title mb-2">
							<Trans
								i18nKey="cardNotify.title"
							/>
						</h3>

					</CardBody>
				</Card>
			</React.Fragment>
		)
	}
}

const mapStateToProps = state => {
	return {
		user: state.User.user,
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			// add other watcher sagas to this object to map them to props
		},
		dispatch
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(CardNotify)))
