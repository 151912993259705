import React from 'react'
import Button from '@Atoms/Button'
import { Trans } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { closeWelcomePage } from '@Store/template/actions'

const Welcome = props => {

	const dispatch = useDispatch()

	return (
		<div
			className="main-content"
			style={{
				position: 'fixed',
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				backgroundColor: '#F5F5F5',
				zIndex: 100
			}}>
			<div className="page-content d-flex justify-content-center">
				<div className="text-center wm-400" style={{ padding: '48px 48px' }}>
					<h5 className="mb-1"><Trans i18nKey="welcome.title"/></h5>
					<p className="mb-4"><Trans i18nKey="welcome.text1"/></p>
					<p className="mb-4" style={{ paddingBottom: '42px' }}><Trans i18nKey="welcome.text2"/></p>
					<div className="d-flex justify-content-center">
						<Button
							color="primary"
							className="px-4 d-flex"
							style={{ alignItems: 'center' }}
							onClick={() => dispatch(closeWelcomePage())}
						>
							<Trans i18nKey="welcome.button" components={{ icon: <span style={{ fontSize: '12px' }}/> }}/>
						</Button>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Welcome
