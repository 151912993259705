import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Modal from '@Modals/Modal'
import _ from 'lodash'
import { MODAL_WELCOME_VERIFY_EMAIL } from '@Modals/ModalInfos/modals'
import WarningRoundedIcon from '@mui/icons-material/WarningRounded'
import Countdown from '@Atoms/countdown'
import Button from '@Atoms/Button'
import { loadUserData } from '@Store/user/actions'
import { Trans } from 'react-i18next'
import { resendEmailVerify } from '@Store/global/actions'

class ModalWelcomeVerifyEmail extends React.Component {

	constructor (props) {
		super(props)
		this.state = {
			user: props.user,
			network: props.network,
		}
	}

	componentDidUpdate (prevProps, prevState, snapshot) {
		if (!_.isEqual(this.props.user, prevProps.user)) this.setState({ user: this.props.user })
		if (!_.isEqual(this.props.network, prevProps.network)) this.setState({ network: this.props.network })
	}

	render () {

		const { user, network } = this.state

		if (
			!user ||
			!network ||
			!network.university
		) return null

		return (
			<Modal type="modal-mobile-center" name={MODAL_WELCOME_VERIFY_EMAIL} centered dismissable={false}>
				<h3 className="title mb-3"><Trans i18nKey="modalWelcomeVerifyEmail.title"/></h3>
				<p className="caption mb-3">
					<Trans
						i18nKey="modalWelcomeVerifyEmail.subtitle"
						values={{
							email: user.email,
							university: network.university.name
						}}
					/>
				</p>
				<div className="text-center mt-3 mb-1">
					<WarningRoundedIcon className="me-2 text-primary"/>
				</div>
				<p className="caption mb-3">
					<Trans i18nKey="modalWelcomeVerifyEmail.checkEmail"/>
				</p>
				{+Date(user.bonusVerifyBefore) - +Date.now() > 0 && (
					<React.Fragment>
						<p className="caption mb-1">
							<Trans i18nKey="modalWelcomeVerifyEmail.accountExpires"/>
						</p>
						<Countdown
							compact
							className={`countdown countdown-bordered text-primary bg-white rounded mb-2`}
							date={user.bonusVerifyBefore}
						/>
					</React.Fragment>
				)}

				<div className="d-flex align-items-center flex-column mb-3">
					<div
						className="link mt-2 mb-1"
						onClick={() => this.props.resendEmailVerify()}
					>
						<Trans i18nKey="modalWelcomeVerifyEmail.sendEmail"/>
					</div>
					<div className="caption-small"><Trans i18nKey="modalWelcomeVerifyEmail.emailTo" values={{ email: user.email }}/></div>
				</div>
				<Button color="primary" fullwidth onClick={() => this.props.loadUserData()}>
					<Trans i18nKey="modalWelcomeVerifyEmail.refresh" />
				</Button>
			</Modal>
		)
	}
}

const mapStateToProps = state => {
	return {
		user: state.User.user,
		network: state.Network.network,
	}
}
const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			loadUserData,
			resendEmailVerify,
			// add other watcher sagas to this object to map them to props
		},
		dispatch
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ModalWelcomeVerifyEmail))

