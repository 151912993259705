import {
	EMAIL_VERIFY_SENT,
	LOAD_INITIAL_DATA,
	LOAD_INVITE_CODE,
	RESEND_EMAIL_VERIFY,
	RESULT_SUBMIT_REQUEST_REWARD,
	SAVE_INVITE_CODE,
	SUBMIT_REQUEST_REWARD,
	TUTORIAL_CLAIM_DISABLE,
	TUTORIAL_CLAIM_ENABLE
} from '@Store/global/actionTypes'

export const loadInitialData = () => ({
	type: LOAD_INITIAL_DATA,
	payload: null
})

export const loadInviteCode = () => ({
	type: LOAD_INVITE_CODE,
	payload: null
})

export const saveInviteCode = (data) => ({
	type: SAVE_INVITE_CODE,
	payload: data
})

export const submitRequestReward = (data) => ({
	type: SUBMIT_REQUEST_REWARD,
	payload: data
})
export const resultSubmitRequestReward = (data) => ({
	type: RESULT_SUBMIT_REQUEST_REWARD,
	payload: data
})

export const resendEmailVerify = () => ({
	type: RESEND_EMAIL_VERIFY,
	payload: null
})
export const emailVerifySent = () => ({
	type: EMAIL_VERIFY_SENT,
	payload: null
})

export const tutorialClaimEnable = () => ({
	type: TUTORIAL_CLAIM_ENABLE,
	payload: null
})
export const tutorialClaimDisable = () => ({
	type: TUTORIAL_CLAIM_DISABLE,
	payload: null
})
