import React, { useEffect, useState } from 'react'
import { formatCurrency } from '@Helpers/number_helper'
import { Trans } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
	AVAILABLE, FIRST_TO_BE_UNLOCKED,
	LOCKED_HIRED_LOST,
	LOCKED_NOT_REGISTERED,
	LOCKED_REGISTERED,
	SECURED_HIRED,
	SECURED_HIRED_REDEEMED,
	SECURED_NOT_HIRED,
	SECURED_NOT_REGISTERED,
	UNLOCKED_HIRED_LOST,
	UNLOCKED_NOT_REGISTERED,
	UNLOCKED_REGISTERED,
	VOTE_STATUSSES
} from '@Molecules/Vote/voteStatusses'
import PropTypes from 'prop-types'
import Button from '@Atoms/Button'
import { claimVote } from '@Store/votes/actions'
import { openShare } from '@Store/share/actions'
import { updateVoteRemoteData } from '@Store/remoteStorage/actions'

const VoteButton = props => {

	const { voteStatus, vote, unlockFirstVote } = props

	const dispatch = useDispatch()

	const userData = useSelector(state => state.User.user)

	const [user, setUser] = useState(userData)

	useEffect(() => setUser(userData), [userData])

	const amount = formatCurrency(vote.totalReward, user.currency)

	if(unlockFirstVote) return (
		<Button className="btn-rounded btn-danger btn-vote" onClick={() => dispatch(updateVoteRemoteData(vote.id, FIRST_TO_BE_UNLOCKED, true))}>
			<Trans i18nKey="cardNetwork.unlockAmount" values={{ amount }}/>
		</Button>
	)

	switch (voteStatus) {
		/* LOCKED */
		case LOCKED_NOT_REGISTERED:
		case LOCKED_REGISTERED:
			return (
				<Button className="btn-rounded btn-danger btn-vote" onClick={() => dispatch(openShare())}>
					<Trans i18nKey="cardNetwork.unlockAmount" values={{ amount }}/>
				</Button>
			)
		/* LOST */
		case LOCKED_HIRED_LOST:
			return (
				<div className="d-flex flex-column justify-content-center align-items-center">
					<div className="h5 gray-3 fw-semibold mb-0">{amount}</div>
					<small className="gray-5 line-height-1"><Trans i18nKey="cardNetwork.voteLost"/></small>
				</div>
			)
		case UNLOCKED_HIRED_LOST:
			return (
				<div className="d-flex flex-column justify-content-center align-items-center">
					<div className="h5 gray-4 fw-semibold mb-0">{amount}</div>
					<small className="gray-5 line-height-1"><Trans i18nKey="cardNetwork.voteLost"/></small>
				</div>
			)
		/* UNLOCKED */
		case UNLOCKED_NOT_REGISTERED:
			return (<div className="h5 text-primary fw-semibold mb-0">{amount}</div>)
		case UNLOCKED_REGISTERED:
			return (
				<Button
					className="btn-rounded btn-primary btn-vote"
					onClick={() => {
						dispatch(claimVote(vote))
					}}>
					<Trans i18nKey="cardNetwork.claimAmount" values={{ amount }}/>
				</Button>
			)
		/* SECURED */
		case SECURED_NOT_HIRED:
		case SECURED_NOT_REGISTERED:
			return (<div className="h5 text-success fw-semibold mb-0">{amount}</div>)
		case SECURED_HIRED:
			/* TODO: create modal get hired and remove comment from below */
			/*return (
				<Button className="btn-rounded btn-success btn-vote">
					<Trans i18nKey="cardNetwork.redeemAmount" values={{ amount }}/>
				</Button>
			)*/
		case SECURED_HIRED_REDEEMED:
			return (
				<div className="d-flex flex-column justify-content-center align-items-center">
					<div className="h5 text-secondary-dark fw-semibold mb-0">{amount}</div>
					<small className="gray-5 line-height-1"><Trans i18nKey="cardNetwork.voteWallet"/></small>
				</div>
			)
		case AVAILABLE:
			return null
	}
	return (<h5 className="">{vote.vote}</h5>)
}

VoteButton.defaultProps = {
	unlockFirstVote: false,
}

VoteButton.propTypes = {
	vote: PropTypes.any.isRequired,
	voteStatus: PropTypes.oneOf(VOTE_STATUSSES),
	unlockFirstVote: PropTypes.bool,
}

export default VoteButton
