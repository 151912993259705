import { LOAD_REMOTE_DATA, LOADED_REMOTE_DATA, REMOVE_REMOTE_DATA, SAVE_REMOTE_DATA, UPDATE_VOTE_REMOTE_DATA, VERIFY_VOTE_REMOTE_DATA } from '@Store/remoteStorage/actionTypes'

export const loadRemoteData = () => ({
	type: LOAD_REMOTE_DATA,
	payload: null
})
export const loadedRemoteData = (data) => ({
	type: LOADED_REMOTE_DATA,
	payload: data
})
export const saveRemoteData = (data) => ({
	type: SAVE_REMOTE_DATA,
	payload: data
})
export const removeRemoteData = (data) => ({
	type: REMOVE_REMOTE_DATA,
	payload: data
})

/* Votes */
export const verifyVoteRemoteData = () => ({
	type: VERIFY_VOTE_REMOTE_DATA,
	payload: null
})
export const updateVoteRemoteData = (voteId, key, value) => ({
	type: UPDATE_VOTE_REMOTE_DATA,
	payload: {
		voteId,
		key,
		value
	}
})
