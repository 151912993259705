import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded'
import React from 'react'
import PropTypes from 'prop-types'

const InfoTitle = props => {

	return (
		<div>
			<HelpOutlineRoundedIcon/>
			<h3 className="title mt-2 mb-3">{props.text}</h3>
		</div>
	)
}

InfoTitle.propTypes = {
	text: PropTypes.any.isRequired,
}

export default InfoTitle
