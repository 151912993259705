import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { Card, CardBody } from 'reactstrap'
import _ from 'lodash'
import { Trans } from 'react-i18next'
import PropTypes from 'prop-types'
import Info from '@Icons/Info'

class CardRank extends React.Component {

	constructor (props) {
		super(props)
		this.state = {
			user: props.user,
			className: props.className,
		}
	}

	componentDidUpdate (prevProps, prevState, snapshot) {
		if (!_.isEqual(this.props.user, prevProps.user)) this.setState({ user: this.props.user })
		if (!_.isEqual(this.props.className, prevProps.className)) this.setState({ className: this.props.className })
	}

	render () {

		if (
			!this.state.user
		) return null

		const { user } = this.state

		return (
			<Card className={`d-flex flex-direction-row align-items-center card-short clickable ${this.state.className}`} onClick={this.props.onClick}>
				<CardBody className="text-center">
					<span className="caption-small">
						<Trans i18nKey="cardRank.title"/><Info style={{ marginLeft: '5px' }}/>
					</span>
					<h5 className="caption-big text-primary mb-0 font-weight-bold">
						{!user.verified ? (
							<div><Trans i18nKey="cardRank.lowerRank"/></div>
						) : (
							<React.Fragment>
								{user.wRank.rank === 0 || user.wRank.rank === 100 ? (
									<Trans i18nKey="cardRank.lowerRank"/>
								) : (
									<Trans i18nKey="cardRank.topRank" values={{ rank: user.wRank.rank }}/>
								)}
							</React.Fragment>
						)}
					</h5>
				</CardBody>
			</Card>
		)
	}
}

CardRank.propTypes = {
	onClick: PropTypes.func,
}

const mapStateToProps = state => {
	return {
		user: state.User.user,
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			// add other watcher sagas to this object to map them to props
		},
		dispatch
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CardRank))
