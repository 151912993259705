import React from 'react'
import PropTypes from 'prop-types'

const ShareWhatsapp = props => {

	return (
		<a
			href={`https://api.WhatsApp.com/send?text=${props.message}`}
			target="_blank"
			className="btn btn-social"
			style={{ backgroundColor: '#25D366', color: 'white' }}
		>
			<i className="bx bxl-whatsapp"/>
		</a>
	)
}

ShareWhatsapp.propTypes = {
	message: PropTypes.string.isRequired,
}

export default ShareWhatsapp
