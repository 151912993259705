import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Trans } from 'react-i18next'
import { Col, Container } from 'reactstrap'

const InternalPageLayout = props => {

	const { name: pageName } = props
	const history = useHistory()

	const internalPageData = useSelector(state => state.Template.internalPage)
	const [internalPage, setInetrnalPage] = useState(internalPageData)

	let listener

	useEffect(() => {
		listener = () => {
			if (internalPage[pageName] && internalPage[pageName].dismissable) {
				document.body.style.overflow = 'unset'
			}
		}
		window.addEventListener('popstate', listener)
	}, [])

	useEffect(() => {
		if (internalPage && !internalPage.open) window.removeEventListener('popstate', listener)

		if (internalPage[pageName] && internalPage[pageName].open) document.body.style.overflow = 'hidden'
		else document.body.style.overflow = 'unset'

		setInetrnalPage(internalPageData)
	}, [internalPageData])

	const currentUrl = history.location.pathname

	return (
		<div className="page-content" style={{ overflowY: 'auto' }}>
			<Container fluid style={{ height: '100%' }}>
				<Col
					lg={{ size: 6, offset: 3 }}
					md={{ size: 8, offset: 2 }}
					sm={{ size: 10, offset: 1 }}
					xs={12}
				>
					<div className="internal-page-layout">
						<div className="internal-page-topbar">
							<Col
								lg={{ size: 6, offset: 3 }}
								md={{ size: 8, offset: 2 }}
								sm={{ size: 10, offset: 1 }}
								xs={12}
								className="d-flex"
							>
								<div
									className="clickable"
									onClick={() => history.push(currentUrl.substring(0, currentUrl.lastIndexOf('/')))}
								>
									<ArrowBackIosNewRoundedIcon className="back"/>
									<Trans i18nKey={`page.${pageName}`}/>
								</div>
							</Col>
						</div>
						<Col
							lg={{ size: 6, offset: 3 }}
							md={{ size: 8, offset: 2 }}
							sm={{ size: 10, offset: 1 }}
							xs={12}
						>
							<div className="internal-page-content">
								{props.children}
							</div>
						</Col>
					</div>
				</Col>
			</Container>
		</div>
	)
}

InternalPageLayout.propTypes = {
	name: PropTypes.string.isRequired,
	children: PropTypes.any.isRequired,
}

export default InternalPageLayout
