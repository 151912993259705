import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Trans } from 'react-i18next'
import { formatCurrency } from '@Helpers/number_helper'
import {
	AVAILABLE,
	LOCKED_HIRED_LOST,
	LOCKED_NOT_REGISTERED,
	LOCKED_REGISTERED,
	SECURED_HIRED,
	SECURED_HIRED_REDEEMED,
	SECURED_NOT_HIRED,
	SECURED_NOT_REGISTERED,
	UNLOCKED_HIRED_LOST,
	UNLOCKED_NOT_REGISTERED,
	UNLOCKED_REGISTERED
} from '@Molecules/Vote/voteStatusses'
import VoteUserIcon from '@Molecules/Vote/VoteUserIcon'
import VoteName from '@Molecules/Vote/VoteName'
import VoteButton from '@Molecules/Vote/VoteButton'
import { useSelector } from 'react-redux'
import { getBonusExpirationTime } from '@Helpers/global'

const Vote = props => {

	const { data, unlockFirstVote } = props

	const userData = useSelector(state => state.User.user)
	const [user, setUser] = useState(userData)
	useEffect(() => setUser(userData), [userData])

	if (
		!user ||
		!user.inviteCode ||
		!user.referrals ||
		!data
	) return null

	const expirationTime = getBonusExpirationTime(user.bonusVerifyBefore)

	let classVote = ''
	switch (data.status) {
		case LOCKED_NOT_REGISTERED:
		case LOCKED_REGISTERED:
		case UNLOCKED_NOT_REGISTERED:
		case LOCKED_HIRED_LOST:
		case UNLOCKED_HIRED_LOST:
		case AVAILABLE:
			break
		case UNLOCKED_REGISTERED:
			classVote = 'vote-unlocked'
			break
		case SECURED_NOT_HIRED:
		case SECURED_NOT_REGISTERED:
			classVote = 'vote-secured'
			break
		case SECURED_HIRED:
			classVote = 'vote-secured-hired'
			break
		case SECURED_HIRED_REDEEMED:
			classVote = 'vote-redeemed'
			break
	}

	if (unlockFirstVote) classVote = 'vote-first-unlock'

	return (
		<div className={`vote ${classVote}`}>
			<div className="d-flex flex-row justify-content-between">
				<div>
					<div className="vote-user">
						<VoteUserIcon voteStatus={data.status} unlockFirstVote={unlockFirstVote}/>
						<div className="vote-name">
							<VoteName voteStatus={data.status} vote={data} unlockFirstVote={unlockFirstVote}/>
						</div>
					</div>
				</div>
				<div className="vote-cta">
					<VoteButton voteStatus={data.status} vote={data} unlockFirstVote={unlockFirstVote}/>
					{!unlockFirstVote && expirationTime && data.status !== AVAILABLE && data.status !== SECURED_NOT_REGISTERED && (
						<div className="text-end">
							{expirationTime.unit === 'hours' && (
								<small className="gray-5">
									<Trans
										i18nKey="cardNetwork.bonusHours"
										values={{
											amount: formatCurrency(user.nextInvite.amountBonus, user.currency),
											time: expirationTime.value
										}}
									/>
								</small>
							)}
							{expirationTime.unit === 'minutes' && (
								<small className="gray-5">
									<Trans
										i18nKey="cardNetwork.bonusMinutes"
										values={{
											amount: formatCurrency(user.nextInvite.amountBonus, user.currency),
											time: expirationTime.value
										}}
									/>
								</small>
							)}
						</div>
					)}
				</div>
			</div>
		</div>
	)
}

Vote.defaultProps = {
	unlockFirstVote: false,
}

Vote.propTypes = {
	data: PropTypes.object.isRequired,
	expirationTime: PropTypes.object,
	unlockFirstVote: PropTypes.bool,
}

export default Vote
