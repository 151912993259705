import React from 'react'
import PropTypes from 'prop-types'

const ShareTelegram = props => {

	return (
		<a
			href={`tg://msg?text=${props.message}`}
			target="_blank"
			className="btn btn-social"
			style={{ backgroundColor: '#0088cc', color: 'white' }}
		>
			<i className="bx bxl-telegram"/>
		</a>
	)
}

ShareTelegram.propTypes = {
	message: PropTypes.string.isRequired,
}

export default ShareTelegram
