import {
	CHANGE_VOTE_ERROR_ALREADY_VOTED,
	CHANGE_VOTE_ERROR_TOO_MANY_VOTES,
	CHANGE_VOTE_ERROR_VOTE_ALREADY_CLAIMED,
	CHANGE_VOTE_ERROR_VOTE_CHANGE_TOO_EARLY,
	CHANGE_VOTE_ERROR_VOTE_COUNT_NOT_FOUND, CHANGE_VOTE_SUCCESS, SAVE_INVITE_TO_VOTE_LINK,
	SAVE_VOTES_DATA, SET_VOTE_FLOW_ACTIVE
} from '@Store/votes/actionTypes'

const INIT_STATE = {
	votes: [],
	changeVoteError: null,
	inviteToVoteLink: null,
	voteFlowActive: null
}

const Votes = (state = INIT_STATE, action) => {
	switch (action.type) {
		case SAVE_VOTES_DATA:
			return {
				...state,
				votes: action.payload
			}
		case CHANGE_VOTE_ERROR_VOTE_ALREADY_CLAIMED:
		case CHANGE_VOTE_ERROR_VOTE_COUNT_NOT_FOUND:
		case CHANGE_VOTE_ERROR_VOTE_CHANGE_TOO_EARLY:
		case CHANGE_VOTE_ERROR_TOO_MANY_VOTES:
		case CHANGE_VOTE_ERROR_ALREADY_VOTED:
			return {
				...state,
				changeVoteError: action.type
			}
		case CHANGE_VOTE_SUCCESS:
			return {
				...state,
				changeVoteError: INIT_STATE.changeVoteError
			}
		case SAVE_INVITE_TO_VOTE_LINK:
			return {
				...state,
				inviteToVoteLink: action.payload
			}
		case SET_VOTE_FLOW_ACTIVE:
			return {
				...state,
				voteFlowActive: action.payload
			}
		default:
			return state
	}
}

export default Votes
