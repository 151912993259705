import i18n from 'i18next'
import detector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import translationEN from '@Locales/en/translation.json'
import jobsEN from '@Locales/en/jobs.json'
import translationIT from '@Locales/it/translation.json'
import jobsIT from '@Locales/it/jobs.json'
import { getLanguage } from '@Store/helpers'

// the translations
const resources = {
	en: { translation: translationEN, jobs: jobsEN },
	it: { translation: translationIT, jobs: jobsIT },
}

const language = localStorage.getItem('i18nextLng')
if (!language) {
	localStorage.setItem('i18nextLng', getLanguage().lang)
}

i18n
	.use(detector)
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		lng: localStorage.getItem('i18nextLng') || 'en',
		fallbackLng: 'en', // use en if detected lng is not available
		keySeparator: '.', // we do not use keys in form messages.welcome
		compatibilityJSON: 'v3',
		transSupportBasicHtmlNodes: true,
		interpolation: {
			escapeValue: false, // react already safes from xss
		},
	})

export default i18n
