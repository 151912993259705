import {ReactComponent as InfoIcon} from '@Assets/images/badge_missing.svg'
import PropTypes from 'prop-types'

const Info = props => {

	return (
		<InfoIcon
			className={props.className}
			style={props.style}
			onClick={props.onClick}
		/>
	)
}

Info.propTypes = {
	className: PropTypes.string,
	style: PropTypes.object,
	onClick: PropTypes.func,
}

export default Info
