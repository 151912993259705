import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import Vote from '@Molecules/Vote/Vote'
import Button from '@Atoms/Button'
import { formatCurrency } from '@Helpers/number_helper'
import { updateVoteRemoteData } from '@Store/remoteStorage/actions'
import { SECURED_NOT_HIRED, UNLOCKED_NOT_REGISTERED } from '@Molecules/Vote/voteStatusses'
import { useDispatch } from 'react-redux'

const InviteToSecureTheVote = props => {

	const { vote, user } = props

	const { t } = useTranslation()
	const dispatch = useDispatch()

	if(!vote.numberVote) return null

	return (
		<>
			<div className="b1 mb-4">
				{vote.numberVote === 1 ? (
					<Trans
						i18nKey="FlowUnlockVote.INVITE_TO_SECURE_THE_VOTE.textFirstVote"
						values={{
							name: vote.vote,
							amount: formatCurrency(vote.totalReward, user.currency),
							cardinal: t(`cardinalNumbers.${vote.numberVote}`)
						}}
						components={{
							span: <span className="h7"/>
						}}
					/>
					):(
					<Trans
						i18nKey="FlowUnlockVote.INVITE_TO_SECURE_THE_VOTE.text"
						values={{
							name: vote.vote,
							amount: formatCurrency(vote.totalReward, user.currency),
							cardinal: t(`cardinalNumbers.${vote.numberVote}`)
						}}
						components={{
							span: <span className="h7"/>
						}}
					/>
				)}

			</div>
			<div className="flow-title overline text-white">
				<Trans i18nKey="FlowUnlockVote.INVITE_TO_SECURE_THE_VOTE.title"/>
			</div>
			<div className="mb-5">
				<Vote data={vote}/>
			</div>
			<div className="text-center">
				<Button
					color="primary"
					className="w-50"
					onClick={() => dispatch(updateVoteRemoteData(vote.id, UNLOCKED_NOT_REGISTERED, true))}
				>
					<Trans i18nKey="FlowUnlockVote.INVITE_TO_SECURE_THE_VOTE.cta"/>
				</Button>
			</div>
		</>
	)
}

export default InviteToSecureTheVote
