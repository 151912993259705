import {ReactComponent as PaperPlaneSendIcon} from '@Assets/images/send_plane.svg'
import PropTypes from 'prop-types'

const PaperPlaneSend = props => {

	return (
		<PaperPlaneSendIcon  alt="" />
	)
}

PaperPlaneSend.propTypes = {
	className: PropTypes.string
}

export default PaperPlaneSend
