import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { changePage, toggleModal } from '@Store/actions'
import { Trans, withTranslation } from 'react-i18next'
import { Card, CardBody } from 'reactstrap'
import routes from '@Routes/routeConfig'
import { jobFinance, jobTech } from '@Data/jobOffers'
import TableJobOffers from '@Molecules/TableJobOffers'

class ReferColleague extends React.Component {

	constructor (props) {
		super(props)
		this.state = {
			user: props.user,
			page: props.page,
			idUser: null,
			type: 'tech',
			referrer: null,
		}
	}

	componentDidMount () {
		/* Keep this condition as we experienced sometimes a multiple call to changePage function */
		if (this.state.page.route !== routes.REFER_COLLEAGUE_OPEN.route) this.props.changePage(routes.REFER_COLLEAGUE_OPEN)
		const { type, referrer } = this.props.match.params
		let newState = {
			referrer
		}
		if (type === 'f') {
			newState.type = 'finance'
		}
		this.setState(newState)
	}

	render () {

		let { isAuthorized: isLogged } = this.props
		const { user, type, referrer } = this.state

		let jobOffers = jobTech
		if (type === 'finance') jobOffers = jobFinance

		if (
			!user
		) return null

		const urlForm = `${process.env.REACT_APP_VOTE_URL}?t=${referrer}`

		return (
			<React.Fragment>
				<h1 className="page-title text-primary-yellow">
					<Trans i18nKey="pageReferColleague.title"/>
				</h1>
				<Card>
					<CardBody>
						<p className="caption-big text-center">
							<Trans i18nKey="pageReferColleague.boxContact.title"/>
						</p>
						<p className="caption">
							<Trans
								i18nKey="pageReferColleague.boxContact.why"
								components={{ u: <u/> }}
							/>
						</p>
						<p>
							<Trans i18nKey="pageReferColleague.boxContact.text"/>
						</p>
						<div className="text-center my-3">
							<a className="btn btn-primary w-75" href={urlForm}>
								<Trans i18nKey="pageReferColleague.boxContact.cta"/>
							</a>
						</div>
						<p className="caption-small text-center mb-1 mt-3">
							<Trans
								i18nKey="pageReferColleague.boxContact.footer"
								components={{ u: <u/> }}
							/>
						</p>
					</CardBody>
				</Card>
				<Card>
					<CardBody>
						<p className="caption-big text-center">
							{type === 'tech' ? (
								<Trans i18nKey="pageReferColleague.boxCompaniesTech"/>
							) : (
								<Trans i18nKey="pageReferColleague.boxCompaniesFinancial"/>
							)}
						</p>
						<TableJobOffers jobOffers={jobOffers} />
					</CardBody>
				</Card>
			</React.Fragment>
		)
	}
}

const mapStateToProps = state => {
	return {
		user: state.User.user,
		page: state.Template.page,
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			changePage,
			toggleModal,
		},
		dispatch
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(ReferColleague)))
