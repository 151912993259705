import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { Card, CardBody } from 'reactstrap'
import _ from 'lodash'
import { toggleModal } from '@Store/template/actions'
import { Trans } from 'react-i18next'
import { CARD_TOP_FEATURES } from '@Constants/constants'
import Objective from '@Atoms/objective'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import AskNotification from '@Atoms/askNotification'

class CardTopFeatures extends React.Component {

	constructor (props) {
		super(props)
		this.state = {
			showAsCard: props.showAsCard || false,
			user: props.user,
		}
	}

	componentDidUpdate (prevProps, prevState, snapshot) {
		if (!_.isEqual(this.props.user, prevProps.user)) this.setState({ user: this.props.user })
	}

	render () {
		const { user } = this.state

		if (!user) return null

		return (
			<Card className="card-top-features">
				<CardBody className="d-flex flex-column">
					<div className="">
						<div className="h8 mb-2">
							<Trans i18nKey="cardTopFeatures.SkipTraditionalHiringProcess"/></div>
						<div className="achievement-box">
							{CARD_TOP_FEATURES.ACHIEVEMENTS.map((step, k) => {
								const isActive = user.verified && user.wRank.rank <= step.rating
								return (
									<React.Fragment key={k}>
										<div className="d-flex align-items-center">
											<Objective
												content={step.box}
												active={isActive}
												contentClassName="caption-small"
												iconCheck
											/>
											<p className="body-small d-flex align-items-center mb-0 flex-grow-1 ms-2 me-2">
												<Trans i18nKey={step.text} />
											</p>
											<div>
												{isActive ? (
													<CheckRoundedIcon className="text-success" style={{ width: '20px' }}/>
												) : (
													<LockOutlinedIcon className="text-gray-normal" style={{ width: '20px' }}/>
												)}
											</div>
										</div>
									</React.Fragment>
								)
							})}
						</div>
					</div>
					<AskNotification>
						<hr/>
						<div className="link text-center">
							<Trans i18nKey="cardTopFeatures.notify" />
						</div>
					</AskNotification>
				</CardBody>
			</Card>
		)
	}
}

const mapStateToProps = state => {
	return {
		user: state.User.user,
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			toggleModal
			// add other watcher sagas to this object to map them to props
		},
		dispatch
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CardTopFeatures))
