import React from "react"

const LoadingSpinner = props => {
  return (
    <React.Fragment>
      <div className="page-content text-center">
        <div className={`spinner-border ${props.color || 'text-primary'}  m-1`} role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </React.Fragment>
  )
}
export default LoadingSpinner
