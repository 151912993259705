import React from 'react'
import PropTypes from 'prop-types'

const HighlightedItem = props => {

	const spanClassName = [
		props.color ? `text-${props.color}` : `text-primary`,
		props.bg
	]

	switch(props.captionSize) {
		case 'sm':
			spanClassName.push('caption-small')
			break
		case 'md':
			spanClassName.push('caption')
			spanClassName.push('font-weight-bold')
			break
		case 'lg':
			spanClassName.push('font-size-16')
			spanClassName.push('font-weight-bold')
			break
	}

	return (
		<div className={`${props.className} ${props.bordered ? 'highlighted-item' : ''} ${props.borderColor ? `border-${props.borderColor}` : ''}`}>
			{props.caption && (
				<div className="item-label">
					<span className={spanClassName.join(' ')}>
						{props.caption}
					</span>
				</div>
			)}
			{props.children}
		</div>
	)
}

HighlightedItem.propTypes = {
	children: PropTypes.any.isRequired,
	color: PropTypes.string,
	caption: PropTypes.any,
	captionSize: PropTypes.oneOf(['sm', 'md', 'lg']),
	bordered: PropTypes.bool,
	className: PropTypes.string,
	bg: PropTypes.string,
	borderColor: PropTypes.oneOf(['primary', 'success'])
}

export default HighlightedItem
