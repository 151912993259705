import React from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'
import { Slider, styled } from '@mui/material'
import { formatCurrency } from '@Helpers/number_helper'
import _ from 'lodash'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { bindActionCreators } from 'redux'
import { toggleModal } from '@Store/template/actions'
import { MODAL_MERITOCRACY_EXPLAIN } from '@Modals/ModalInfos/modals'
import { Trans } from 'react-i18next'
import AmountWhySoHigh from '@Atoms/AmountWhySoHigh'

const marks = [
	{ value: 0, label: '1', reward: 330 },
	{ value: 1, label: '2', reward: 564 },
	{ value: 2, label: '5', reward: 865 },
	{ value: 3, label: '10', reward: 1270 },
	{ value: 4, label: '40', reward: 1765 },
	{ value: 5, label: '200', reward: 4580 },
	{ value: 6, label: '1k', reward: 21475 },
	{ value: 7, label: '5k', reward: 121570 },
]

const StyledSlider = styled(Slider)(({ theme }) => ({
	height: 3,
	padding: '18px 0',
	'& .MuiSlider-track, & .MuiSlider-rail': {
		border: 'none',
		color: '#EA6B24',
		opacity: 1
	},
	'& .MuiSlider-thumb': {
		height: 20,
		width: 20,
		color: '#EA6B24',
		backgroundColor: '#fff',
		border: '2px solid currentColor',
		'&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
			boxShadow: 'inherit',
		},
		'&:before': {
			display: 'none',
		},
	},
	'& .MuiSlider-mark': {
		color: 'white'
	},
	'& .MuiSlider-markLabel': {
		fontSize: 10,
		color: '#666'
	},
	'& .MuiSlider-markLabelActive': {
		color: '#198754',
		fontWeight: 'bold',
		fontSize: 14,
		marginTop: -3
	}
}))

class CardMeritocracy extends React.Component {

	constructor (props) {
		super(props)
		this.state = {
			user: props.user,
			reward: false,
			sliderDefaultStep: 3,
		}
	}

	componentDidMount () {
		this.handleChangeSlider(this.state.sliderDefaultStep)
	}

	componentDidUpdate (prevProps, prevState, snapshot) {
		if (!_.isEqual(this.props.user, prevProps.user)) this.setState({ user: this.props.user })
	}

	handleChangeSlider = (value) => {
		const reward = marks.filter((o) => { return o.value === value})[0].reward
		this.setState({ reward })
	}

	render () {
		const { user, reward, sliderDefaultStep } = this.state

		if (!user || !reward) return null

		return (
			<Card>
				<CardBody className="text-start">
					<h3 className="title mb-2"><Trans i18nKey="cardMeritocracy.title" /></h3>
					<div className="caption">
						<Trans i18nKey="cardMeritocracy.content" />
					</div>
					<div className="text-center mt-2 px-3">
						<div className="caption-small"><Trans i18nKey="cardMeritocracy.howManyColleagues" /></div>
						<StyledSlider
							defaultValue={sliderDefaultStep}
							valueLabelDisplay="off"
							step={1}
							track={false}
							marks={marks}
							min={marks[0].value}
							max={marks[marks.length - 1].value}
							onChange={(e, v) => this.handleChangeSlider(v)}
						/>
						<AmountWhySoHigh amount={reward} size='medium'/>
					</div>
					<div className="caption-small mt-2"><Trans i18nKey="cardMeritocracy.newIdeas" /></div>
					<div className="caption-small">
						<Trans
							i18nKey="cardMeritocracy.contact"
							components={{a: <a className="text-decoration-underline text-secondary" href="mailto:ambassador@wiraki.com" />}}
						/>
					</div>
				</CardBody>
			</Card>
		)
	}
}

const mapStateToProps = state => {
	return {
		user: state.User.user,
	}
}
const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			toggleModal
			// add other watcher sagas to this object to map them to props
		},
		dispatch
	)
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(CardMeritocracy)))
