import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Modal from '@Modals/Modal'
import _ from 'lodash'
import { openShare } from '@Store/share/actions'
import Badge from '@Atoms/badge'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import { Col, Row } from 'reactstrap'
import { CARD_COLLECTIVE_GOALS } from '@Constants/constants'
import { formatCurrency } from '@Helpers/number_helper'
import ButtonShare from '@Atoms/ButtonShare'
import { MODAL_GOAL } from '@Modals/ModalInfos/modals'
import InfoTitle from '@Atoms/InfoTitle'
import { Trans } from 'react-i18next'

class ModalGoal extends React.Component {

	constructor (props) {
		super(props)
		this.state = {
			user: props.user,
			network: props.network,
		}
	}

	componentDidUpdate (prevProps, prevState, snapshot) {
		if (!_.isEqual(this.props.user, prevProps.user)) this.setState({ user: this.props.user })
		if (!_.isEqual(this.props.network, prevProps.network)) this.setState({ network: this.props.network })
	}

	render () {

		const { user, network } = this.state

		if (
			!user ||
			!network ||
			!network.groupGoals ||
			!network.participants
		) return null

		const partecipantsVerified = network.participants.filter(p => p.verified)

		const participants = partecipantsVerified.length + 1
		const nextGoal = network.groupGoals.filter(g => g.from <= participants && g.to >= participants)[0]

		if (!nextGoal) return null

		const nextGoalIcon = CARD_COLLECTIVE_GOALS.GOALS.find(g => g.value === nextGoal.to)

		return (
			<Modal type="modal-mobile-center" name={MODAL_GOAL} centered>
				<InfoTitle text={<Trans i18nKey="modalGoal.title" />}/>
				<div className="caption">
					<Trans
						i18nKey="modalGoal.content"
						values={{badge: nextGoalIcon.title.toLowerCase()}}
						/>
				</div>
				<div className="my-2 py-2 mb-3 bordered-primary">
					<Row>
						<Col xs={6} className="justify-content-center d-flex align-items-center">
							<Badge color={nextGoalIcon.icon} width={34} className="py-2"/>
							<AddRoundedIcon className="text-success" style={{ width: '30px', height: '30px', position: 'absolute', bottom: 0, right: -15 }}/>
						</Col>
						<Col xs={6} className="justify-content-center d-flex align-items-center">
							<span style={{ fontSize: '38px' }}>💰</span>
						</Col>
					</Row>
					<Row>
						<Col xs={6} className="justify-content-center d-flex align-items-center">
							<div className="caption-big font-weight-bold"><Trans i18nKey={`goals.${nextGoalIcon.title}`}/></div>
						</Col>
						<Col xs={6} className="justify-content-center d-flex align-items-center">
							<span className="caption-big font-weight-bold">+{formatCurrency(nextGoal.rewardTotal, user.currency)}</span>
						</Col>
					</Row>
				</div>
				<ButtonShare color="primary" fullwidth type="inviteEarnBadge"/>
			</Modal>
		)
	}
}

const mapStateToProps = state => {
	return {
		user: state.User.user,
		network: state.Network.network,
	}
}
const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			openShare
			// add other watcher sagas to this object to map them to props
		},
		dispatch
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ModalGoal))
