import React from 'react'

import routes from '@Routes/routeConfig'
// Layouts
import AuthDefault from '@Components/Layout/AuthDefault'
import NonAuthDefault from '@Components/Layout/NonAuthDefault'
import NonAuthHybrid from '@Components/Layout/NonAuthHybrid'

// Authentication related pages
import LoginWithtoken from '@Pages/Authentication/LoginWithToken'
import Login from '@Pages/Authentication/Login'
import Logout from '@Pages/Authentication/Logout'
import ResetPassword from '@Pages/Authentication/ResetPassword'

// Open pages
import Certificate from '@Pages/Certificate'
import ContactMe from '@Pages/ContactMe'
import ReferColleague from '@Pages/ReferColleague'

// Onboarding
// Pages
import Home from '@Pages/Home'
import Rank from '@Pages/Rank'
import Jobs from '@Pages/Jobs'
// Internal Pages
import MonitorInvites from '@Pages/InternalPages/MonitorInvites'
import InternalPageLayout from '@Components/Layout/InternalPageLayout'
import ListFeeds from '@Pages/InternalPages/ListFeeds'

const authProtectedRoutes = [
	// this route should be at the end of all other routes
	// eslint-disable-next-line react/display-name
	{ layout: AuthDefault, exact: true, path: routes.HOME.route, component: Home },
	{ layout: AuthDefault, exact: true, path: routes.RANK.route, component: Rank },
	{ layout: AuthDefault, exact: true, path: routes.JOBS.route, component: Jobs },

	// Internal pages
	{ layout: InternalPageLayout, exact: false, path: routes.MY_INVITES.route, component: MonitorInvites, layoutProps: routes.MY_INVITES },
	{ layout: InternalPageLayout, exact: false, path: routes.LIST_FEEDS.route, component: ListFeeds, layoutProps: routes.LIST_FEEDS },
]

const publicRoutes = [
	{ redirectIfAuthorized: false, layout: NonAuthDefault, exact: true, path: routes.LOGOUT.route, component: Logout },
	{ redirectIfAuthorized: true, layout: NonAuthDefault, exact: true, path: routes.LOGIN.route, component: Login },
	{ redirectIfAuthorized: false, layout: NonAuthDefault, exact: true, path: routes.RESET_PASSWORD.route, component: ResetPassword },
	{ redirectIfAuthorized: false, layout: NonAuthDefault, exact: true, path: routes.LOGIN_WITH_TOKEN.route, component: LoginWithtoken },

	{ redirectIfAuthorized: false, layout: NonAuthHybrid, exact: false, path: routes.CERTIFICATE_OPEN.route, component: Certificate },
	{ redirectIfAuthorized: false, layout: NonAuthHybrid, exact: false, path: routes.CONTACT_ME_EMAIL_OPEN.route, component: ContactMe },
	{ redirectIfAuthorized: false, layout: NonAuthHybrid, exact: false, path: routes.REFER_COLLEAGUE_OPEN.route, component: ReferColleague },
]

export { publicRoutes, authProtectedRoutes }
