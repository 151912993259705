import React, { useEffect, useState } from 'react'
import { Alert, Col, Row } from 'reactstrap'
import { userResetPassword, verifyToken } from '@Store/actions'
import routes from '@Routes/routeConfig'
import { Trans, useTranslation } from 'react-i18next'
import InputBox from '@Molecules/InputBox'
import Input from '@Atoms/Input'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import * as queryString from 'query-string'
import { Helmet } from 'react-helmet'

const ResetPassword = props => {

	const [isFirst, setIsFirst] = useState(false)
	const [action, setAction] = useState()
	const [code, setCode] = useState()
	const [submitted, setSubmitted] = useState(false)

	const { t } = useTranslation()
	const dispatch = useDispatch()

	useEffect(() => {
		window.dataLayer.push({
			event: 'pageview',
			page: { url: routes.RESET_PASSWORD.route, title: 'Page Reset Password' }
		})
		const code = queryString.parse(location.search).code
		setCode(code)
		setIsFirst(queryString.parse(location.search).first === 'true')
		setAction(queryString.parse(location.search).action)
		dispatch(verifyToken(code))
	}, [])

	const resetPasswordData = useSelector(state => state.ResetPassword)

	const {
		register,
		handleSubmit,
		formState: { errors },
		formState,
		setValue,
		watch
	} = useForm({
		mode: 'onBlur',
		reValidateMode: 'onChange',
	})

	useEffect(() => {
		if (resetPasswordData?.emailUser?.email) setValue('email', resetPasswordData.emailUser.email)
		if (resetPasswordData.resetSuccessMsg && resetPasswordData.accessToken) {
			setTimeout(function (accessToken) {
				window.location.href = `/login-with-token?t=${accessToken}`
			}.bind(this, resetPasswordData.accessToken), 1500)
		}
	}, [resetPasswordData])

	// handleValidSubmit
	const submit = values => {
		setSubmitted(true)
		dispatch(userResetPassword({ password: values.newPassword }, props.history, code, action))
	}

	if (!resetPasswordData.readyRender) return null
	if (!resetPasswordData.emailUser) {
		window.location.href = routes.LOGIN.location
	}

	return (
		<React.Fragment>
			<Helmet>
				<title>Login | Wiraki</title>
			</Helmet>
			<div className="account-pages my-4 pt-sm-5">
				<Row className="justify-content-center">
					<Col xs={12} sm={8} md={5} lg={4} xl={3}>
						<div className="h2 mb-3">🔑<br/><Trans i18nKey="pageResetPassword.title"/></div>

						<div className="container-form">
							<form onSubmit={handleSubmit(submit)}>
								<InputBox
									errors={null}
									touched={true}
									label={t(`pageResetPassword.inputEmail.label`)}
								>
									<Input
										required
										disabled
										register={register}
										name="email"
										errors={null}
										touched={true}
										registerOptions={{ required: true }}
										type="email"/>
								</InputBox>

								<InputBox
									errors={errors.newPassword}
									touched={formState.touchedFields.newPassword || !!watch().newPassword}
									label={t(`pageResetPassword.inputPassword.label`)}
								>
									<Input
										required
										register={register}
										name="newPassword"
										errors={errors.newPassword}
										touched={formState.touchedFields.newPassword || !!watch().newPassword}
										placeholder={t(`pageResetPassword.inputPassword.placeholder`)}
										minLength={5}
										registerOptions={{ required: true, validate: async (value) => { return /^(.*){5,}$/.test(value)} }}
										type="password"/>
								</InputBox>
								{resetPasswordData.resetSuccessMsg ?
									<Alert className="my-3" color="success">
										<Trans
											i18nKey="pageResetPassword.success"
											components={{ a: <a href={`/login-with-token?t=${resetPasswordData.accessToken}`}/> }}
										/>
									</Alert>
									: null}
								<button className="btn btn-primary btn-rounded btn-block w-100" type="submit" disabled={submitted}>
									<Trans i18nKey="pageResetPassword.button"/>
								</button>

								{!isFirst && (
									<div className="text-center">
										<a href="/login">
											<i className="mdi mdi-reply me-1"/><Trans i18nKey="pageResetPassword.backToLogin"/>
										</a>
									</div>
								)}
							</form>
						</div>
					</Col>
				</Row>
			</div>
		</React.Fragment>
	)
}

export default ResetPassword
