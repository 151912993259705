import { all, takeEvery } from 'redux-saga/effects'
import { INTERNAL_NOTIFICATIONS_SHOW } from '@Store/internalNotifications/actionTypes'
import { Slide, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import { INTERNAL_NOTIFICATIONS } from '@Constants/internalNotifications'
import i18n from 'i18next'

const options = {
	position: toast.POSITION.TOP_RIGHT,
	autoClose: false,
	hideProgressBar: true,
	closeOnClick: true,
	pauseOnHover: true,
	draggable: false,
	progress: false,
	transition: Slide
}

function * showInternalNotification ({ payload }) {
	switch (payload) {
		case INTERNAL_NOTIFICATIONS.BROWSER_PREVENT_PROMPT:
			toast.warning(
				i18n.t('internalNotifications.browserIsPreventingShowPrompt'),
				{
					...options,
					toastId: INTERNAL_NOTIFICATIONS.BROWSER_PREVENT_PROMPT
				})
			break
	}
	console.log(toast.isActive(INTERNAL_NOTIFICATIONS.BROWSER_PREVENT_PROMPT))
}

function * InternalNotificationsSaga () {
	yield all([
		takeEvery(INTERNAL_NOTIFICATIONS_SHOW, showInternalNotification)
	])
}

export default InternalNotificationsSaga
