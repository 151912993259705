import React, { useState } from 'react'
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from 'reactstrap'
import WhoPaysForRewards from '@Molecules/FAQ/WhoPaysForRewards'
import WhoRewardsWork from '@Molecules/FAQ/WhoRewardsWork'

const FAQ = props => {

	const accordionName = 'accordion_FAQ'

	const [open, setOpen] = useState('')
	const toggle = (id) => {
		if (open === id) setOpen('')
		else setOpen(id)
	}

	const FAQs = [
		WhoPaysForRewards(),
		WhoRewardsWork()
	]

	return (
		<Accordion open={open} toggle={toggle} id="faq">
			{FAQs.map((elem, k) => (
				<AccordionItem key={k}>
					<AccordionHeader targetId={`${accordionName}_${k}`}>
						{elem.title}
					</AccordionHeader>
					<AccordionBody accordionId={`${accordionName}_${k}`}>
						{elem.content}
					</AccordionBody>
				</AccordionItem>
			))}
		</Accordion>
	)
}

export default FAQ
