import React from 'react'
import { Card, CardBody } from 'reactstrap'
import PropTypes from 'prop-types'

const CardSimple = props => {

	return (
		<Card
			className={`${props.onClick ? 'clickable' : ''} d-flex flex-direction-row align-items-center card-short ${props.className}`}
			style={props.style}
			onClick={props.onClick}
		>
			<CardBody className="text-center">
				<span className="caption-small">{props.caption}</span>
				<h5 className={`caption-big ${props.color || 'text-primary'} mb-0 font-weight-bold`}>
					{props.text}
				</h5>
			</CardBody>
		</Card>
	)
}

CardSimple.propTypes = {
	caption: PropTypes.any,
	text: PropTypes.any,
	color: PropTypes.string,
	onClick: PropTypes.func,
}

export default CardSimple
