import React, { useEffect, useState } from 'react'
import { Button, ButtonGroup, Card, CardBody, InputGroup } from 'reactstrap'
import { Trans, useTranslation } from 'react-i18next'
import Select from 'react-select'
import { getMonthNames, ucfirst } from '@Helpers/global'
import { useDispatch, useSelector } from 'react-redux'
import { saveUserJob } from '@Store/jobs/actions'
import _ from 'lodash'
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'

const CardJobs = props => {
	const { t } = useTranslation()
	const years = []
	const currYear = new Date().getFullYear()
	for (let i = currYear - 1; i < currYear + 6; i++) {
		years.push({ value: i, label: i })
	}
	const months = []
	getMonthNames(t('locale')).map((m, k) => {
		months.push({ value: k + 1, label: ucfirst(m) })
	})

	const dispatch = useDispatch()

	const userJob = useSelector(state => state.Jobs.userJob)

	const [jobStage, setJobStage] = useState(false)
	const [jobPartTime, setJobPartTime] = useState(false)
	const [jobFullTime, setJobFullTime] = useState(false)
	const [month, setMonth] = useState()
	const [year, setYear] = useState()

	const [jobEdited, setJobEdited] = useState(false)
	const [graduationEdited, setGraduationEdited] = useState(false)

	useEffect(() => {

		setJobFullTime(userJob.interestedFullTime)
		setJobStage(userJob.interestedInternship)
		setJobPartTime(userJob.interestedPartTime)
		setMonth(months.filter(m => m.value === userJob.graduation.month)[0])
		setYear(years.filter(y => y.value === userJob.graduation.year)[0])
	}, [userJob])

	const handleChangeJobStage = (value) => {
		setJobEdited(true)
		setTimeout(() => {setJobEdited(false)}, 2000)
		const obj = _.cloneDeep(userJob)
		obj.interestedInternship = value
		dispatch(saveUserJob(obj))
	}

	const handleChangeJobPartTime = (value) => {
		setJobEdited(true)
		setTimeout(() => {setJobEdited(false)}, 2000)
		const obj = _.cloneDeep(userJob)
		obj.interestedPartTime = value
		dispatch(saveUserJob(obj))
	}

	const handleChangeJobFullTime = (value) => {
		setJobEdited(true)
		setTimeout(() => {setJobEdited(false)}, 2000)
		const obj = _.cloneDeep(userJob)
		obj.interestedFullTime = value
		dispatch(saveUserJob(obj))
	}

	const handleChangeMonth = (month) => {
		setGraduationEdited(true)
		setTimeout(() => {setGraduationEdited(false)}, 2000)
		const obj = _.cloneDeep(userJob)
		obj.graduationMonth = month.value
		dispatch(saveUserJob(obj))
	}

	const handleChangeYear = (year) => {
		setGraduationEdited(true)
		setTimeout(() => {setGraduationEdited(false)}, 2000)
		const obj = _.cloneDeep(userJob)
		obj.graduationYear = year.value
		dispatch(saveUserJob(obj))
	}

	return (
		<Card
			className={`clickable d-flex flex-direction-row align-items-center ${props.className}`}
			onClick={props.onClick}
		>
			<CardBody className="d-flex flex-column">
				<div className="h8 mb-3">
					<Trans i18nKey="cardJobs.title"/>
				</div>
				<div className="body-small">
					<Trans i18nKey="cardJobs.content"/>
				</div>
				<div className="mt-3 text-center">
					<label className="w-100 position-relative">
						<Trans i18nKey="cardJobs.jobType.label"/>
						{jobEdited && (
							<CheckCircleOutlineRoundedIcon className="text-success" style={{ position: 'absolute', right: 12, bottom: 6 }}/>
						)}
					</label>
					<ButtonGroup className="btn-rounded w-100 btn-no-focus">
						<Button
							type="button"
							color="primary"
							outline
							active={jobStage}
							onClick={() => handleChangeJobStage(!jobStage)}
							className="w-50"
						>
							<Trans i18nKey="cardJobs.jobType.internship"/>
						</Button>
						<Button
							type="button"
							color="primary"
							outline
							active={jobFullTime}
							onClick={() => handleChangeJobFullTime(!jobFullTime)}
							className="w-50"
						>
							<Trans i18nKey="cardJobs.jobType.fullTime"/>
						</Button>
						<Button
							type="button"
							color="primary"
							outline
							active={jobPartTime}
							onClick={() => handleChangeJobPartTime(!jobPartTime)}
							className="w-50"
						>
							<Trans i18nKey="cardJobs.jobType.partTime"/>
						</Button>
					</ButtonGroup>
				</div>
				<div className="mt-3 text-center position-relative">
					<label className="w-100"><Trans i18nKey="cardJobs.graduation.label"/></label>
					<InputGroup className="w-75 m-auto">
						<Select
							className="w-75 text-start"
							placeholder="Month"
							options={months}
							value={month}
							isSearchable={false}
							onChange={(e) => handleChangeMonth(e)}
							styles={{
								control: (provided) => ({
									...provided,
									borderColor: '#EA6B24',
									borderRadius: '8px',
									borderTopRightRadius: 0,
									borderBottomRightRadius: 0,
								}),
								singleValue: (provided) => ({
									...provided,
									fontWeight: '500',
									color: '#EA6B24'
								}),
								menu: (provided) => ({
									...provided,
									zIndex: 10000
								}),
								indicatorsContainer: () => ({
									display: 'none'
								}),
							}}
						/>
						<Select
							className="w-25 text-start"
							placeholder="Year"
							options={years}
							value={year}
							isSearchable={false}
							onChange={(e) => handleChangeYear(e)}
							styles={{
								control: (provided) => ({
									...provided,
									borderColor: '#EA6B24',
									borderRadius: '8px',
									borderTopLeftRadius: 0,
									borderBottomLeftRadius: 0,
								}),
								singleValue: (provided) => ({
									...provided,
									fontWeight: '500',
									color: '#EA6B24'
								}),
								indicatorsContainer: () => ({
									display: 'none'
								}),
							}}
						/>
					</InputGroup>
					{graduationEdited && (
						<CheckCircleOutlineRoundedIcon className="text-success" style={{ position: 'absolute', right: 12, bottom: 6 }}/>
					)}
				</div>
			</CardBody>
		</Card>
	)
}

CardJobs.propTypes = {}

export default CardJobs
