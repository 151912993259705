import { ADD_CERTIFICATE, LOAD_LIST_REFERRAL, LOAD_USER_DATA, SAVE_USER_DATA, SAVE_USER_DATA_SAGA, UPDATE_ENTRY_BONUS_COUNTDOWN } from '@Store/user/actionTypes'

export const loadUserData = () => ({
	type: LOAD_USER_DATA,
	payload: null
})
export const loadListReferral = () => ({
	type: LOAD_LIST_REFERRAL,
	payload: null,
})

export const saveUserData = (data) => ({
	type: SAVE_USER_DATA,
	payload: data,
})
export const saveUserDataSaga = (data) => ({
	type: SAVE_USER_DATA_SAGA,
	payload: data,
})

export const addCertificate = (certificateCode, data) => ({
	type: ADD_CERTIFICATE,
	payload: {
		certificateCode,
		data,
	}
})
export const updateEntryBonusCountdown = timeLeft => ({
	type: UPDATE_ENTRY_BONUS_COUNTDOWN,
	payload: timeLeft
})
