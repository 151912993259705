import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Modal from '@Modals/Modal'
import _ from 'lodash'
import { openShare } from '@Store/share/actions'
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded'
import ButtonShare from '@Atoms/ButtonShare'
import HighlightedItem from '@Atoms/HighlighedItem'
import { MODAL_RANK } from '@Modals/ModalInfos/modals'
import { Trans } from 'react-i18next'

class ModalRank extends React.Component {

	constructor (props) {
		super(props)
		this.state = {
			user: props.user,
			network: props.network,
		}
	}

	componentDidUpdate (prevProps, prevState, snapshot) {
		if (!_.isEqual(this.props.user, prevProps.user)) this.setState({ user: this.props.user })
		if (!_.isEqual(this.props.network, prevProps.network)) this.setState({ network: this.props.network })
	}

	render () {

		const { user, network } = this.state

		if (
			!user ||
			!network ||
			!network.university ||
			!network.faculty
		) return null

		return (
			<Modal type="modal-mobile-center" name={MODAL_RANK} centered>
				<HelpOutlineRoundedIcon/>
				<h3 className="title mt-2 mb-3"><Trans i18nKey="modalRank.title"/></h3>
				<div className="caption mb-2">
					<Trans i18nKey="modalRank.content"/>
				</div>

				{/* TEMPORARY DISABLED */}
				{1 === 2 && (
					<HighlightedItem
						bordered
						caption="Supporter"
						bg="bg-primary-yellow"
						className="mb-3"
						captionSize="md"
					>
						<div className="caption p-2">
							You know who are the talents in your network.<br/>
							No recruiter know them better than you do.<br/>
							<br/>
							Vote for them to make them emerge and be hired by top companies and get rewarded for your help!

							<br/>
							<br/>
							DA TERMINARE
						</div>
					</HighlightedItem>
				)}
				<ButtonShare color="primary" fullwidth type="spreadRevolution"/>
			</Modal>
		)
	}
}

const mapStateToProps = state => {
	return {
		user: state.User.user,
		network: state.Network.network,
	}
}
const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			openShare
			// add other watcher sagas to this object to map them to props
		},
		dispatch
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ModalRank))
