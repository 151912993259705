import React, { useEffect, useState } from 'react'
import Modal from '@Modals/Modal'
import Cards from '@Cards'
import { CARD_LEADERBOARD } from '@Constants/constants'
import { MODAL_LEADERBOARD } from '@Modals/ModalPages/modals'
import { useSelector } from 'react-redux'

const ModalLeaderboard = props => {

	const userData = useSelector(state => state.User.user)
	const networkData = useSelector(state => state.Network.network)

	const [user, setUser] = useState(userData)
	const [network, setNetwork] = useState(networkData)

	useEffect(() => setUser(userData), [userData])
	useEffect(() => setNetwork(networkData), [networkData])

	if (
		!user ||
		!network ||
		!network.university ||
		!network.faculty ||
		!network.participants
	) return null

	const partecipantsVerified = network.participants.filter(p => p.verified)

	return (
		<Modal type="modal-mobile-full" name={MODAL_LEADERBOARD}>
			<img alt="" width={40} src={network.university.logo} style={{ borderRadius: '10px' }}/>
			<div className="title">
				{network.faculty.name}
			</div>


			{partecipantsVerified.length < CARD_LEADERBOARD.NUM_PARTICIPANTS_TO_UNLOCK ? (
				<React.Fragment>
					<Cards.CardRankConfidence/>
					<Cards.CardLeaderboardUnlock/>
					<Cards.CardShare type="ShareToGainMore"/>
				</React.Fragment>
			) : (
				<React.Fragment>
					<Cards.CardContest/>
					<Cards.CardLeaderboard/>
					<Cards.CardRankGraph/>
					{user.wRank.rank < 100 && user.wRank.rank > 0 ? (
						<Cards.CardShare type="YouAreTop"/>
					) : (
						<Cards.CardShare type="HowIsYourRank"/>
					)}
				</React.Fragment>
			)}

		</Modal>
	)
}

export default ModalLeaderboard
