import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Card, CardBody, CardHeader} from 'reactstrap'
import {toggleModal} from '@Store/template/actions'
import {Trans} from 'react-i18next'
import {MODAL_REWARDS} from '@Modals/ModalPages/modals'
import ReactEcharts from 'echarts-for-react'
import {formatCurrency} from '@Helpers/number_helper'
import routes from '@Routes/routeConfig'

const CardBalance = props => {

    const dispatch = useDispatch()

    const userData = useSelector(state => state.User.user)
    const [user, setUser] = useState(userData)
    useEffect(() => setUser(userData), [userData])

    if (
        !user ||
        !user.reward ||
        !user.rewardVotes
    ) return null

    /* Calculate the rewards */
    let rewardsLocked = 0
    let rewardsUnlocked = 0
    let rewardsSecured = 0
    user.rewardVotes.map(v => {
        if (v.active && v.claimed) {
            rewardsSecured += v.totalReward
        } else if (v.active && !v.claimed) {
            rewardsUnlocked += v.totalReward
        } else {
            rewardsLocked += v.totalReward
        }
    })
    const totalRewards = rewardsLocked + rewardsUnlocked + rewardsSecured

    return (
        <Card className="align-self-center card-monitor-invites">
            <CardHeader className="clickable" onClick={() => dispatch(toggleModal(MODAL_REWARDS))}>
                <div className="position-relative">
                    <div className="h5 fw-semibold mb-1"><Trans i18nKey="cardBalance.title"/></div>
                    <button
                        type="button"
                        className="top-right-icon"
                        data-dismiss="modal"
                        aria-label="Close"
                    />
                </div>
            </CardHeader>
            <CardBody className="d-flex flex-column">
                <div className="mb-2 position-relative">
                    <div className=""><Trans i18nKey="cardBalance.subtitle"/></div>
                </div>
                <div className="d-flex flex-row">
                    <div className="w-50">
                        <ReactEcharts
                            style={{height: '150px'}}
                            option={{
                                series: [{
                                    type: 'pie',
                                    radius: ['60%', '80%'],
                                    label: {show: false},
                                    data: [
                                        {value: 100 / totalRewards * rewardsLocked},
                                        {value: 100 / totalRewards * rewardsUnlocked},
                                        {value: 100 / totalRewards * rewardsSecured},
                                    ]
                                }],
                                color: ['#CE2B37', '#F67C09', '#34B157']
                            }}/>
                    </div>
                    <div className="w-50">
                        <div className="w-100 w-sm-75 w-md-50 mx-auto d-flex flex-column justify-content-evenly h-100">
                            <div className="d-flex flex-row justify-content-between">
                                <div className="h8 text-danger"><Trans i18nKey="cardBalance.locked"/></div>
                                <div className="gray-6">{formatCurrency(rewardsLocked, user.currency)}</div>
                            </div>
                            <div className="d-flex flex-row justify-content-between">
                                <div className="h8 text-primary"><Trans i18nKey="cardBalance.unlocked"/></div>
                                <div className="gray-6">{formatCurrency(rewardsUnlocked, user.currency)}</div>
                            </div>
                            <div className="d-flex flex-row justify-content-between">
                                <div className="h8 text-success"><Trans i18nKey="cardBalance.secured"/></div>
                                <div className="gray-6">{formatCurrency(rewardsSecured, user.currency)}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </CardBody>
        </Card>
    )
}

export default CardBalance
