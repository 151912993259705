import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Modal from '@Modals/Modal'
import _ from 'lodash'
import EnvelopeSend from '@Icons/EnvelopeSend'
import { MODAL_CHECK_EMAIL } from '@Modals/ModalInfos/modals'
import { Trans } from 'react-i18next'

class ModalCheckEmail extends React.Component {

	constructor (props) {
		super(props)
		this.state = {
			user: props.user,
		}
	}

	componentDidUpdate (prevProps, prevState, snapshot) {
		if (!_.isEqual(this.props.user, prevProps.user)) this.setState({ user: this.props.user })
	}

	render () {

		const { user } = this.state

		if (
			!user
		) return null

		return (
			<Modal type="modal-mobile-center" name={MODAL_CHECK_EMAIL} centered>
				<h3 className="title mb-3"><Trans i18nKey="modalCheckEmail.title"/></h3>
				<div className="mb-2"><EnvelopeSend className="text-primary" style={{ width: '100px', height: '100px' }}/></div>
				<p className="caption"><Trans i18nKey="modalCheckEmail.subtitle" values={{ email: user.email }}/></p>
			</Modal>
		)
	}
}

const mapStateToProps = state => {
	return {
		user: state.User.user,
	}
}
const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			// add other watcher sagas to this object to map them to props
		},
		dispatch
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ModalCheckEmail))

