import { all, call, put, select, takeEvery } from 'redux-saga/effects'

// Login Redux States
import { LOAD_REMOTE_DATA, LOADED_REMOTE_DATA, REMOVE_REMOTE_DATA, SAVE_REMOTE_DATA, UPDATE_VOTE_REMOTE_DATA } from './actionTypes'
import axios from 'axios'
import { loadedRemoteData } from '@Store/remoteStorage/actions'
import { deepSameKeys, getHeaders, handleError, updateObjectValuesWithoutAdd } from '@Store/helpers'
import { SAVE_USER_DATA, SAVE_USER_DATA_SAGA } from '@Store/user/actionTypes'
import _ from 'lodash'
import { tutorialClaimDisable } from '@Store/global/actions'
import { FIRST_TO_BE_UNLOCKED, SECURED_NOT_HIRED, UNLOCKED_NOT_REGISTERED, UNLOCKED_VOTE_AGAIN } from '@Molecules/Vote/voteStatusses'

const getStateUser = (state) => state.User.user
const getRemoteStorage = (state) => state.RemoteStorage.remoteStorage

/*
	Object structure for remoteData
	========================
	WARNING: changing this structure will update all remote data.
	WARNING: removed keys will be deleted.
*/
const voteObject = {
	active: false,
	claimed: false,
	redeemed: false,
	[SECURED_NOT_HIRED]: false,
	[UNLOCKED_NOT_REGISTERED]: false,
	[UNLOCKED_VOTE_AGAIN]: false,
	[FIRST_TO_BE_UNLOCKED]: false,
}

function * verifyVotesObject () {
	const dataUser = _.cloneDeep(yield select(getStateUser))
	const dataRemoteStorage = _.cloneDeep(yield select(getRemoteStorage))

	/* Continue only if all of them are initialized */
	if (!dataUser || !dataUser.rewardVotes || !dataRemoteStorage) return

	/* Base structure of dataRemoteStorage.votesData */
	const votesDataDefault = {}
	dataUser.rewardVotes.map(v => {
		votesDataDefault[v.id] = {
			..._.cloneDeep(voteObject),
			active: v.active,
			claimed: v.claimed,
		}
		/* Because it doesn't exist, copy the actual state of votes */
	})
	/* If the key doesn't exists, create it with default state of votes */
	if (!dataRemoteStorage.votesData) {
		yield call(() => putRemoteData({ payload: { votesData: JSON.stringify(votesDataDefault) } }))
	}

	const votesRemoteData = dataRemoteStorage.votesData

	/* If the value structure doesn't match, update the structure */
	if (!deepSameKeys({ ...votesRemoteData }, { ...votesDataDefault })) {
		const newStructureWithData = updateObjectValuesWithoutAdd(votesDataDefault, votesRemoteData)
		yield call(() => putRemoteData({ payload: { votesData: JSON.stringify(newStructureWithData) } }))
	}
}

function * updateVotesObject ({ payload }) {
	try {
		const dataRemoteStorage = _.cloneDeep(yield select(getRemoteStorage)).votesData
		/* Update data only if different */
		if (
			dataRemoteStorage.hasOwnProperty(payload.voteId) &&
			dataRemoteStorage[payload.voteId].hasOwnProperty(payload.key) &&
			dataRemoteStorage[payload.voteId][payload.key] !== payload.value
		) {
			const newRemoteData = { ...dataRemoteStorage }
			newRemoteData[payload.voteId][payload.key] = payload.value

			yield call(() => putRemoteData({ payload: { votesData: JSON.stringify(newRemoteData) } }))
		}
	} catch (error) {handleError(error)}
}

function * getRemoteData () {
	try {
		const { data } = yield call(() => axios.get(`${process.env.REACT_APP_ENDPOINT_API_USER}/v1/storage`, getHeaders()))
		if (data && data.votesData) {
			data.votesData = JSON.parse(data.votesData)
		}
		/* Retrocompatibility for users that claimed their first vote but haven't the variable setted */
		let votesClaimed = []
		if (data && data.votesData) {
			Object.keys(data.votesData).map(k => {
				if (data.votesData[k].claimed) votesClaimed.push(data.votesData[k])
			})
		}
		/* ----- */

		/* Retrocompatibility for previous modal welcome */
		if (data.modalWelcome && !data.completedWelcome) {
			data.completedWelcome = true
			yield call(() => putRemoteData({ payload: { completedWelcome: true } }))
		}
		/* ----- */

		/* Retrocompatibility for all new variables */
		if (typeof data.bannerEntryBonusStatus === 'undefined') data.bannerEntryBonusStatus = true
		/* ----- */

		if (data && !data.tutorialClaim && data.votesData && votesClaimed.length > 0) {
			yield all([
				call(() => putRemoteData({ payload: { tutorialClaim: true } })),
				put(tutorialClaimDisable()),
				put(loadedRemoteData(data))
			])
		}
		yield put(loadedRemoteData(data))
	} catch (error) {
		handleError(error)
	}
}

function * putRemoteData ({ payload }) {
	try {
		yield call(() => axios.post(`${process.env.REACT_APP_ENDPOINT_API_USER}/v1/storage`, payload, getHeaders()))
		yield call(() => getRemoteData())
	} catch (error) {
		handleError(error)
	}
}

function * deleteRemoteData ({ payload: key }) {
	try {
		yield call(() => axios.delete(`${process.env.REACT_APP_ENDPOINT_API_USER}/v1/storage/${key}`, getHeaders()))
	} catch (error) {
		handleError(error)
	}
}

function * RemoteStorageSaga () {
	yield all([
		takeEvery(LOAD_REMOTE_DATA, getRemoteData),
		takeEvery(SAVE_REMOTE_DATA, putRemoteData),
		takeEvery(REMOVE_REMOTE_DATA, deleteRemoteData),

		/*  */
		takeEvery(SAVE_USER_DATA, verifyVotesObject),
		takeEvery(SAVE_USER_DATA_SAGA, verifyVotesObject),
		takeEvery(LOAD_REMOTE_DATA, verifyVotesObject),
		takeEvery(LOADED_REMOTE_DATA, verifyVotesObject),
		/* */

		takeEvery(UPDATE_VOTE_REMOTE_DATA, updateVotesObject),
	])
}

export default RemoteStorageSaga
