import { PUSH_NOTIFICATIONS_CHECK, PUSH_NOTIFICATIONS_GRANTED, PUSH_NOTIFICATIONS_INIT_PROMPT } from '@Store/pushNotifications/actionTypes'

export const pushNotificationsInitPrompt = () => ({
	type: PUSH_NOTIFICATIONS_INIT_PROMPT,
	payload: null
})

export const pushNotificationsGranted = () => ({
	type: PUSH_NOTIFICATIONS_GRANTED,
	payload: null
})

export const pushNotificationsCheck = () => ({
	type: PUSH_NOTIFICATIONS_CHECK,
	payload: null,
})
