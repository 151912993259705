import React from 'react'
import PropTypes from 'prop-types'
import { shareLinkedin } from '@Helpers/share'

const ShareLinkedin = props => {

	const url = shareLinkedin(props.url)

	return (
		<a
			href={url}
			target="_blank"
			className="btn btn-social"
			style={{ backgroundColor: '#0088cc', color: 'white' }}
		>
			<i className="bx bxl-linkedin"/>
		</a>
	)
}

ShareLinkedin.propTypes = {
	url: PropTypes.string.isRequired,
}

export default ShareLinkedin
