import { ReactComponent as UserIcon } from '@Assets/images/user.svg'
import { ReactComponent as LockIcon } from '@Assets/images/lock.svg'
import { ReactComponent as PlusIcon } from '@Assets/images/plusRounded.svg'
import { ReactComponent as CheckIcon } from '@Assets/images/checkRounded.svg'
import PropTypes from 'prop-types'

const User = props => {

	const { icon, iconColor, userColor } = props

	let userColorClass = ''
	switch (userColor) {
		case 'gray':
			userColorClass = 'gray-4'
			break
		case 'black':
			userColorClass = 'gray-6'
			break
	}

	let iconColorClass = ''
	switch(iconColor) {
		case 'primary':
			iconColorClass = 'text-primary'
			break
		case 'secondary':
			iconColorClass = 'text-secondary'
			break
		case 'success':
			iconColorClass = 'text-success'
			break
		case 'black':
			iconColorClass = 'gray-6'
			break
	}

	const styleObj = { position: 'absolute', fontSize: '10px', top: 0, right: 2 }

	return (
		<div className="p-1">
			{icon === 'lock' && (<LockIcon style={styleObj} className={iconColorClass}/>)}
			{icon === 'plus' && (<PlusIcon style={styleObj} className={iconColorClass}/>)}
			{icon === 'check' && (<CheckIcon style={styleObj} className={iconColorClass}/>)}
			<UserIcon
				className={`${props.className} ${userColorClass}`}
				style={props.style}
			/>
		</div>
	)
}

User.propTypes = {
	icon: PropTypes.oneOf(['check', 'plus', 'lock']),
	iconColor: PropTypes.oneOf(['primary', 'secondary', 'success', 'black']),
	userColor: PropTypes.oneOf(['gray', 'black']),
}

export default User
