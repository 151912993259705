import PropTypes from 'prop-types'

const Button = props => {

	return (
		<button
			className={`${props.fontSize ? `font-size-${props.fontSize}` : 'font-size-14'} clickable btn ${props.color && `btn-${props.color}`} ${props.rounded && `btn-rounded`} ${props.className} ${props.fullwidth ? 'w-100' : ''}`}
			onClick={props.onClick}
			disabled={props.disabled}
			style={props.style}
		>
			{props.children}
		</button>
	)
}

Button.defaultProps = {
	rounded: true
}

Button.propTypes = {
	rounded: PropTypes.bool,
	color: PropTypes.string,
	children: PropTypes.any.isRequired,
	className: PropTypes.string,
	onClick: PropTypes.func,
	fullwidth: PropTypes.bool,
	fontSize: PropTypes.number,
	disabled: PropTypes.bool,
	style: PropTypes.object,
}

export default Button
