import React from 'react'
import PropTypes from 'prop-types'

const Input = props => {

	const {
		type,
		errors,
		name,
		placeholder,
		register,
		touched,
		inputErrorMessage,
		inputInfoMessage,
		registerOptions,
		autocompleteWith,
		disabled,
		style,
	} = props

	return (
		<>
			<input
				name={name}
				type={type}
				placeholder={placeholder}
				autoComplete={autocompleteWith}
				disabled={disabled}
				style={style}
				className={`form-control ${errors ? 'invalid' : touched ? 'valid' : ''}`}
				{...register(name, registerOptions)}
			/>
			{errors ? (
				<div className="input-info error">{inputErrorMessage}</div>
			) : inputInfoMessage && (
				<div className="input-info">{inputInfoMessage}</div>
			)}
		</>
	)
}
Input.defaultProps = {
	touched: false,
	disabled: false,
}

Input.propTypes = {
	name: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	placeholder: PropTypes.string,
	register: PropTypes.func.isRequired,
	errors: PropTypes.any,
	touched: PropTypes.bool,
	autocompleteWith: PropTypes.string,
	registerOptions: PropTypes.object,
	inputInfoMessage: PropTypes.string,
	inputErrorMessage: PropTypes.string,
	disabled: PropTypes.bool,
}

export default Input
