import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import LoadingSpinner from '@Atoms/loadingSpinner'
import * as queryString from 'query-string'
import { loginWithToken } from '@Store/auth/login/actions'

class LoginWithToken extends React.Component {

	constructor (props) {
		super(props)
		this.state = {}
	}

	componentDidMount() {
		this.props.loginWithToken(queryString.parse(location.search).t)
	}

	render () {
		return (
			<React.Fragment>
				<div style={{
					position: 'fixed',
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
					display: 'flex',
					height: '100%',
					justifyContent: 'center',
					alignItems: 'center',
				}}>
					<LoadingSpinner/>
				</div>
			</React.Fragment>
		)
	}
}

const mapStateToProps = state => {
	return {}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			loginWithToken
			// add other watcher sagas to this object to map them to props
		},
		dispatch
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoginWithToken))
