import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import { toggleModal } from '@Store/template/actions'
import { Card, CardBody, Col, Row } from 'reactstrap'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import HourglassEmptyRoundedIcon from '@mui/icons-material/HourglassEmptyRounded'
import WarningRoundedIcon from '@mui/icons-material/WarningRounded'
import { Trans } from 'react-i18next'

class CardReferralList extends React.Component {

	constructor (props) {
		super(props)
		this.state = {
			user: props.user,
			isAccordionOpen: false,
		}
	}

	componentDidUpdate (prevProps, prevState, snapshot) {
		if (!_.isEqual(this.props.user, prevProps.user)) this.setState({ user: this.props.user })
	}

	render () {
		const { user } = this.state

		if (
			!user ||
			!user.referrals
		) return null

		let numAccountsVerified = 0
		let numAccountsNotVerified = 0

		user.referrals.items.map((item) => {
			item.verified ? numAccountsVerified++ : numAccountsNotVerified++
		})

		return (
			<React.Fragment>
				<Card className="card-list-participants">
					<CardBody className="fd-flex flex-column">
						<h3 className="title mb-3"><Trans i18nKey="cardReferralList.title"/></h3>
						<Row>
							<Col xs={6}>
								<div className="caption-small"><Trans i18nKey="cardReferralList.accountNotVerified"/></div>
								<div className="text-primary">{numAccountsNotVerified}</div>
							</Col>
							<Col xs={6}>
								<div className="caption-small"><Trans i18nKey="cardReferralList.accountVerified"/></div>
								<div className="text-success">{numAccountsVerified}</div>
							</Col>
						</Row>
						<hr/>
						<table className="w-100">
							<thead>
							<tr>
								<th width="30px"/>
								<th/>
								<th/>
								<th width="30px"/>
							</tr>
							</thead>
							<tbody>
							{user.referrals.items.map((person, k) => (
								<tr key={k} className={`${person.verified ? 'text-gray' : 'text-gray-light'}`}>
									<td className="align-middle"><PersonOutlineOutlinedIcon style={{ width: '20px' }}/></td>
									<td className="align-middle text-start">{person.fullName}</td>
									<td className="align-middle text-end">
										{person.verified ?
											<Trans i18nKey="cardReferralList.verified"/>
											:
											<Trans i18nKey="cardReferralList.notVerified"/>
										}
									</td>
									<td className="align-middle">
										{person.verified ?
											<CheckRoundedIcon style={{ width: '20px' }} className="text-success"/>
											:
											<HourglassEmptyRoundedIcon style={{ width: '20px' }}/>
										}
									</td>
								</tr>
							))}
							</tbody>
						</table>
						{numAccountsNotVerified > 0 && (
							<div className="d-flex flex-direction-row align-items-center mt-3">
								<WarningRoundedIcon className="me-2 text-primary"/>
								<div className="caption-small text-start"><Trans i18nKey="cardReferralList.activateAccount"/></div>
							</div>
						)}
					</CardBody>
				</Card>
			</React.Fragment>
		)
	}
}

const mapStateToProps = state => {
	return {
		user: state.User.user,
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			toggleModal
			// add other watcher sagas to this object to map them to props
		},
		dispatch
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CardReferralList))
