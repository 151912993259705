import { combineReducers } from 'redux'

// Authentication
import Login from '@Store/auth/login/reducer'
import Account from '@Store/auth/register/reducer'
import ForgetPassword from '@Store/auth/forgetPassword/reducer'
import ResetPassword from '@Store/auth/resetPassword/reducer'

import System from '@Store/system/reducer'

import Template from '@Store/template/reducer'
import Global from '@Store/global/reducer'
import RemoteStorage from '@Store/remoteStorage/reducer'
import Share from '@Store/share/reducer'
import PushNotifications from '@Store/pushNotifications/reducer'
import InternalNotifications from '@Store/internalNotifications/reducer'
import Votes from '@Store/votes/reducer'
import User from '@Store/user/reducer'
import Network from '@Store/network/reducer'
import Balance from '@Store/balance/reducer'
import Jobs from '@Store/jobs/reducer'
import Feeds from '@Store/feeds/reducer'

const rootReducer = combineReducers({
	// public
	Login,
	Account,
	ForgetPassword,
	ResetPassword,

	System,

	Template,
	Global,
	RemoteStorage,
	Share,
	PushNotifications,
	InternalNotifications,
	Votes,
	User,
	Network,
	Balance,
	Jobs,
	Feeds,
})

export default rootReducer
