import React from 'react'
import Modal from '@Modals/Modal'
import { MODAL_ADDITIONAL_VOTE_LOCKED } from '@Modals/ModalInfos/modals'
import { Trans } from 'react-i18next'
import ButtonShare from '@Atoms/ButtonShare'

const ModalAdditionalVoteLocked = props => {

	return (
		<Modal type="modal-mobile-center" name={MODAL_ADDITIONAL_VOTE_LOCKED} centered>
			<h3 className="title mb-3"><Trans i18nKey="modalAdditionalVoteLocked.title"/></h3>
			<p className="caption mb-3">
				<Trans
					i18nKey="modalAdditionalVoteLocked.content"
					components={{
						boldSuccess: <span className="text-success font-weight-bold"/>
					}}
				/>
			</p>
			<ButtonShare color="primary" fullwidth type="inviteUnlockThisVote"/>
		</Modal>
	)
}

export default ModalAdditionalVoteLocked
