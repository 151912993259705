const INIT_STATE = {}

const Share = (state = INIT_STATE, action) => {
	switch (action.type) {
		default:
			return state
	}
}

export default Share
