import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Modal as ReactstrapModal, ModalBody } from 'reactstrap'
import { toggleModal } from '@Store/template/actions'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

const heightSticky = 50
const styleSticky = {
	left: 0,
	right: 0,
	bottom: 0,
	height: heightSticky,
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	backgroundColor: 'rgba(221, 221, 221, 0.2)',
	backdropFilter: 'blur(10px)',
	position: 'fixed',
	zIndex: '3000'
}

const Modal = props => {

	const dispatch = useDispatch()

	const {
		name: modalName,
		dismissable,
		bodyClassName,
		className,
		stickyAction,
		children,
		centered,
		type,
	} = props

	const modalData = useSelector(state => state.Template.modal)

	const [modal, setModal] = useState(modalData)
	const history = useHistory()

	let listener

	useEffect(() => {
		listener = () => {
			if (modal[modalName] && modal[modalName].dismissable) {
				dispatch(toggleModal(modalName, { open: false }))
				document.body.style.overflow = 'unset'
				window.history.pushState(null, document.title, window.location.href)
				history.go(1)
			}
		}
		window.addEventListener('popstate', listener)
	})

	useEffect(() => {
		if (modal && !modal.open) window.removeEventListener('popstate', listener)

		if (modal[modalName] && modal[modalName].open) document.body.style.overflow = 'hidden'
		else document.body.style.overflow = 'unset'

		setModal(modalData)
	}, [modalData])

	const isOpen = modal[modalName] && modal[modalName].open

	return (
		<React.Fragment>
			<ReactstrapModal
				size="md"
				isOpen={isOpen}
				toggle={() => {if (dismissable) {dispatch(toggleModal(modalName, { open: false }))}}}
				modalClassName={type}
				contentClassName={className}
				centered={centered}
			>
				{dismissable && (
					<div className="">
						<button
							onClick={() => dispatch(toggleModal(modalName, { open: false }))}
							type="button"
							className="close"
							data-dismiss="modal"
							aria-label="Close"
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
				)}
				<ModalBody className={`${bodyClassName} text-center position-relative`} style={stickyAction && { marginBottom: heightSticky }}>
					{children}
				</ModalBody>
			</ReactstrapModal>
			{stickyAction && isOpen && (
				<div style={styleSticky}>
					<div className="d-flex flex-grow-1 justify-content-center align-items-center wm-400">
						{stickyAction}
					</div>
				</div>
			)}
		</React.Fragment>
	)
}

Modal.defaultProps = {
	dismissable: true,
}

Modal.propTypes = {
	type: PropTypes.oneOf(['modal-mobile-center', 'modal-mobile-full']).isRequired,
	name: PropTypes.string.isRequired,
	children: PropTypes.any,
	centered: PropTypes.bool,
	stickyAction: PropTypes.any,
	dismissable: PropTypes.bool,
	className: PropTypes.string,
	bodyClassName: PropTypes.string,
}

export default Modal
