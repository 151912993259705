import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Modal from '@Modals/Modal'
import _ from 'lodash'
import { openShare } from '@Store/share/actions'
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded'
import ButtonShare from '@Atoms/ButtonShare'
import { MODAL_RANK_CONFIDENCE } from '@Modals/ModalInfos/modals'
import { Trans } from 'react-i18next'

class ModalRankConfidence extends React.Component {

	constructor (props) {
		super(props)
		this.state = {
			user: props.user,
			network: props.network,
		}
	}

	componentDidUpdate (prevProps, prevState, snapshot) {
		if (!_.isEqual(this.props.user, prevProps.user)) this.setState({ user: this.props.user })
		if (!_.isEqual(this.props.network, prevProps.network)) this.setState({ network: this.props.network })
	}

	render () {

		const { user, network } = this.state

		if (
			!user ||
			!network ||
			!network.university ||
			!network.faculty ||
			!network.participants
		) return null

		const partecipantsVerified = network.participants.filter(p => p.verified)

		return (
			<Modal type="modal-mobile-center" name={MODAL_RANK_CONFIDENCE} centered>
				<HelpOutlineRoundedIcon/>
				<h3 className="title mt-2 mb-3"><Trans i18nKey="modalRankConfidence.title"/></h3>
				<div className="caption mb-2"><Trans i18nKey="modalRankConfidence.participants" count={partecipantsVerified.length} /></div>
				<div className="d-flex justify-content-center align-items-center px-5 mb-3">
					<img alt="" width={40} src={network.university.logo} style={{ borderRadius: '10px' }}/>
					<div className="caption ms-2">{network.faculty.name}</div>
				</div>
				<div className="caption mb-2">
					<Trans i18nKey="modalRankConfidence.content" />
				</div>
				<ButtonShare color="primary" fullwidth type="inviteRankConfidence" />
			</Modal>
		)
	}
}

const mapStateToProps = state => {
	return {
		user: state.User.user,
		network: state.Network.network,
	}
}
const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			openShare
			// add other watcher sagas to this object to map them to props
		},
		dispatch
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ModalRankConfidence))
