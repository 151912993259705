import React from 'react'
import { AMBASSADOR_LEVELS, CARD_COLLECTIVE_GOALS } from '@Constants/constants'
import moment from 'moment'

export function scrollToTop () {
	document.body.scrollTop = 0 // For Safari
	document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
}

export function getArrowDelta (delta) {
	switch (delta) {
		case 2:
			return (<i className="text-success fas fa-angle-double-up"/>)
		case 1:
			return (<i className="text-success fas fa-angle-up"/>)
		case 0:
			return (<i className="text-gray-normal">~</i>)
		case -1:
			return (<i className="text-danger fas fa-angle-down"/>)
		case -2:
			return (<i className="text-danger fas fa-angle-double-down"/>)
	}
	return null
}

export function getBadge (goal) {
	const obj = CARD_COLLECTIVE_GOALS.GOALS.filter(g => g.value === goal.to)[0]

	return { icon: obj.icon, title: obj.title }
}

export function getDayNames(locale = 'en', format = 'long') {
	const formatter = new Intl.DateTimeFormat(locale, { weekday: format, timeZone: 'UTC' });
	const days = [1, 2, 3, 4, 5, 6, 7].map(day => {
		const dd = day < 10 ? `0${day}` : day;
		return new Date(`2017-01-${dd}T00:00:00+00:00`);
	});
	return days.map(date => formatter.format(date));
}

export function getMonthNames(locale = 'en', format = 'long') {
	const formatter = new Intl.DateTimeFormat(locale, { month: format, timeZone: 'UTC' });
	const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(month => {
		const mm = month < 10 ? `0${month}` : month;
		return new Date(`2017-${mm}-01T00:00:00+00:00`);
	});
	return months.map(date => formatter.format(date));
}

export function ucfirst(string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getBonusExpirationTime(date) {
	const remainingHours = moment(date).diff(moment(), 'hours')
	if (remainingHours > 0) return { unit: 'hours', value: remainingHours }

	const remainingMinutes = moment(date).diff(moment(), 'minutes')
	if (remainingMinutes > 0) return { unit: 'minutes', value: remainingMinutes }

	return {unit: 'seconds', value: 0}
}

export function calculateBonusByInvites(numInvites) {
	let step = {
		numVotes: numInvites < 2 ? numInvites+1 : 3,
		amountVotes: (numInvites < 3 ? numInvites : 3) * 200,
		numInvites: numInvites,
		amountInvites: numInvites * 15,
		groupGoals: 0,
		ambassadorPercentage: 0,
		ambassadorBonus: 0,
		total: 0
	}
	// Group goal
	step.groupGoals += numInvites >= CARD_COLLECTIVE_GOALS.GOALS[0].value ? 60 : 0
	step.groupGoals += numInvites >= CARD_COLLECTIVE_GOALS.GOALS[1].value ? 30 : 0
	step.groupGoals += numInvites >= CARD_COLLECTIVE_GOALS.GOALS[2].value ? 15 : 0
	step.groupGoals += numInvites >= CARD_COLLECTIVE_GOALS.GOALS[3].value ? 9 : 0

	// Ambassador level
	AMBASSADOR_LEVELS.map(l => {
		if (numInvites >= l.numInvites) step.ambassadorPercentage = l.sharePercentage
	})

	step.ambassadorBonus = (step.amountVotes + step.amountInvites + step.groupGoals) * step.ambassadorPercentage / 100
	//step.total = (step.amountVotes + step.amountInvites + step.groupGoals) * (1 + step.ambassadorPercentage)
	step.total = (step.amountVotes + step.amountInvites + step.groupGoals) + step.ambassadorBonus

	return step
}
