import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { BrowserRouter } from 'react-router-dom'
import './i18n'
import { Provider } from 'react-redux'
import store from './store'
import smartlookClient from 'smartlook-client'
import OneSignal from 'react-onesignal'
import { hotjar } from 'react-hotjar'
import TagManager from 'react-gtm-module'

if (process.env.REACT_APP_ENVIRONMENT === 'production') {

	/* Google Tag Manager */
	TagManager.initialize({ gtmId: 'GTM-5QCJPGL' })
	/* Smartlook */
	smartlookClient.init('fdcee40846a430f68d0ee566eb2cb729d5bf0c08', { region: 'eu' })
	hotjar.initialize(2655306, 6)
}

// DEEP LINK IMPLEMENTATION
/*
import { isAndroid, isIOS } from "react-device-detect"
if (isAndroid) {
	const url =
		"intent://wiraki.com/#Intent;scheme=wiraki;package=com.wiraki.android;end";
	window.location.replace(url);
} else if (isIOS) {
	window.location.replace("wiraki://");
}
*/

const app = (
	<Provider store={store}>
		<BrowserRouter>
			<App/>
		</BrowserRouter>
	</Provider>
)

ReactDOM.render(app, document.getElementById('root'))
//serviceWorker.unregister()
serviceWorker.register()
serviceWorker.addInstallPrompt()

/*
* TEMP LISTENER
*/
self.addEventListener('push', function (event) {
	event.waitUntil(() => {
		console.log('HERE', event)
	})
})

self.addEventListener('push', function (event) {
	console.log('event', event)
	return true
})

OneSignal.init({
	appId: process.env.REACT_APP_ONESIGNAL_APP,
	safari_web_id: process.env.REACT_APP_ONESIGNAL_APP_SAFARI,
	allowLocalhostAsSecureOrigin: process.env.REACT_APP_ONESIGNAL_LOCALHOST === 'true',
	requiresUserPrivacyConsent: false,
	serviceWorkerParam: { scope: '/onesignal/' },
	serviceWorkerPath: 'onesignal/OneSignalSDKWorker.js',
	serviceWorkerUpdaterPath: 'onesignal/OneSignalSDKUpdaterWorker.js',
	promptOptions: {
		actionMessage: 'Get notified when you receive a new reward and when you get voted.',
		acceptButtonText: 'Yes!',
		cancelButtonText: 'Maybe later',
		autoAcceptTitle: 'Click Allow',
		slidedown: {
			enabled: true,
			autoPrompt: false,
			timeDelay: 0,
			pageViews: 0
		}
	}
})
