const INIT_STATE = {
	withdrawRequested: false,
	withdrawStatus: null,
	withdrawErrorMessage: '',
}

const Balance = (state = INIT_STATE, action) => {
	switch (action.type) {
		case 'REQUEST_WITHDRAW':
			return {
				...state,
				withdrawRequested: true
			}
		case 'REQUEST_WITHDRAW_SUCCESS':
			return {
				...state,
				withdrawRequested: false,
				withdrawStatus: true,
			}
		case 'REQUEST_WITHDRAW_ERROR':
			return {
				...state,
				withdrawRequested: false,
				withdrawStatus: false,
				withdrawErrorMessage: action.payload
			}
		case 'BALANCE_RESET_DATA':
			return {
				...state,
				...INIT_STATE
			}
		default:
			return state
	}
}

export default Balance
