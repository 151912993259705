import React, { useEffect, useState } from 'react'
import { Card, CardBody } from 'reactstrap'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Feed from '@Atoms/Feed'

const CardListFeeds = props => {

	const history = useHistory()

	const feedsData = useSelector(state => state.Feeds.feeds)
	const [feeds, setFeeds] = useState(feedsData)
	useEffect(() => setFeeds(feedsData), [feedsData])

	const entryBonusCountdownData = useSelector(state => state.User.entryBonusCountdown)
	const [timeLeft, setTimeLeft] = useState(entryBonusCountdownData)
	useEffect(() => setTimeLeft(entryBonusCountdownData), [entryBonusCountdownData])


	if(!feeds) return null

	return (
		<Card className="align-self-center card-feeds-preview under-header mb-0">
			<CardBody className="d-flex flex-column h-100 px-0 mb-0 pb-0">
				<div className="feeds-list feeds-list-complete">
					{timeLeft && (
						<Feed type={'COUNTDOWN'} data={{}} showTimeLeft />
					)}
					{feeds.map((feed, k) => (
						<Feed key={k} type={feed.feedType} data={feed} />
					))}
				</div>
			</CardBody>
		</Card>
	)
}

export default CardListFeeds
