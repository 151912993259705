import React from 'react'
import {ReactComponent as BadgeMissingIcon} from '@Assets/images/badge_missing.svg'
import {ReactComponent as BadgeSingleIcon} from '@Assets/images/badge_single.svg'
import {ReactComponent as BadgeDoubleIcon} from '@Assets/images/badge_double.svg'
import {ReactComponent as BadgeTripleIcon} from '@Assets/images/badge_triple.svg'
import {ReactComponent as BadgeDiamondIcon} from '@Assets/images/badge_diamond.svg'
import PropTypes from 'prop-types'

class Badge extends React.Component {

	constructor(props) {
		super(props)
	}

	components = {
		missing: BadgeMissingIcon,
		single: BadgeSingleIcon,
		double: BadgeDoubleIcon,
		triple: BadgeTripleIcon,
		diamond: BadgeDiamondIcon,
	}

	render () {
		const BadgeCompoent = this.components[this.props.type || 'missing']
		return <BadgeCompoent
			width={this.props.width}
			height={this.props.height}
			className={this.props.className}
			style={this.props.style}
			onClick={this.props.onClick}
		/>
	}
}

Badge.propTypes = {
	className: PropTypes.string,
	style: PropTypes.object,
	onClick: PropTypes.func,
	type: PropTypes.oneOf(['single', 'double','triple','missing','diamond'])
}

export default Badge
