import { LOAD_LIST_JOBS, LOAD_USER_JOB, LOADED_USER_JOB, SAVE_LIST_JOBS, SAVE_USER_JOB } from '@Store/jobs/actionTypes'

export const loadListJobs = () => ({
	type: LOAD_LIST_JOBS,
	payload: null
})
export const saveListJobs = list => ({
	type: SAVE_LIST_JOBS,
	payload: list
})

export const loadUserJob = () => ({
	type: LOAD_USER_JOB,
	payload: null
})
export const loadedUserJob = obj => ({
	type: LOADED_USER_JOB,
	payload: obj
})
export const saveUserJob = obj => ({
	type: SAVE_USER_JOB,
	payload: obj
})
