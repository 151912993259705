import { LOAD_FEEDS, LOAD_FEEDS_SUCCESS } from '@Store/feeds/actionTypes'

export const loadFeeds = () => ({
	type: LOAD_FEEDS,
	payload: null
})

export const loadFeedsSuccess = (feeds) => ({
	type: LOAD_FEEDS_SUCCESS,
	payload: feeds
})
