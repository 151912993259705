import { CHANGE_LAYOUT, CHANGE_PAGE, CLOSE_WELCOME_PAGE, TOGGLE_INTERNAL_PAGE, TOGGLE_MODAL } from './actionTypes'

import _ from 'lodash'

export const changePage = data => ({
	type: CHANGE_PAGE,
	payload: data,
})

export const toggleModal = (modal, { dismissable, open, data } = {}) => ({
	type: TOGGLE_MODAL,
	payload: {
		modal,
		open: _.isNil(open) ? true : open,
		data: _.isNil(data) ? null : data,
		dismissable: _.isNil(dismissable) ? true : dismissable
	}
})

export const changeLayout = layout => ({
	type: CHANGE_LAYOUT,
	payload: layout
})
export const closeWelcomePage = () => ({
	type: CLOSE_WELCOME_PAGE,
	payload: null
})

export const toggleInternalPage = (pageName, {dismissable, open, data} = {}) => ({
	type: TOGGLE_INTERNAL_PAGE,
	payload: {
		pageName,
		open: _.isNil(open) ? true : open,
		data: _.isNil(data) ? null : data,
		dismissable: _.isNil(dismissable) ? true : dismissable
	}
})
