import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Cards from '@Cards'
import Modal from '@Modals/Modal'
import { MODAL_SHARE } from '@Modals/ModalInfos/modals'

class ModalShare extends React.Component {

	constructor (props) {
		super(props)
		this.state = {}
	}

	componentDidUpdate (prevProps, prevState, snapshot) {
	}

	render () {
		return (
			<Modal type="modal-mobile-center" name={MODAL_SHARE} centered>
				<Cards.CardShare type="ShareToGainMore" className="mb-0"/>
			</Modal>
		)
	}
}

const mapStateToProps = state => {
	return {}
}
const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			// add other watcher sagas to this object to map them to props
		},
		dispatch
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ModalShare))

