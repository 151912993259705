import React, {useEffect, useState} from 'react'
import {Card, CardBody} from 'reactstrap'
import {Trans} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {useSelector} from 'react-redux'
import Feed from '@Atoms/Feed'
import routes from '@Routes/routeConfig'

const CardFeedsPreview = props => {

    const history = useHistory()

    const feedsData = useSelector(state => state.Feeds.feeds)
    const [feeds, setFeeds] = useState(feedsData)
    useEffect(() => setFeeds(feedsData), [feedsData])

    const entryBonusCountdownData = useSelector(state => state.User.entryBonusCountdown)
    const [timeLeft, setTimeLeft] = useState(entryBonusCountdownData)
    useEffect(() => setTimeLeft(entryBonusCountdownData), [entryBonusCountdownData])

    if (!feeds) return null

    let listFeeds = feeds.slice(0, 2)
    if (!timeLeft) listFeeds = feeds.slice(0, 3)

    return (
        <Card className="align-self-center card-feeds-preview under-header">
            <CardBody className="d-flex flex-column pt-4">
                <div className="clickable" onClick={() => history.push(routes.LIST_FEEDS.location)}>
                    <div className="d-flex flex-row align-items-center justify-content-between">

                        <div className="h7"><Trans i18nKey="cardFeeds.title"/></div>
                        {listFeeds.length > 2 && (
                            <div
                                className="body-small font-weight-bold text-secondary"
                            >
                                <Trans i18nKey="cardFeeds.seeAll"/>
                            </div>
                        )}
                    </div>
                    <hr style={{marginTop: '10px', marginBottom: '14px'}}/>
                    <div className="feeds-list">
                        {timeLeft && (
                            <Feed className="mt-0" type={'COUNTDOWN'} data={{}} short showTimeLeft/>
                        )}
                        {listFeeds.map((feed, k) => (
                            <Feed key={k} className={!timeLeft && k === 0 ? 'mt-0' : ''} type={feed.feedType}
                                  data={feed}
                                  short/>
                        ))}
                        <div className="feeds-blurred"/>
                    </div>
                </div>
            </CardBody>
        </Card>
    )

}

export default CardFeedsPreview
