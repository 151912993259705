import React from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'
import { Trans } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { shareCertificate } from '@Store/actions'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import { shareLinkedin } from '@Helpers/share'

const CertificateFound = props => {

	const { isAuthorized, certificates, university, faculty, pageUrl } = props

	const linkedinShare = shareLinkedin(pageUrl)

	const dispatch = useDispatch()
	const toggleShareFormal = () => dispatch(shareCertificate(certificates.pngSm, pageUrl, ''))

	return (
		<React.Fragment>
			<Card>
				<CardBody className="text-center position-relative">
					{!isAuthorized && (
						<React.Fragment>
							<div className="d-flex flex-column align-items-center mb-2">
								<img alt="" width={40} src={university.logo} style={{ borderRadius: '10px' }}/>
							</div>
							<h3 className="title text-center mt-2 mb-3">
								{university.name}<br/>
								{faculty.name}
							</h3>
						</React.Fragment>
					)}
					<div className="position-relative" style={{ display: 'inline-block' }}>
						{/* Open pdf, show preview as png */}
						<a href={certificates.pdf} target="_blank">
							<img src={certificates.pngMd} alt="" style={{ maxWidth: '100%' }}/>
							<CloudDownloadIcon className="certificate-download-icon"/>
						</a>
					</div>
					{isAuthorized && (
						<React.Fragment>
							<hr/>
							<Row>
								<Col
									xs={6}
									style={{ borderRight: '1px solid #dedede' }}
								>
									<a href={linkedinShare} target="_blank">
										<div className={`clickable text-gray-dark caption-big d-flex align-items-center justify-content-center`}>
											<Trans
												i18nKey="pageCertificate.shareLinkedin"
												components={{ icon: <i style={{ fontSize: '1.5rem' }} className="fa bx bxl-linkedin-square text-linkedin"/> }}
											/>
										</div>
									</a>
								</Col>
								<Col
									xs={6}
									onClick={() => toggleShareFormal()}
								>
									<div className={`clickable caption-big`}>
										<Trans i18nKey="pageCertificate.share" components={{ icon: <i className="fa fa-share"/> }}/>
									</div>
								</Col>
							</Row>
						</React.Fragment>
					)}
				</CardBody>
			</Card>
		</React.Fragment>
	)
}

export default CertificateFound
