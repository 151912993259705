import React, { useEffect } from 'react'
import Modal from '@Modals/Modal'
import { Trans, useTranslation } from 'react-i18next'
import { MODAL_FORGOT_PASSWORD, MODAL_FORGOT_PASSWORD_CONFIRM } from '@Modals/ModalInfos/modals'
import { useDispatch, useSelector } from 'react-redux'
import Input from '@Atoms/Input'
import InputBox from '@Molecules/InputBox'
import { useForm } from 'react-hook-form'
import { userForgetPassword } from '@Store/auth/forgetPassword/actions'
import { toggleModal } from '@Store/actions'

const ModalForgotPassword = props => {

	const modal = useSelector(state => state.Template.modal[MODAL_FORGOT_PASSWORD])

	const { t } = useTranslation()

	const dispatch = useDispatch()

	const {
		register,
		handleSubmit,
		formState: { errors },
		formState,
		setValue,
		watch
	} = useForm({
		mode: 'onBlur',
		reValidateMode: 'onChange',
	})

	useEffect(() => {
		if (modal && modal.data) setValue('forgotPassword', modal.data)
	}, [modal])

	if (!modal) return null

	const submit = () => {
		dispatch(userForgetPassword({ email: watch().emailForgotPassword }, props.history))
		dispatch(toggleModal(MODAL_FORGOT_PASSWORD, { open: false }))
		dispatch(toggleModal(MODAL_FORGOT_PASSWORD_CONFIRM))
	}

	return (
		<Modal type="modal-mobile-center" className="w-85 mx-auto" name={MODAL_FORGOT_PASSWORD} centered>
			<div className="my-2 h5"><Trans i18nKey="pageLogin.modalForgotPassword.title"/></div>
			<div className="my-2 mb-4"><Trans i18nKey="pageLogin.modalForgotPassword.subtitle"/></div>
			<form className="container-form" onSubmit={handleSubmit(submit)}>
				<div>
					<InputBox
						errors={errors.emailForgotPassword}
						touched={formState.touchedFields.emailForgotPassword || !!watch().emailForgotPassword}
						label={t(`pageLogin.inputEmail.label`)}
					>
						<Input
							required
							register={register}
							name="emailForgotPassword"
							errors={errors.emailForgotPassword}
							touched={formState.touchedFields.emailForgotPassword || !!watch().emailForgotPassword}
							placeholder={t(`pageLogin.inputEmail.placeholder`)}
							registerOptions={{ required: true, validate: async (value) => { return /^.*@.*\..*$/.test(value)} }}
							type="email"/>
					</InputBox>
				</div>
				<div className="px-3 mt-4">
					<button
						className="btn btn-primary btn-rounded btn-block w-50 px-2"
						type="submit"
					>
						<Trans i18nKey="pageLogin.modalForgotPassword.button"/>
					</button>
				</div>
			</form>
		</Modal>
	)
}

export default ModalForgotPassword

