export const SAVE_VOTES_DATA = 'SAVE_VOTES_DATA'
export const CHANGE_VOTE = 'CHANGE_VOTE'
export const CHANGE_VOTE_SUCCESS = 'CHANGE_VOTE_SUCCESS'
export const CLAIM_VOTE = 'CLAIM_VOTE'
export const CLAIM_VOTE_SUCCESS = 'CLAIM_VOTE_SUCCESS'

/* RESPONSE ERRORS to CHANGE_VOTE */
export const CHANGE_VOTE_ERROR_VOTE_ALREADY_CLAIMED = 'CHANGE_VOTE_ERROR_VOTE_ALREADY_CLAIMED'
export const CHANGE_VOTE_ERROR_VOTE_COUNT_NOT_FOUND = 'CHANGE_VOTE_ERROR_VOTE_COUNT_NOT_FOUND'
export const CHANGE_VOTE_ERROR_VOTE_CHANGE_TOO_EARLY = 'CHANGE_VOTE_ERROR_VOTE_CHANGE_TOO_EARLY'
export const CHANGE_VOTE_ERROR_TOO_MANY_VOTES = 'CHANGE_VOTE_ERROR_TOO_MANY_VOTES'
export const CHANGE_VOTE_ERROR_ALREADY_VOTED = 'CHANGE_VOTE_ERROR_ALREADY_VOTED'

export const GET_INVITE_TO_VOTE_LINK = 'GET_INVITE_TO_VOTE_LINK'
export const SAVE_INVITE_TO_VOTE_LINK = 'SAVE_INVITE_TO_VOTE_LINK'
export const SET_VOTE_FLOW_ACTIVE = 'SET_VOTE_FLOW_ACTIVE'
