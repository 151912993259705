import React, {useEffect, useState} from 'react'
import {Card, CardBody, CardHeader, Col} from 'reactstrap'
import {Trans} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {formatCurrency} from '@Helpers/number_helper'
import ButtonShare from '@Atoms/ButtonShare'
import {useHistory} from 'react-router-dom'
import routes from '@Routes/routeConfig'

const CardMonitorInvites = props => {

    const history = useHistory()

    const userData = useSelector(state => state.User.user)

    const [user, setUser] = useState(userData)

    useEffect(() => setUser(userData), [userData])

    if (!user.verified) return null

    // Count referrals Accepted
    const referralsAccepted = user.referrals.items.reduce((num, item) => item.verified ? ++num : num, 0)
    const referralsPending = user.referrals.items.length - referralsAccepted

    return (
        <Card className="align-self-center card-monitor-invites">
            <CardHeader className="clickable" onClick={() => history.push(routes.MY_INVITES.name)}>
                <div className="position-relative">
                    <div className="h5 fw-semibold mb-1"><Trans i18nKey="cardMonitorInvites.title"/></div>
                    <button
                        type="button"
                        className="top-right-icon"
                        data-dismiss="modal"
                        aria-label="Close"
                    />
                </div>
            </CardHeader>
            <CardBody className="d-flex flex-column">
                <div className="mb-4 position-relative">
                    <div className=""><Trans i18nKey="cardMonitorInvites.subtitle"/></div>
                </div>
                <div className="box-container mb-2">
                    <Col xs={4} className="column">
                        <div className="box">
                            <div className="count">{user.referrals.visits || 0}</div>
                            <div className="text"><Trans i18nKey="cardMonitorInvites.click"/></div>
                        </div>
                    </Col>
                    <Col xs={4} className="column">
                        <div className="box">
                            <div className="count">{referralsPending}</div>
                            <div className="text"><Trans i18nKey="cardMonitorInvites.pending"/></div>
                        </div>
                    </Col>
                    <Col xs={4} className="column">
                        <div className="box">
                            <div className="count">{referralsAccepted}</div>
                            <div className="text"><Trans i18nKey="cardMonitorInvites.registered"/></div>
                        </div>
                    </Col>
                </div>
                <hr/>
                <div>
                    <div className="h7"><Trans i18nKey="cardMonitorInvites.benefits.title"/></div>
                    <table className="w-100 border-0 table-benefits">
                        <tbody>
                        <tr>
                            <td>💶</td>
                            <td><Trans
                                i18nKey="cardMonitorInvites.benefits.1"
                                values={{amount: formatCurrency(15, user.currency)}}
                                components={{
                                    small: <small/>
                                }}
                            /></td>
                        </tr>
                        <tr>
                            <td>🏅</td>
                            <td><Trans
                                i18nKey="cardMonitorInvites.benefits.2"
                                values={{amount: formatCurrency(15, user.currency)}}
                                components={{
                                    small: <small style={{lineHeight: '90%'}}/>
                                }}
                            /></td>
                        </tr>
                        </tbody>
                    </table>
                    <div className="text-center" style={{marginTop: '42px'}}>
                        <div className="w-100 w-lg-75 mx-auto">
                            <ButtonShare className="w-75" color="primary" type="inviteNow"/>
                        </div>
                    </div>
                </div>
            </CardBody>
        </Card>
    )
}

export default CardMonitorInvites
