import { all, call, put, takeEvery } from 'redux-saga/effects'
import { LOAD_LIST_REFERRAL, LOAD_USER_DATA } from '@Store/user/actionTypes'
import axios from 'axios'
import { getHeaders, getLanguage, handleError } from '@Store/helpers'
import { AMBASSADOR_LEVELS } from '@Constants/constants'
import moment from 'moment'
import { saveUserData } from '@Store/actions'
import i18n from 'i18next'

function * setLanguage (lang) {
	try {
		yield call(() => axios.patch(`${process.env.REACT_APP_ENDPOINT_API_USER}/v1/me`, { language: lang }, getHeaders()))
	} catch (e) { handleError(e) }
}

function * getUserData () {
	try {
		const { data: me } = yield call(() => axios.get(`${process.env.REACT_APP_ENDPOINT_API_USER}/v1/me`, getHeaders()))


		const browserLang = getLanguage().langFull
		/* If language is not set, set it */
		if (!me.language) {
			me.language = browserLang
			yield call(() => setLanguage(browserLang))
		}

		/* If the language from backend is different, change it unless there is a test key */
		if (me.language !== browserLang && !localStorage.getItem('testLang')) {
			i18n.changeLanguage(me.language.substring(0, 2))
		} else if (localStorage.getItem('testLang')) {
			i18n.changeLanguage(localStorage.getItem('testLang').substring(0, 2))
		}

		let ambassadorLevel = null
		AMBASSADOR_LEVELS.map((level, k) => {
			if (level.sharePercentage === me.rewardAmbassador.sharePercentage) {
				let nextLevel = null
				if (AMBASSADOR_LEVELS[k + 1]) nextLevel = AMBASSADOR_LEVELS[k + 1]
				ambassadorLevel = {
					...level,
					nextLevel,
				}
			}
		})

		delete me.balance

		const user = {
			...me,
			//verified: false,
			reward: null,
			bonusVerifyBefore: moment(me.createdAt).add(me.profile.timeToVerifyAccount, 'seconds').toISOString(),
			//bonusVerifyBefore: moment().add(22, 'hours').toISOString(),
			nextInvite: {
				rewardBase: me.nextInvite.rewardForNextInvite,
				rewardBonus: me.nextInvite.rewardForNextInvite + me.nextInvite.rewardBonusForNextInvite,
				amountBonus: me.nextInvite.rewardBonusForNextInvite,
				rewardAmbassador: me.nextInvite.rewardAmbassador,
			},
			rewardAmbassador: {
				...me.rewardAmbassador,
				...ambassadorLevel
			}
		}
		yield put(saveUserData(user))
	} catch (error) { handleError(error) }
}

function * getListReferral () {
	try {
		const { data } = yield call(() => axios.get(`${process.env.REACT_APP_ENDPOINT_API_USER}/v1/me/invited`, getHeaders()))
		const user = {
			referrals: data,
		}
		yield put(saveUserData(user))
	} catch (error) {
		handleError(error)
	}
}

function * UserSaga () {
	yield all([
		takeEvery(LOAD_USER_DATA, getUserData),
		takeEvery(LOAD_LIST_REFERRAL, getListReferral),
	])
}

export default UserSaga
