import React from 'react'
import { Card, CardBody } from 'reactstrap'
import WarningRoundedIcon from '@mui/icons-material/WarningRounded'
import PropTypes from 'prop-types'

const CardWarningShort = props => {

	if (props.show === false) return null

	return (
		<Card className="bg-primary-red">
			<CardBody className="d-flex flex-direction-row align-items-center">
				<WarningRoundedIcon className="me-2 text-primary"/>
				<div className="caption-small text-start">
					{props.content}
				</div>
			</CardBody>
		</Card>
	)
}

CardWarningShort.propTypes = {
	content: PropTypes.string.isRequired,
	show: PropTypes.bool
}

export default CardWarningShort
