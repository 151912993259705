import { Trans } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { formatCurrency } from '@Helpers/number_helper'
import { useSelector } from 'react-redux'
import { getBonusExpirationTime } from '@Helpers/global'

const JoinerBonusLine = props => {

	const userData = useSelector(state => state.User.user)

	const [user, setUser] = useState(userData)

	useEffect(() => setUser(userData), [userData])

	if (
		!user ||
		!user.nextInvite
	) return null
	if (moment(user.bonusVerifyBefore) < moment() || user.nextInvite.amountBonus === 0) return null

	const { unit, value } = getBonusExpirationTime(user.bonusVerifyBefore)


	return (
		<div className={`${props.className} caption-small text-center mt-2 font-weight-bold`}>
			{unit === 'hours' && (
				<Trans
					i18nKey="joinerBonusLine.hours"
					values={{
						count: value,
						amount: formatCurrency(user.nextInvite.amountBonus, user.currency)
					}}
					components={{ i: <i className="bx bx-gift"/> }}
				/>
			)}
			{unit === 'minutes' && (
				<Trans
					i18nKey="joinerBonusLine.minutes"
					values={{
						count: value,
						amount: formatCurrency(user.nextInvite.amountBonus, user.currency)
					}}
					components={{ i: <i className="bx bx-gift"/> }}
				/>
			)}
		</div>
	)
}

export default JoinerBonusLine
