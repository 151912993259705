import React, { useEffect, useState } from 'react'
import Button from '@Atoms/Button'
import { useDispatch, useSelector } from 'react-redux'
import { openShare } from '@Store/actions'
import PropTypes from 'prop-types'
import { formatCurrency } from '@Helpers/number_helper'
import PaperPlaneSend from '@Icons/PaperPlaneSend'
import { Trans } from 'react-i18next'

const ButtonShare = props => {

	const dispatch = useDispatch()

	const userData = useSelector(state => state.User.user)
	const [user, setUser] = useState(userData)
	useEffect(() => setUser(userData), [userData])

	if (
		!user ||
		!user.nextInvite
	) return null

	const { className, type, name, color } = props

	return (
		<Button
			{...props}
			onClick={() => dispatch(openShare())}
			className={`${className} btn-rounded`}
		>
			{user.nextInvite.rewardBonus === 0 ? (
				<Trans
					i18nKey={`buttonShare.${type}_noReward`}
					values={{
						amount: formatCurrency(user.nextInvite.rewardBonus, user.currency),
						name: name
					}}
					components={{
						icon: <PaperPlaneSend className={color && `text-${color}`}/>
					}}
				/>
			) : (
				<Trans
					i18nKey={`buttonShare.${type}`}
					values={{
						amount: formatCurrency(user.nextInvite.rewardBonus, user.currency),
						name: name
					}}
					components={{
						icon: <PaperPlaneSend className={color && `text-${color}`}/>
					}}
				/>
			)}
		</Button>
	)

}

ButtonShare.propTypes = {
	type: PropTypes.oneOf([
			'inviteFriend',
			'invite',
			'spreadRevolution',
			'inviteEarnBadge',
			'inviteRankConfidence',
			'inviteShort',
			'joinColleague',
			'invitePerson',
			'inviteColleague',
			'inviteUnlockThisVote',
			'inviteStartContest',
			'inviteToUnlock',
			'inviteNow',
			'increaseSupport',
			'joinNow',
			'inviteStudents',
			'inviteNow'
		]
	).isRequired,
}

export default ButtonShare
