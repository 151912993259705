import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Col, Container, Row } from 'reactstrap'
import { bindActionCreators } from 'redux'
import { changePage } from '@Store/template/actions'
import Logo from '@Icons/Logo'

class NonAuthDefault extends React.Component {

	constructor (props) {
		super(props)
		this.state = {}
	}

	componentDidUpdate (prevProps, prevState, snapshot) {

	}

	render () {
		return (
			<React.Fragment>
				<div className="" style={{ overflowY: 'auto' }} id="page-scroll">
					<div style={{padding: '14px 0'}} className="bg-primary d-flex align-items-center justify-content-center">
						<Logo style={{width: '26px', height: '26px'}} className="text-white"/> <span className="h5 text-white ms-2 mb-0">Wiraki</span>
					</div>
					<Container fluid style={{ height: '100%' }} id={`page-non-auth`}>
						<Row>
							<Col md={{ size: 12, offset: 0 }} sm={12} className="d-flex flex-column">
								{this.props.children}
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		)
	}
}

const mapStateToProps = state => {
	return {}
}
const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			changePage
			// add other watcher sagas to this object to map them to props
		},
		dispatch
	)
}
NonAuthDefault.propTypes = {
	children: PropTypes.any,
	location: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NonAuthDefault))
