import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {Card, CardBody, Col, Row} from 'reactstrap'
import _ from 'lodash'
import ChartistGraph from 'react-chartist'
import 'chartist/dist/scss/chartist.scss'
import '@Assets/scss/chartist.scss'
import moment from 'moment'
import {Trans} from 'react-i18next'
import {defaultData} from '@Data/inviteData'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded'
import {faker} from '@faker-js/faker/locale/en'

const lineChartOptions = {
    fullWidth: true,
    onlyInteger: true,
    chartPadding: {
        right: 10,
        top: 10,
        left: 0,
        bottom: 0,
    },
    axisY: {
        onlyInteger: true,
    }
}

const CardReferralGraph = props => {

    const userData = useSelector(state => state.User.user)
    const [user, setUser] = useState(userData)
    useEffect(() => setUser(userData), [userData])

    const buildGraphData = (user) => {
        let listEvents = []

        /* Add all events type 'click' */
        user.referrals.events.map(e => {
            listEvents.push({date: e, value: 'click'})
        })

        /* Add all events type 'invite' and 'verified' */
        user.referrals.items.map(item => {
            if (item.verified) {
                listEvents.push({date: item.verifiedAt, value: 'verified'})
            }
            listEvents.push({date: item.createdAt, value: 'invite'})
        })

        listEvents.sort((a, b) => moment(a.date) - moment(b.date))

        /* */
        let click = 0
        let invite = 0
        let verified = 0
        let listClick = [0]
        let listInvite = [0]
        let listVerified = [0]
        listEvents.map(e => {
            switch (e.value) {
                case 'click':
                    click++
                    break
                case 'invite':
                    invite++
                    break
                case 'verified':
                    verified++
                    break
            }
            if (click !== 0) listClick.push(click)
            listInvite.push(invite)
            listVerified.push(verified)
        })

        return [listClick, listInvite, listVerified]
    }

    if (
        !user ||
        !user.referrals ||
        !user.rewardAmbassador
    ) return null

    const referrals = _.orderBy(user.referrals.items, ['verified'], ['desc'])

    return (
        <Card className={`d-flex flex-direction-row align-items-center card-referral-graph`}>
            <CardBody className="w-100">
                <div className="h7 mb-1 mb-lg-4"><Trans i18nKey="cardReferralGraph.title"/></div>
                <div className="d-flex flex-column flex-lg-row">
                    <div className="my-2 mx-lg-auto text-center mt-4 my-lg-2 d-flex flex-row flex-lg-column align-items-center justify-content-evenly justify-content-lg-between">
                        <div className="number px-0 d-flex flex-column py-lg-2">
                            <div className="font-weight-normal font-size-32 gray-6 mb-2">{user.referrals.visits}</div>
                            <div className="font-size-12 text-secondary"><Trans i18nKey="cardReferralGraph.clicks"/>
                            </div>
                        </div>
                        <div className="number px-0 d-flex flex-column py-lg-2">
                            <div
                                className="font-weight-normal font-size-32 gray-6 mb-2">{user.referrals.numberNotVerifiedAccounts + user.referrals.numberVerifiedAccounts}</div>
                            <div className="font-size-12 text-primary"><Trans i18nKey="cardReferralGraph.users"/></div>
                        </div>
                        <div className="number px-0 d-flex flex-column py-lg-2">
                            <div
                                className="font-weight-normal font-size-32 gray-6 mb-2">{user.referrals.numberVerifiedAccounts}</div>
                            <div className="font-size-12 text-success"><Trans i18nKey="cardReferralGraph.accounts"/>
                            </div>
                        </div>
                    </div>
                    <div className="position-relative w-100">
                        {(user.referrals.events.length !== 0 || user.referrals.items.length !== 0) ? (
                            <ChartistGraph
                                data={{series: buildGraphData(user)}}
                                options={lineChartOptions}
                                type={'Line'}
                                className="graphReferrals"
                            />
                        ) : (
                            <>
                                <div
                                    className="d-flex align-items-center justify-content-center"
                                    style={{
                                        textAlign: 'center',
                                        position: 'absolute',
                                        inset: 0,
                                        backdropFilter: 'blur(3px)',
                                    }}
                                >
                                    <Trans i18nKey="cardReferralGraph.waiting"/>
                                </div>
                                <ChartistGraph
                                    data={{series: buildGraphData(defaultData)}}
                                    options={lineChartOptions}
                                    type={'Line'}
                                    className="graphReferrals"
                                />
                            </>
                        )}
                    </div>
                </div>
                {user.referrals.visits !== 0 && (
                    <div id="table-referrals">
                        <div className="thead">
                            <div className="tr">
                                <div className="th"></div>
                                <div className="th text-center overline"><Trans i18nKey="cardReferralGraph.clicks"/>
                                </div>
                                <div className="th text-center overline"><Trans i18nKey="cardReferralGraph.users"/>
                                </div>
                                <div className="th text-center overline"><Trans i18nKey="cardReferralGraph.accounts"/>
                                </div>
                            </div>
                        </div>
                        <div className="tbody">
                            {referrals.map((referral, k) => (
                                <div className="tr" key={k}>
                                    <div className="td body-small gray-6">{referral.fullName}</div>
                                    <div className="td text-center align-middle"><CheckCircleRoundedIcon
                                        style={{fontSize: '18px'}} className="text-secondary"/></div>
                                    <div className="td text-center align-middle"><CheckCircleRoundedIcon
                                        style={{fontSize: '18px'}} className="text-primary"/></div>
                                    <div className="td text-center align-middle">
                                        {referral.verified ?
                                            (<CheckCircleRoundedIcon style={{fontSize: '18px'}}
                                                                     className="text-success"/>) :
                                            (<RadioButtonUncheckedRoundedIcon style={{fontSize: '18px'}}
                                                                              className="gray-3"/>)}
                                    </div>
                                </div>
                            ))}
                            {_.range(user.referrals.visits - referrals.length).map((k) => (
                                <div className="tr" key={k}>
                                    <div className="td position-relative align-middle">
                                        {faker.name.firstName().substring(0, 6)} {faker.name.lastName().substring(0, 8)}
                                        <div
                                            style={{
                                                textAlign: 'center',
                                                position: 'absolute',
                                                inset: 0,
                                                backdropFilter: 'blur(3px)',
                                            }}/>
                                    </div>
                                    <div className="td text-center align-middle"><CheckCircleRoundedIcon
                                        style={{fontSize: '18px'}} className="text-secondary"/></div>
                                    <div className="td text-center align-middle"><RadioButtonUncheckedRoundedIcon
                                        style={{fontSize: '18px'}} className="gray-3"/></div>
                                    <div className="td text-center align-middle"><RadioButtonUncheckedRoundedIcon
                                        style={{fontSize: '18px'}} className="gray-3"/></div>
                                </div>
                            ))}
                        </div>
                        <div className="tfoot">
                            <div className="tr">
                                <div className="td body-small gray-6"><Trans i18nKey="cardReferralGraph.total"/></div>
                                <div
                                    className="td text-center align-middle body-small gray-6 font-weight-medium">{user.referrals.visits}</div>
                                <div
                                    className="td text-center align-middle body-small gray-6 font-weight-medium">{user.referrals.numberVerifiedAccounts + user.referrals.numberNotVerifiedAccounts}</div>
                                <div
                                    className="td text-center align-middle body-small gray-6 font-weight-medium">{user.referrals.numberVerifiedAccounts}</div>
                            </div>
                        </div>
                    </div>
                )}
            </CardBody>
        </Card>
    )
}

export default CardReferralGraph
