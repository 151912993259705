export const defaultData = {
	referrals: {
		visits: 20,
		numberVotes: 6,
		numberVerifiedAccounts: 2,
		numberNotVerifiedAccounts: 1,
		items: [
			{
				fullName: 'Paolo Faraoni',
				verified: true,
				createdAt: '2021-11-04T13:57:48.000Z',
				verifiedAt: '2021-11-04T13:58:55.000Z',
				detail: null
			},
			{
				fullName: 'Giammaria Anatroccolo',
				verified: true,
				createdAt: '2021-12-28T10:42:09.000Z',
				verifiedAt: '2021-12-28T10:42:43.000Z',
				detail: null
			},
			{
				fullName: 'John Menny',
				verified: false,
				createdAt: '2021-11-04T13:55:43.000Z',
				verifiedAt: null,
				detail: null
			}
		],
		events: [
			'2022-01-20T10:59:02.000Z',
			'2022-01-20T10:58:55.000Z',
			'2021-12-28T10:41:10.000Z',
			'2021-12-28T09:49:25.000Z',
			'2021-12-28T09:49:03.000Z',
			'2021-12-28T09:48:47.000Z',
			'2021-12-19T17:13:04.000Z',
			'2021-11-19T15:45:15.000Z',
			'2021-11-19T14:54:03.000Z',
			'2021-11-19T14:37:05.000Z',
			'2021-11-16T15:17:03.000Z',
			'2021-11-04T15:17:02.000Z',
			'2021-11-03T15:17:01.000Z',
			'2021-11-03T15:17:01.000Z',
			'2021-11-03T15:16:59.000Z'
		]
	}
}
