import {
	LOAD_ALL_NETWORK_DATA,
	LOAD_CERTIFICATE,
	LOAD_LIST_HIRINGS,
	LOAD_LIST_PARTICIPANTS,
	LOAD_NETWORK_DATA, SAVE_LIST_HIRINGS,
	SAVE_LIST_PARTICIPANTS,
	SAVE_NETWORK_DATA,
	SAVE_NETWORK_DATA_SAGA
} from '@Store/network/actionTypes'

export const loadAllNetworksData = () => ({
	type: LOAD_ALL_NETWORK_DATA,
	payload: null
})
export const loadNetworkData = (networkId) => ({
	type: LOAD_NETWORK_DATA,
	payload: networkId
})
export const loadListParticipants = (networkId) => ({
	type: LOAD_LIST_PARTICIPANTS,
	payload: networkId,
})

export const saveNetworkData = (networkId, data) => ({
	type: SAVE_NETWORK_DATA,
	payload: {
		networkId,
		data
	}
})
export const saveNetworkDataSaga = (networkId, data) => ({
	type: SAVE_NETWORK_DATA_SAGA,
	payload: {
		networkId,
		data
	}
})
export const saveListParticipants = (networkId, data) => ({
	type: SAVE_LIST_PARTICIPANTS,
	payload: {
		networkId,
		data
	}
})

export const loadCertificate = (userUrl, docHash) => ({
	type: LOAD_CERTIFICATE,
	payload: {
		userUrl,
		docHash,
	}
})
