import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { Button, ButtonGroup, Card, CardBody } from 'reactstrap'
import _ from 'lodash'
import { openShare } from '@Store/actions'
import LeaderboardRank from '@Cards/CardLeaderboard/LeaderboardRank'
import LeaderboardVoters from '@Cards/CardLeaderboard/LeaderboardVoters'
import { Trans } from 'react-i18next'

class CardLeaderboard extends React.Component {

	constructor (props) {
		super(props)
		this.state = {
			user: props.user,
			network: props.network,
			activeTab: props.user.receivedVotes ? 'rank' : 'voters',
		}
	}

	componentDidUpdate (prevProps, prevState, snapshot) {
		if (!_.isEqual(this.props.user, prevProps.user)) {
			let newState = { user: this.props.user }
			if (!_.isEqual(this.props.user.receivedVotes, prevProps.user.receivedVotes)) {
				newState.activeTab = this.props.user.receivedVotes ? 'rank' : 'voters'
			}
			this.setState(newState)
		}
		if (!_.isEqual(this.props.network, prevProps.network)) this.setState({ network: this.props.network })
	}

	render () {
		const { user, network, activeTab } = this.state

		if (
			!user ||
			!user.referrals ||
			!network ||
			!network.leaderboardRank ||
			!network.leaderboardVoters
		) return null

		return (
			<Card className="align-self-center">
				<CardBody className="d-flex flex-column">
					<h3 className="title mb-2"><Trans i18nKey="cardLeaderboard.title"/></h3>
					<div className="text-center mb-3">
						<ButtonGroup className="btn-rounded w-75">
							<Button
								type="button"
								color="primary"
								outline
								size="sm"
								active={activeTab === 'rank'}
								onClick={() => this.setState({ activeTab: 'rank' })}
							>
								<Trans i18nKey="cardLeaderboard.topWRank"/>
							</Button>
							<Button
								type="button"
								color="primary"
								outline
								size="sm"
								active={activeTab === 'voters'}
								onClick={() => this.setState({ activeTab: 'voters' })}
							>
								<Trans i18nKey="cardLeaderboard.topVoters"/>
							</Button>
						</ButtonGroup>
					</div>
					{activeTab === 'rank' && (
						<LeaderboardRank network={network} user={user}/>
					)}
					{activeTab === 'voters' && (
						<LeaderboardVoters network={network} user={user}/>
					)}
				</CardBody>
			</Card>
		)
	}
}

const mapStateToProps = state => {
	return {
		user: state.User.user,
		network: state.Network.network,
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			openShare
			// add other watcher sagas to this object to map them to props
		},
		dispatch
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CardLeaderboard))
