import { SAVE_LIST_PARTICIPANTS, SAVE_NETWORK_DATA, SAVE_NETWORK_DATA_SAGA } from '@Store/network/actionTypes'

const INIT_STATE = {
	network: null,
	networks: null,
	privateNetwork: null,
}

const Network = (state = INIT_STATE, action) => {
	let stateNetworkData = null
	switch (action.type) {
		case SAVE_LIST_PARTICIPANTS:
			stateNetworkData = state.networks ? state.networks[action.payload.networkId] : {}
			return {
				...state,
				networks: {
					...state.networks,
					[action.payload.networkId]: {
						...stateNetworkData,
						participants: action.payload.data
					}
				}
			}
		case SAVE_NETWORK_DATA:
		case SAVE_NETWORK_DATA_SAGA:
			stateNetworkData = state.networks ? state.networks[action.payload.networkId] : {}
			const newState = {
				...state,
				networks: {
					...state.networks,
					[action.payload.networkId]: {
						...stateNetworkData,
						...action.payload.data
					}
				}
			}

			Object.keys(newState.networks).map(key => {
				if (!newState.networks[key].private) {
					newState.network = newState.networks[key]
				}
			})

			return newState
		default:
			return state
	}
}

export default Network
