import { all, call, put, takeEvery } from 'redux-saga/effects'

// Login Redux States
import { ACTIVATE_INVITE_CODE, LOAD_INITIAL_DATA, RESEND_EMAIL_VERIFY } from './actionTypes'
import axios from 'axios'
import {
	emailVerifySent,
	loadAllNetworksData,
	loadBalanceData,
	loadFeeds,
	loadListReferral,
	loadRemoteData,
	loadUserData,
	loadUserJob,
	pushNotificationsCheck,
	saveUserData,
	toggleModal
} from '@Store/actions'
import { getHeaders, handleError } from '@Store/helpers'
import { MODAL_VERIFY_EMAIL_SENT } from '@Modals/ModalInfos/modals'

function * activateInviteCode ({ payload: code }) {
	try {
		yield call(() => axios.post(`${process.env.REACT_APP_ENDPOINT_API_USER}/v1/invites/code/${code}/start`, {}, getHeaders()))
		yield call(() => getCurrentInviteCode())
	} catch (error) { handleError(error) }
}

function * getCurrentInviteCode () {
	try {
		const { data } = yield call(() => axios.get(`${process.env.REACT_APP_ENDPOINT_API_USER}/v1/invites/current`, getHeaders()))
		yield put(saveUserData({ inviteCode: data }))
	} catch (error) { handleError(error) }
}

function * getGlobalStats () {
	try {
		const { data } = yield call(() => axios.get(`${process.env.REACT_APP_ENDPOINT_API_USER}/v1/me/stats`, getHeaders()))
		yield put(saveUserData({ stats: data }))
	} catch(error) {handleError(error)}
}

function * getInitialData () {
	try {
		yield all([
			call(() => getCurrentInviteCode()),
			put(loadListReferral()),
			put(loadBalanceData()),
			put(loadUserData()),
			put(loadAllNetworksData()),
			put(loadRemoteData()),
			put(pushNotificationsCheck()),
			put(loadUserJob()),
			put(loadFeeds()),
			call(() => getGlobalStats())
			//put(loadListJobs())
			/* Fetch background data periodically */
			//call(() => fetchSagaPeriodically())
		])
	} catch (error) { handleError(error) }
}

/*function * fetchSagaPeriodically () {
	while(true) {
		yield all([
			put(loadListReferral()),
			put(loadBalanceData()),
			put(loadUserData()),
			put(loadListParticipants()),
			put(loadNetworkData()),
		])
		yield delay(10000)
	}
}*/

function * sendEmailVerify () {
	try {
		yield call(() => axios.post(`${process.env.REACT_APP_ENDPOINT_API_USER}/v1/me/verify`, {}, getHeaders()))
		yield all([
			put(toggleModal(MODAL_VERIFY_EMAIL_SENT)),
			put(emailVerifySent())
		])
	} catch (error) {
		handleError(error)
	}
}

function * GlobalSaga () {
	yield all([
		takeEvery(LOAD_INITIAL_DATA, getInitialData),

		takeEvery(ACTIVATE_INVITE_CODE, activateInviteCode),

		takeEvery(RESEND_EMAIL_VERIFY, sendEmailVerify),
	])
}

export default GlobalSaga
