import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { Card, CardBody } from 'reactstrap'
import _ from 'lodash'
import JoinerBonusLine from '@Atoms/JoinerBonusLine'
import { formatCurrency } from '@Helpers/number_helper'
import { Trans } from 'react-i18next'

class CardNextInvite extends React.Component {

	constructor (props) {
		super(props)
		this.state = {
			user: props.user,
		}
	}

	componentDidUpdate (prevProps, prevState, snapshot) {
		if (!_.isEqual(this.props.user, prevProps.user)) this.setState({ user: this.props.user })
	}

	render () {
		const { user } = this.state

		if (
			!user
		) return null

		return (
			<Card className="align-items-center card-short">
				<CardBody className="text-center">
					<div className="caption"><Trans i18nKey="cardNextInvite.title" /></div>
					<div className="caption-big my-2 text-primary font-weight-bold">
						{user.nextInvite.amountBonus !== 0 && (
							<span className="text-decoration-line-through font-size-14 me-2">
								+{formatCurrency(user.nextInvite.rewardBase, user.currency)}</span>
						)}
						+{formatCurrency(user.nextInvite.rewardBonus, user.currency)}
					</div>
					<JoinerBonusLine bold/>
				</CardBody>
			</Card>
		)
	}
}

const mapStateToProps = state => {
	return {
		user: state.User.user,
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			// add other watcher sagas to this object to map them to props
		},
		dispatch
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CardNextInvite))
