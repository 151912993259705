import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody, Col, Progress, Row } from 'reactstrap'
import { AMBASSADOR_LEVELS } from '@Constants/constants'
import { numberFormat, formatCurrency } from '@Helpers/number_helper'
import { Trans } from 'react-i18next'
import { calculateBonusByInvites } from '@Helpers/global'

const CardAmbassador = props => {

	const userData = useSelector(state => state.User.user)
	const [user, setUser] = useState(userData)
	useEffect(() => setUser(userData), [userData])

	if (
		!user ||
		!user.rewardAmbassador ||
		!user.referrals
	) return null

	const stepSize = 100 / AMBASSADOR_LEVELS.length
	let currentBarSize = 0 // Initial size
	let prospectBarSize = stepSize
	const numInvites = user.referrals.numberVerifiedAccounts

	AMBASSADOR_LEVELS.map((level, k) => {
		if (level.numInvites !== 0) {
			if (numInvites >= level.numInvites) {
				currentBarSize += stepSize
				prospectBarSize += stepSize
			} else if (k !== 0) { // element in k-1 exists
				/*
				The current bar will increase by the portion of the current goal achieved
				(es. current 6 invites, the steps are 5-10, it will increase by 1/5
				*/
				const numerator = numInvites - AMBASSADOR_LEVELS[k - 1].numInvites
				const denominator = level.numInvites - AMBASSADOR_LEVELS[k - 1].numInvites
				if (numerator > 0) {
					currentBarSize += stepSize * (numerator / denominator)
				}
			}
		}
	})

	currentBarSize = currentBarSize !== 0 ? currentBarSize : 1
	prospectBarSize -= currentBarSize

	return (
		<Card className="align-self-center card-ambassador">
			<CardBody className="d-flex flex-column">
				<div className="h7 mb-1"><Trans i18nKey="cardAmbassador.title"/></div>
				<div className="mb-3"><Trans i18nKey="cardAmbassador.subtitle"/></div>

				<div className="ambassador-level-container">
					<div className="w-max">
						<div className="w-max">
							{AMBASSADOR_LEVELS.map((level, k) => {
								const isActive = numInvites >= level.numInvites
								const step = calculateBonusByInvites(level.numInvites)
								return (
									<div className={`ambassador-level-step percentage ${isActive ? 'active' : ''}`} key={k}>
										<div className="w-content">
											{k !== 0 && (
												<div className="top-value">
													<div className="with-bonus h7">{formatCurrency(step.total, user.currency)}</div>
													<div className="level-step-value">
														<span className="without-bonus">{formatCurrency(step.total - step.ambassadorBonus, user.currency)}</span>
														&nbsp;
														<span>+{level.sharePercentage}%</span>
													</div>
												</div>
											)}
										</div>
									</div>
								)
							})}
						</div>
						<Progress multi className="" style={{ height: '12px', marginTop: '4px' }}>
							<Progress bar color="primary" style={{ zIndex: 100, borderRadius: '0.25rem' }} value={currentBarSize}></Progress>
							<Progress bar color="orange-light" style={{ paddingLeft: '0.25rem', marginLeft: '-0.25rem', zIndex: 75 }} value={prospectBarSize}></Progress>
							<Progress bar color="gray-2" value={100 - currentBarSize - prospectBarSize}></Progress>
						</Progress>
						<div className="w-max">
							{AMBASSADOR_LEVELS.map((level, k) => {
								const isActive = numInvites >= level.numInvites
								return (
									<div className={`ambassador-level-step step ${isActive ? 'active' : ''}`} key={k}>
										<div className="">
											<span className="number">{numberFormat(level.numInvites)}</span>
											<span className="invites" style={{ display: 'inline-block', transform: 'rotateY(0) rotate(-35deg)' }}>🎟</span>
											<div className="ambassador-label"><Trans i18nKey={`ambassadorLevel.${level.label}`}/></div>
										</div>
									</div>
								)
							})}
						</div>
					</div>
				</div>

			</CardBody>
		</Card>
	)
}

export default CardAmbassador
