import React from 'react'
import PropTypes from 'prop-types'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

const Objective = props => {

	return (
		<React.Fragment>
			<div className={`objective ${props.active ? 'active' : ''} ${props.className}`}>
				<div className="objective-icon" style={{ position: 'relative' }}>
					{props.active && props.checkIcon && (
						<CheckRoundedIcon className="text-success" style={{ height: '1rem', width: '1rem', position: 'absolute', top: '-5px', right: '-5px' }}/>
					)}
					<div className="text-center">
						<p className={props.contentClassName}>{props.content}</p>
					</div>
					<div className="text-center">
					{props.icon}
					</div>
				</div>
			</div>
		</React.Fragment>
	)
}

Objective.propTypes = {
	icon: PropTypes.any,
	content: PropTypes.any,
	active: PropTypes.bool,
	checkIcon: PropTypes.bool,
}

export default Objective
