import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import { isMobile } from 'react-device-detect'

// Login Redux States
import { OPEN_SHARE, SHARE_CERTIFICATE } from './actionTypes'
import { shareLink, shareMessage } from '@Helpers/share'
import { toggleModal } from '@Store/template/actions'
import { MODAL_SHARE, MODAL_SHARE_CERTIFICATE } from '@Modals/ModalInfos/modals'
import _ from 'lodash'

const getStateUser = (state) => state.User.user

function * sharePrompt ({ payload }) {

	const stateUser = _.cloneDeep(yield select(getStateUser))

	let urlShare = null
	let message = null

	if (stateUser?.inviteCode?.code) urlShare = shareLink(stateUser.inviteCode.code)
	if (stateUser?.inviteCode?.code) message = shareMessage(stateUser.inviteCode.code, true)

	yield call(() => triggerShare(
		payload.customUrl || urlShare,
		payload.customMessage || message,
		MODAL_SHARE)
	)
}

function * toggleShareCertificate ({ payload }) {
	const { urlPng, urlPage, message } = payload
	yield call(() => triggerShare(urlPage, message, MODAL_SHARE_CERTIFICATE, { urlPng, urlPage, message }))
}

function * triggerShare (urlShare, message, modal, data = null) {
	try {
		navigator.clipboard.writeText(urlShare)
	} catch (e) {}
	/* If mobile, try to open share on mobile. On desktop, open modal */
	if (isMobile) {
		try {
			navigator.share({
				title: 'Wiraki',
				url: urlShare,
				text: message
			})
		} catch (err) {
			yield put(toggleModal(modal, { data }))
		}
	} else {
		yield put(toggleModal(modal, { data }))
	}
}

function * ShareSaga () {
	yield all([
		takeEvery(OPEN_SHARE, sharePrompt),
		takeEvery(SHARE_CERTIFICATE, toggleShareCertificate),
	])
}

export default ShareSaga
