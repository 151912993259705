import React from 'react'
import { Card, CardBody } from 'reactstrap'
import PropTypes from 'prop-types'
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded'

const CardJobTalent = props => {

	return (
		<Card
			className={`card-short w-100 ${props.className}`}
			onClick={props.onClick}
			style={props.style}
		>
			<CardBody className="p-3 w-100 text-left d-flex flex-row align-items-center justify-content-start">
				<img width={50} height={50} className="me-2" src={props.company.logo} style={{ borderRadius: '50%' }}/>
				<div className="d-flex flex-column align-items-start justify-content-center w-100">
					<h3 className="mb-2 text-gray-normal" style={{ fontSize: '16px' }}>{props.name}</h3>
					<h3 className="mb-2" style={{ fontSize: '14px' }}>{props.jobTitle}</h3>
					<div className="d-flex flex-row justify-content-between align-items-end w-100">
						<h3 className="d-flex align-items-center text-gray-normal w-100" style={{ fontSize: '12px' }}>
							<ApartmentRoundedIcon style={{ fontSize: '20px', color: '#333' }}/>&nbsp;{props.company.name}
						</h3>
					</div>
				</div>
			</CardBody>
		</Card>
	)
}

CardJobTalent.propTypes = {
	className: PropTypes.any,
	jobTitle: PropTypes.string.isRequired,
	company: PropTypes.string.isRequired,
	onClick: PropTypes.func,
}

export default CardJobTalent
