import React, { useEffect, useState } from 'react'
import { Card, CardBody } from 'reactstrap'
import { formatCurrency } from '@Helpers/number_helper'
import { useSelector } from 'react-redux'
import { Trans } from 'react-i18next'
import ButtonShare from '@Atoms/ButtonShare'

const CardSocietyImpact = props => {

	const userData = useSelector(state => state.User.user)
	const [user, setUser] = useState(userData)
	useEffect(() => setUser(userData), [userData])

	if (
		!user ||
		!user.impacts ||
		!user.impacts.supporter ||
		!user.stats
	) return null

	const hasStats = user.impacts.supporter !== 0

	return (
		<Card className="align-self-center">
			<CardBody className="d-flex flex-column">
				<div className="h7 mb-1"><Trans i18nKey="cardSocietyImpact.title"/></div>
				{hasStats ? (
					<Trans
						i18nKey="cardSocietyImpact.hasStats.text"
						components={{ p: <p/> }}
					/>
				) : (
					<Trans
						i18nKey="cardSocietyImpact.noStats.text"
						components={{ p: <p/> }}
					/>
				)}


				<div className="">
					{hasStats ? (
						<Trans
							i18nKey="cardSocietyImpact.hasStats.stats"
							components={{
								div: <div className="h8 my-2"/>,
								ul: <ul style={{ listStyle: 'none', paddingLeft: '0' }}/>,
								li: <li style={{marginBottom: '2px'}}/>
							}}
							values={{
								registered: user.impacts.supporter,
								voted: user.impacts.vote,
								dominoEffect: 1,
							}}
						/>
					) : (
						<Trans
							i18nKey="cardSocietyImpact.noStats.stats"
							components={{
								div: <div className="h8 my-2"/>,
								ul: <ul style={{ listStyle: 'none', paddingLeft: '0' }}/>,
								li: <li style={{marginBottom: '2px'}}/>
							}}
							values={{
								hirings: user.stats.numHirings,
								amount: formatCurrency(user.stats.amountMoneyDistributed, user.currency)
							}}
						/>
					)}
				</div>
				<div className="text-center mb-2" style={{ marginTop: '42px' }}>
					{hasStats ? (
						<ButtonShare className="w-75" color="primary" type="increaseSupport"/>
					) : (
						<ButtonShare className="w-75" color="primary" type="joinNow"/>
					)}
				</div>
			</CardBody>
		</Card>
	)
}

export default CardSocietyImpact
