import { all, call, put, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { getHeaders, handleError } from '@Store/helpers'
import _ from 'lodash'
import { formatCurrency } from '@Helpers/number_helper'
import { saveUserData } from '@Store/user/actions'
import { LOAD_BALANCE_DATA, REQUEST_WITHDRAW } from '@Store/balance/actionTypes'
import { requestWithdrawError, requestWithdrawSuccess } from '@Store/balance/actions'

function * getBalance () {
	try {
		const { data } = yield call(() => axios.get(`${process.env.REACT_APP_ENDPOINT_API_USER}/v1/me/balance`, getHeaders()))
		let shareRewards = 0
		let amountWithdrawn = 0
		data.operations.map((o) => {
			if (o.code === 'reward-referrer') shareRewards += o.amount
			if (_.includes(['withdraw-manual', 'withdraw-tremendous'], o.code)) amountWithdrawn += o.amount
		})
		const user = {
			currency: data.currency,
			balance: data.amount,
			balanceFormatted: formatCurrency(data.amount, data.currency, true),
			amountShareRewards: shareRewards,
			transactions: data.operations,
			amountWithdrawn: Math.abs(amountWithdrawn),
		}
		yield put(saveUserData(user))
	} catch (error) {handleError(error)}
}

function * callRequestWithdraw () {
	try {
		yield call(() => axios.post(`${process.env.REACT_APP_ENDPOINT_API_USER}/v1/withdraw`, {}, getHeaders()))
		yield all([
			put(requestWithdrawSuccess()),
			call(() => getBalance())
		])
	} catch (error) {
		if (error.response?.data?.errors[0]) {
			yield put(requestWithdrawError(error.response?.data?.errors[0]?.message))
		}
		handleError(error)
	}
}

function * BalanceSaga () {
	yield all([
		takeEvery(LOAD_BALANCE_DATA, getBalance),
		takeEvery(REQUEST_WITHDRAW, callRequestWithdraw),
	])
}

export default BalanceSaga
