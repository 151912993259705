import React, {useEffect, useState} from 'react'
import {Card, CardBody, CardHeader, Col, Row} from 'reactstrap'
import {Trans} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {MODAL_RANK, MODAL_RANK_CONFIDENCE} from '@Modals/ModalInfos/modals'
import {toggleModal} from '@Store/actions'
import Button from '@Atoms/Button'
import {MODAL_LEADERBOARD, MODAL_REWARDS} from '@Modals/ModalPages/modals'
import {Link, useHistory} from 'react-router-dom'
import routes from '@Routes/routeConfig'

const CardRankLeaderboard = props => {

    const dispatch = useDispatch()
    const history = useHistory()

    const userData = useSelector(state => state.User.user)
    const [user, setUser] = useState(userData)

    useEffect(() => setUser(userData), [userData])

    return (
        <Card className="card-rank-leaderboard">
            <CardHeader className="clickable" onClick={() => history.push(routes.RANK.location)}>
                <div className="position-relative">
                    <div className="h5 fw-semibold"><Trans i18nKey="cardRankLeaderboard.title"/></div>
                    <button
                        type="button"
                        className="top-right-icon"
                        data-dismiss="modal"
                        aria-label="Close"
                    />
                </div>
            </CardHeader>
            <CardBody className="d-flex flex-column">
                <Row>
                    <Col xs={6}>
                        <div className="box clickable" onClick={() => dispatch(toggleModal(MODAL_RANK))}>
                            <div className="h7">
                                {user.wRank.rank === 0 || user.wRank.rank === 100 ? (
                                    <Trans i18nKey="cardRank.lowerRank"/>
                                ) : (
                                    <Trans i18nKey="cardRank.topRank" values={{rank: user.wRank.rank}}/>
                                )}
                            </div>
                            <div className="small gray-4 text-uppercase mb-2">
                                <Trans i18nKey="cardRankLeaderboard.myRank"/>
                            </div>
                            <div className="text-secondary fw-bold">
                                <Trans i18nKey="cardRankLeaderboard.seeMore"/>
                            </div>
                        </div>
                    </Col>
                    <Col xs={6}>
                        <div className="box clickable" onClick={() => dispatch(toggleModal(MODAL_RANK_CONFIDENCE))}>
                            <div className="h7"><Trans i18nKey={`rankPrecision.${user.wRank.rankLabel}`}/></div>
                            <div className="small gray-4 text-uppercase mb-2">
                                <Trans i18nKey="cardRankLeaderboard.confidency"/>
                            </div>
                            <div className="text-secondary fw-bold">
                                <Trans i18nKey="cardRankLeaderboard.seeMore"/>
                            </div>
                        </div>
                    </Col>
                </Row>
                <div className="text-center" style={{marginTop: '42px'}}>
                    <div className="w-100 w-lg-75 mx-auto">
                        <Button className="w-75" color="primary"
                                onClick={() => dispatch(toggleModal(MODAL_LEADERBOARD))}>
                            <Trans i18nKey="cardRankLeaderboard.buttonLeaderboard"/>
                        </Button>
                    </div>
                </div>
            </CardBody>
        </Card>
    )
}

export default CardRankLeaderboard
