import React, { useEffect, useState } from 'react'
import { Col, Row } from 'reactstrap'
import { Trans } from 'react-i18next'
import Info from '@Icons/Info'
import { MODAL_RANK } from '@Modals/ModalInfos/modals'
import { useDispatch, useSelector } from 'react-redux'
import Cards from '@Cards'
import routes from '@Routes/routeConfig'
import { changePage, toggleModal } from '@Store/template/actions'

const Rank = props => {

	const dispatch = useDispatch()

	const userData = useSelector(state => state.User.user)
	const networkData = useSelector(state => state.Network.network)
	const pageData = useSelector(state => state.Template.page)

	const [user, setUser] = useState(userData)
	const [network, setNetwork] = useState(networkData)
	const [page, setPage] = useState(pageData)

	useEffect(() => setUser(userData), [userData])
	useEffect(() => setNetwork(networkData), [networkData])
	useEffect(() => setPage(pageData), [pageData])

	useEffect(() => {
		if (page?.route !== routes.RANK.route) dispatch(changePage(routes.RANK))
		window.dataLayer.push({
			event: 'pageview',
			page: { url: routes.RANK.route, title: 'Page Rank' }
		})
	}, [])

	if (
		!user ||
		!network ||
		!network.university
	) return null

	return (
		<Col xs={12}>
			<Cards.CardText
				className="bg-primary-yellow"
				centered
				captionClassName="text-gray-dark"
				title={(<>
					<Trans i18nKey="cardNoNeedCV.title"/>
					&nbsp;<Info style={{ width: '14px', height: '14px' }}/>
				</>)}
				text={(<Trans i18nKey="cardNoNeedCV.text"/>)}
				onClick={() => dispatch(toggleModal(MODAL_RANK))}
			/>
			{!user.verified && (
				<Cards.CardWarningShort
					content={<Trans i18nKey="cardWarningShort.verifyEmail" values={{ university: network.university.name }}/>}
				/>
			)}
			<Row>
				<Col xs={6}><Cards.CardRank onClick={() => dispatch(toggleModal(MODAL_RANK))}/></Col>
				<Col xs={6}><Cards.CardRankConfidence/></Col>
			</Row>
			<Cards.CardTopFeatures/>
			{user.receivedVotes ? (
				<React.Fragment>
					<Cards.CardRankGraph showAskNotification/>
				</React.Fragment>
			) : (
				<React.Fragment>
					<Cards.CardText
						title={(<Trans i18nKey="cardVoteIsWorth.title"/>)}
						text={(<Trans i18nKey="cardVoteIsWorth.text"/>)}
					/>
				</React.Fragment>
			)}
			{user.wRank.rank < 100 && user.wRank.rank > 0 ? (
				<Cards.CardShare type="YouAreTop"/>
			) : (
				<Cards.CardShare type="HowIsYourRank"/>
			)}
		</Col>
	)
}

export default Rank
