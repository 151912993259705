import React from 'react'
import Cards from '@Cards'

const MonitorInvites = props => {

	return (
		<>
			<Cards.CardInviteRewards />
			<Cards.CardAmbassador />
			<Cards.CardReferralGraph/>
			<Cards.CardSocietyImpact/>
		</>
	)
}

export default MonitorInvites
