import { all, call, put, takeEvery } from 'redux-saga/effects'
import { getHeaders, handleError } from '@Store/helpers'
import { LOAD_LIST_JOBS, LOAD_USER_JOB, SAVE_USER_JOB } from '@Store/jobs/actionTypes'
import axios from 'axios'
import { loadedUserJob } from '@Store/jobs/actions'

function * getListJobs () {
	try {
		const { data: jobs } = yield call(() => axios.get(`${process.env.REACT_APP_ENDPOINT_API_USER}/v1/jobs/?countyCode=it,uk`, getHeaders()))
		console.log(jobs)
	} catch (error) { handleError(error) }
}

function * getUserJob () {
	try {
		const { data } = yield call(() => axios.get(`${process.env.REACT_APP_ENDPOINT_API_USER}/v1/me/job`, getHeaders()))
		yield put(loadedUserJob(data))
	} catch (error) { handleError(error) }
}

function * setUserJob ({ payload: obj }) {
	try {
		yield call(() => axios.patch(`${process.env.REACT_APP_ENDPOINT_API_USER}/v1/me/job`, obj, getHeaders()))
	} catch (error) { handleError(error) }
}

function * JobsSaga () {
	yield all([
		takeEvery(LOAD_LIST_JOBS, getListJobs),
		takeEvery(LOAD_USER_JOB, getUserJob),
		takeEvery(SAVE_USER_JOB, setUserJob),
	])
}

export default JobsSaga
