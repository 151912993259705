import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

const WhoPaysForRewards = props => {

	const { i18n } = useTranslation()

	const [image, setImage] = useState()

	import(`@Assets/images/infographic/money_distribution_${i18n.languages[0]}.png`).then((img) => setImage(img.default))

	return {
		title: (
			<div className="d-flex flex-row align-items-center justify-content-center">
				<div className="text-center h5 mb-0 me-1" style={{ width: '24px' }}>💰</div>
				<Trans i18nKey="faq.whoPaysForRewards.title"/>
			</div>
		),
		content: (
			<div id="who-pays-for-rewards">
				<Trans
					i18nKey="faq.whoPaysForRewards.content"
					components={{
						image: <img src={image} alt=""/>
					}}
				/>
			</div>
		)
	}
}

export default WhoPaysForRewards
