import React from 'react'
import { Trans } from 'react-i18next'
import Vote from '@Molecules/Vote/Vote'
import Button from '@Atoms/Button'
import { useDispatch } from 'react-redux'
import { updateVoteRemoteData } from '@Store/remoteStorage/actions'
import { SECURED_NOT_HIRED } from '@Molecules/Vote/voteStatusses'

const VoteSecuredDoMore = props => {

	const { vote } = props

	const dispatch = useDispatch()

	return (
		<>
			<div className="b1 mb-4">
				<Trans
					i18nKey="FlowUnlockVote.VOTE_SECURED_DO_MORE.text"
					values={{ name: vote.vote }}
					components={{
						span: <span className="h7"/>
					}}
				/>
			</div>
			<div className="flow-title overline text-white">
				<Trans i18nKey="FlowUnlockVote.VOTE_SECURED_DO_MORE.title"/>
			</div>
			<div style={{marginBottom: '42px'}}>
				<Vote data={vote}/>
			</div>
			<div className="mb-5">
				<Trans
					i18nKey="FlowUnlockVote.VOTE_SECURED_DO_MORE.action"
					components={{
						span: <span className="h8"/>
					}}
				/>
			</div>
			<div className="text-center">
				<Button
					color="primary"
					className="w-50"
					onClick={() => dispatch(updateVoteRemoteData(vote.id, SECURED_NOT_HIRED, true))}
				>
					<Trans i18nKey="FlowUnlockVote.VOTE_SECURED_DO_MORE.cta"/>
				</Button>
			</div>
		</>
	)
}

export default VoteSecuredDoMore
