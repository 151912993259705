import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { Card, CardBody } from 'reactstrap'
import _ from 'lodash'
import { Trans } from 'react-i18next'

class CardActiveVotes extends React.Component {

	constructor (props) {
		super(props)
		this.state = {
			user: props.user,
			network: props.network,
			className: props.className,
		}
	}

	componentDidUpdate (prevProps, prevState, snapshot) {
		if (!_.isEqual(this.props.user, prevProps.user)) this.setState({ user: this.props.user })
		if (!_.isEqual(this.props.network, prevProps.network)) this.setState({ network: this.props.network })
	}

	render () {

		const { user, network } = this.state

		if (
			!user ||
			!user.reward ||
			!network ||
			!network.votes
		) return null

		const activeVotes = user.rewardVotes.filter(v => v.active).length

		return (
			<Card className={`d-flex flex-direction-row align-items-center card-short ${this.state.className}`}>
				<CardBody className="text-center">
					<span className="caption-small"><Trans i18nKey="cardActiveVotes.title" /></span>
					<h5 className="caption-big text-primary mb-0 font-weight-bold">
						<Trans
							i18nKey="cardActiveVotes.content"
							values={{
								activeVotes,
								totalVotes: user.rewardVotes.length
							}}
							components={{small: <small className="font-weight-bolder caption-small text-primary" />}}
						/>
					</h5>
				</CardBody>
			</Card>
		)
	}
}

const mapStateToProps = state => {
	return {
		user: state.User.user,
		network: state.Network.network,
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			// add other watcher sagas to this object to map them to props
		},
		dispatch
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CardActiveVotes))
