import {
	CHANGE_VOTE, CHANGE_VOTE_ERROR_ALREADY_VOTED,
	CHANGE_VOTE_ERROR_TOO_MANY_VOTES,
	CHANGE_VOTE_ERROR_VOTE_ALREADY_CLAIMED,
	CHANGE_VOTE_ERROR_VOTE_CHANGE_TOO_EARLY,
	CHANGE_VOTE_ERROR_VOTE_COUNT_NOT_FOUND,
	CHANGE_VOTE_SUCCESS,
	CLAIM_VOTE,
	CLAIM_VOTE_SUCCESS,
	GET_INVITE_TO_VOTE_LINK, SAVE_INVITE_TO_VOTE_LINK,
	SAVE_VOTES_DATA, SET_VOTE_FLOW_ACTIVE
} from '@Store/votes/actionTypes'

export const saveVotesData = data => ({
	type: SAVE_VOTES_DATA,
	payload: data
})

export const changeVote = vote => ({
	type: CHANGE_VOTE,
	payload: vote
})

export const changeVoteSuccess = () => ({
	type: CHANGE_VOTE_SUCCESS,
	payload: null
})

export const claimVote = vote => ({
	type: CLAIM_VOTE,
	payload: vote
})

export const claimVoteSuccess = vote => ({
	type: CLAIM_VOTE_SUCCESS,
	payload: vote
})

/* RESPONSE ERRORS to CHANGE_VOTE */
export const changeVoteErrorVoteAlreadyClaimed = () => ({
	type: CHANGE_VOTE_ERROR_VOTE_ALREADY_CLAIMED,
	payload: null
})
export const changeVoteErrorVoteCountNotFound = () => ({
	type: CHANGE_VOTE_ERROR_VOTE_COUNT_NOT_FOUND,
	payload: null
})
export const changeVoteErrorVoteChangeTooEarly = () => ({
	type: CHANGE_VOTE_ERROR_VOTE_CHANGE_TOO_EARLY,
	payload: null
})
export const changeVoteErrorTooManyVotes = () => ({
	type: CHANGE_VOTE_ERROR_TOO_MANY_VOTES,
	payload: null
})
export const changeVoteErrorAlreadyVoted = () => ({
	type: CHANGE_VOTE_ERROR_ALREADY_VOTED,
	payload: null
})

export const getInviteToVoteLink = () => ({
	type: GET_INVITE_TO_VOTE_LINK,
	payload: null
})
export const saveInviteToVoteLink = code => ({
	type: SAVE_INVITE_TO_VOTE_LINK,
	payload: code
})
export const setVoteFlowActive = (id = null) => ({
	type: SET_VOTE_FLOW_ACTIVE,
	payload: id
})
