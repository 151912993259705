import { PUSH_NOTIFICATIONS_GRANTED } from '@Store/pushNotifications/actionTypes'

const INIT_STATE = {
	notificationsEnabled: false
}

const PushNotifications = (state = INIT_STATE, action) => {
	switch (action.type) {
		case PUSH_NOTIFICATIONS_GRANTED:
			return {
				...state,
				notificationsEnabled: true
			}
		default:
			return state
	}
}

export default PushNotifications
