import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import { toggleModal } from '@Store/template/actions'
import { Card, CardBody } from 'reactstrap'
import Badge from '@Atoms/badge'
import { formatCurrency } from '@Helpers/number_helper'
import { MODAL_GOAL } from '@Modals/ModalInfos/modals'
import { Trans } from 'react-i18next'

class CardGoal extends React.Component {

	constructor (props) {
		super(props)
		this.state = {
			user: props.user,
			network: props.network,
		}
	}

	componentDidUpdate (prevProps, prevState, snapshot) {
		if (!_.isEqual(this.props.user, prevProps.user)) this.setState({ user: this.props.user })
		if (!_.isEqual(this.props.network, prevProps.network)) this.setState({ network: this.props.network })
	}

	render () {
		const { user, network } = this.state

		if (
			!user ||
			!network
		) return null

		let sumGoalsValue = 0
		network.groupGoals.map(g => g.achieved && g.to <= network.participants.length ? sumGoalsValue += g.rewardTotal : null)

		return (
			<React.Fragment>
				<Card className="align-self-center card-short card-referral clickable" onClick={() => this.props.toggleModal(MODAL_GOAL)}>
					<CardBody className="d-flex flex-row" style={{ paddingLeft: 0, paddingRight: 0 }}>
						<div className=" w-25 d-flex justify-content-center align-items-center caption-big text-success font-weight-bold">
							+{formatCurrency(sumGoalsValue, user.currency)}
						</div>
						<div
							className="d-flex flex-column flex-grow-1 text-center justify-content-center align-items-center"
							style={{
								borderLeft: '1px solid #dedede',
								borderRight: '1px solid #dedede',
							}}
						>
							<div className="caption-small"><Trans i18nKey="cardGoal.yourBadge"/></div>
							<div className="caption-big text-primary font-weight-bold">
								<Trans i18nKey={`goals.${user.goal.title}`}/>
							</div>
						</div>
						<div className=" w-25 d-flex justify-content-center align-items-center">
							<Badge color={user.goal.icon}/>
						</div>
					</CardBody>
				</Card>
			</React.Fragment>
		)
	}
}

const mapStateToProps = state => {
	return {
		user: state.User.user,
		network: state.Network.network,
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			toggleModal
			// add other watcher sagas to this object to map them to props
		},
		dispatch
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CardGoal))
