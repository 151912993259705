import React, { useEffect, useRef, useState } from 'react'
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Card, CardBody } from 'reactstrap'
import { Trans } from 'react-i18next'
import { formatCurrency } from '@Helpers/number_helper'
import { useSelector } from 'react-redux'
import ReactApexChart from 'react-apexcharts'
import ButtonShare from '@Atoms/ButtonShare'
import { calculateBonusByInvites } from '@Helpers/global'

const CardInviteRewards = props => {

	const acordionName = 'invite-rewards'

	const userData = useSelector(state => state.User.user)
	const [user, setUser] = useState(userData)
	useEffect(() => setUser(userData), [userData])

	const invites = [1, 2, 5, 10, 20, 40]
	const steps = []
	const xAxis = []
	invites.map(i => {
		const step = calculateBonusByInvites(i)

		xAxis.push(formatCurrency(Math.round(step.total / 50) * 50, user.currency))

		steps.push(step)
	})

	const [currentIndex, setCurrentIndex] = useState(3)
	const [isReady, setIsReady] = useState(false)
	const [stats, setStats] = useState({
		numVotes: steps[3].numVotes,
		amountVotes: steps[3].amountVotes,
		numInvites: steps[3].numInvites,
		amountInvites: steps[3].amountInvites,
		groupGoals: steps[3].groupGoals,
		ambassador: steps[3].ambassadorPercentage,
		total: steps[3].total,
	})

	useEffect(() => {
		if (currentIndex !== null) {
			setStats({
				numVotes: steps[currentIndex].numVotes,
				amountVotes: steps[currentIndex].amountVotes,
				numInvites: steps[currentIndex].numInvites,
				amountInvites: steps[currentIndex].amountInvites,
				groupGoals: steps[currentIndex].groupGoals,
				ambassador: steps[currentIndex].ambassadorPercentage,
				total: steps[currentIndex].total,
			})
		}
	}, [currentIndex])

	const [open, setOpen] = useState('')
	const toggle = (id) => {
		if (open === id) setOpen('')
		else setOpen(id)
	}

	const options = {
		chart: {
			id: '',
			animation: {
				enabled: false
			},
			toolbar: {
				show: false,
			},
			events: {
				dataPointSelection: function (event, context, options) {
					setOpen(acordionName)
					if (isReady && currentIndex !== options.dataPointIndex) {
						setIsReady(false)
						setCurrentIndex(options.dataPointIndex)
					}
				},
				updated: function (event, context, options) {
					if (!isReady) setIsReady(true)
				}
			},
			zoom: {
				enabled: false
			}
		},
		markers: {
			size: 5,
			colors: '#F67C09',
			strokeWidth: 30,
			strokeColors: 'transparent',
			hover: {
				size: 8,
			}
		},
		dataLabels: {
			enabled: false,
		},
		stroke: {
			curve: 'straight',
			width: 1,
		},
		colors: ['#3D3C3C'],
		xaxis: {
			type: 'category',
			categories: xAxis,
			tooltip: {
				enabled: false
			},
			labels: {
				rotate: -45,
				rotateAlways: true,
				maxHeight: 50,
				style: {
					colors: '#8C8989',
				}
			}
		},
		yaxis: {
			min: 1,
			max: 40,
			tickAmount: 4,
			forceNiceScale: true,
			labels: {
				style: {
					colors: '#8C8989',
				}
			}
		},
		grid: {
			show: true,
			borderColor: '#E6E5E5',
			strokeDashArray: 0,
			position: 'back',
			xaxis: {
				lines: {
					show: true
				}
			},
			yaxis: {
				lines: {
					show: true
				}
			},
		},
		tooltip: {
			shared: false,
			intersect: true,
			x: {
				show: false,
			},
			y: {
				show: true,
				title: {
					formatter: function (seriesName) {
						return ''
					}
				},
				formatter: function (value, obj) {
					return `<span style="font-weight: 400">${value}<span style="display: inline-block; transform: rotateY(0) rotate(-35deg);">&nbsp;🎟</span>&nbsp;&nbsp;≈&nbsp;&nbsp;${obj.w.globals.categoryLabels[obj.dataPointIndex]}</span>`
				}
			},
			marker: {
				show: false
			}
		},
		selection: {
			enabled: false
		}
	}

	return (
		<Card className="align-self-center card-ambassador">
			<CardBody className="d-flex flex-column">
				<div className="h7 mb-1"><Trans i18nKey="cardInviteRewards.title"/></div>
				<div className="mb-4"><Trans i18nKey="cardInviteRewards.subtitle"/></div>

				<div className="font-size-12 gray-4" style={{ marginBottom: '-20px' }}>
					<Trans i18nKey="cardInviteRewards.forecast" components={{ span: <span style={{ display: 'inline-block', transform: 'rotateY(0) rotate(-35deg)' }}/> }}/>
				</div>
				<div className="">
					<ReactApexChart
						options={options}
						series={[{ name: 'Reward', data: invites }]}
						height="220"
						className="apex-charts"
					/>
				</div>

				<div id="reward-details">
					<Accordion open={open} toggle={toggle}>
						<AccordionItem>
							<AccordionHeader targetId={acordionName}>
								<div className="fw-bold font-size-14">
									<Trans i18nKey="cardInviteRewards.details"/>
								</div>
							</AccordionHeader>
							<AccordionBody accordionId={acordionName}>
								<div className="mb-1">
									<Trans i18nKey="cardInviteRewards.table.votes"/> <strong className="text-primary">x{stats.numVotes}</strong>
									<span className="float-end font-weight-medium">{formatCurrency(stats.amountVotes, user.currency)}</span>
								</div>
								<div className="mb-1">
									<Trans i18nKey="cardInviteRewards.table.bonus"/> <strong className="text-primary">x{stats.numInvites}</strong>
									<span className="float-end font-weight-medium">{formatCurrency(stats.amountInvites, user.currency)}</span>
								</div>
								<div className="mb-1">
									<Trans i18nKey="cardInviteRewards.table.groupGoal"/>
									<span className="float-end font-weight-medium">{formatCurrency(stats.groupGoals, user.currency)}</span>
								</div>
								<div className="mb-1">
									<Trans i18nKey="cardInviteRewards.table.ambassadorLevel"/>
									<span className="float-end font-weight-medium">+&nbsp;{stats.ambassador}%</span>
								</div>
								<hr/>
								<div>
									<Trans i18nKey="cardInviteRewards.table.total"/>
									<span className="float-end font-weight-medium">{formatCurrency(stats.total, user.currency)}</span>
								</div>
							</AccordionBody>
						</AccordionItem>
					</Accordion>
				</div>
				<div className="text-center mb-2" style={{ marginTop: '42px' }}>
					<ButtonShare className="w-75" color="primary" type="inviteStudents"/>
				</div>
			</CardBody>
		</Card>
	)

}

export default CardInviteRewards

/*
TODO: the following code needs to be tested with react-d3 to plot a custom graph

import React, { useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ReferenceLine, Circle } from 'react-d3-components';

const data = [
  {
    name: 'Series 1',
    values: [
      { x: 200, y: 1 },
      { x: 650, y: 2 },
      { x: 1300, y: 5 },
      { x: 1600, y: 10 },
      { x: 2000, y: 20 },
      { x: 3250, y: 40 }
    ],
  },
];

const ExponentialCurve = () => {
  const [selectedPoint, setSelectedPoint] = useState(null);

  const handlePointClick = (point) => {
    setSelectedPoint(point);
  };

  const handlePointMouseEnter = (point) => {
    // You can add code here to increase the size of the point on hover
  };

  const handlePointMouseLeave = (point) => {
    // You can add code here to restore the size of the point when the mouse leaves
  };

  return (
    <LineChart
      data={data}
      width={800}
      height={400}
      margin={{ top: 10, bottom: 50, left: 50, right: 10 }}
    >
      <Line
        stroke="#ff7300"
        strokeWidth={2}
      >
        {data[0].values.map((point, i) => (
          <Circle
            key={i}
            cx={point.x}
            cy={point.y}
            r={selectedPoint === point ? 10 : 5} // Change the size of the point based on the selected prop
            fill="#ff7300"
            onClick={() => handlePointClick(point)}
            onMouseEnter={() => handlePointMouseEnter(point)}
            onMouseLeave={() => handlePointMouseLeave(point)}
          />
        ))}
      </Line>
      <XAxis
        label="X"
        tickFormat={(d) => `€${d}`}
      />
      <YAxis
        label="Y"
      />
      <CartesianGrid
        stroke="#ccc"
      />
      <Tooltip
        label="X"
        value="Y"
      />
      <Legend />
      <ReferenceLine
        stroke="#000"
        strokeDasharray="5 5"
      />
    </LineChart>
  );
};

export default ExponentialCurve;
 */
