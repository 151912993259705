import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined'

export const CARD_TOP_FEATURES = {
	ACHIEVEMENTS: [
		{ rating: 100, box: 'Supporter', text: 'cardTopFeatures.Achievement1' },
		{ rating: 75, box: 'Top 75%', text: 'cardTopFeatures.Achievement2' },
		{ rating: 50, box: 'Top 50%', text: 'cardTopFeatures.Achievement3' },
		{ rating: 30, box: 'Top 30%', text: 'cardTopFeatures.Achievement4' },
		{ rating: 15, box: 'Top 15%', text: 'cardTopFeatures.Achievement5' },
		{ rating: 5, box: 'Top 5%', text: 'cardTopFeatures.Achievement6' },
	]
}

export const MAX_VOTED_PER_VOTE = 3

export const NUM_PARTICIPANTS_TO_SHOW = 5

export const CARD_LEADERBOARD = {
	NUM_PARTICIPANTS_TO_UNLOCK: 0,
}

export const CARD_SHARE = {
	NUM_PARTICIPANTS_EXTERNAL_SHARE: 20,
	AMOUNT_EXTERNAL_SHARE: 15
}

export const CARD_COLLECTIVE_GOALS = {
	GOALS: [
		{ value: 5, title: 'Innovator', icon: 'diamond', goalLabel: '5', goalIcon: <PersonOutlineOutlinedIcon/>, text: 'cardCollectiveReward.step1' },
		{ value: 12, title: 'Early adopter', icon: 'gold', goalLabel: '12', goalIcon: <PeopleAltOutlinedIcon/>, text: 'cardCollectiveReward.step2' },
		{ value: 25, title: 'Contributor', icon: 'silver', goalLabel: '25', goalIcon: <PeopleAltOutlinedIcon/>, text: 'cardCollectiveReward.step3' },
		{ value: 50, title: 'Supporter', icon: 'bronze', goalLabel: '50', goalIcon: <PeopleAltOutlinedIcon/>, text: 'cardCollectiveReward.step4' },
	]
}

export const AMBASSADOR_LEVELS = [
	{ label: 'Follower', numInvites: 0, sharePercentage: 0 },
	{ label: 'Advocate', numInvites: 2, sharePercentage: 0.5 },
	{ label: 'Charismatic', numInvites: 5, sharePercentage: 0.8 },
	{ label: 'Promoter', numInvites: 10, sharePercentage: 1 },
	{ label: 'Spreader', numInvites: 40, sharePercentage: 1.5 },
	{ label: 'Ambassador', numInvites: 200, sharePercentage: 2 },
	{ label: 'Crusader', numInvites: 1000, sharePercentage: 2.5 },
	{ label: 'Influencer', numInvites: 5000, sharePercentage: 3 },
]

export const RANK_PRECISION = [
	{ from: 0, to: 0.3, label: 'Very Low' },
	{ from: 0.3, to: 0.5, label: 'Low' },
	{ from: 0.5, to: 0.7, label: 'Medium' },
	{ from: 0.7, to: 0.9, label: 'High' },
	{ from: 0.9, to: 1.1, label: 'Very High' },
]

export const CONTEST = {
	numParticipantForContest: 10
}

export const FEEDS = {

	/* Global feeds */
	NEW_UNIVERSITY: { name: 'NEW_UNIVERSITY', importance: 60 },
	NEW_FACULTY: { name: 'NEW_FACULTY', importance: 60 },
	WEEKLY_REWARD_EARNED: { name: 'WEEKLY_REWARD_EARNED', importance: 100 },
	MONTHLY_REWARD_EARNED: { name: 'MONTHLY_REWARD_EARNED', importance: 100 },
	NEW_COMPANY: { name: 'NEW_COMPANY', importance: 60 },

	/* Network specific */
	GROUP_GOAL_ACHIEVED: { name: 'GROUP_GOAL_ACHIEVED', importance: 80 },
	COLLEAGUE_HIRED: { name: 'COLLEAGUE_HIRED', importance: 100 },
	COLLEAGUE_EARNED_REWARD: { name: 'COLLEAGUE_EARNED_REWARD', importance: 100 },

	/* User specific */
	VOTE_UNLOCKED: { name: 'VOTE_UNLOCKED', importance: 20 },
	USER_INVITED_VERIFIED: { name: 'USER_INVITED_VERIFIED', importance: 20 },
	WRANK_CHANGED: { name: 'WRANK_CHANGED', importance: 40 },
	AMBASSADOR_LEVEL_CHANGED: { name: 'AMBASSADOR_LEVEL_CHANGED', importance: 20 },

}

// Highest: WELCOME_BONUS
// High: COLLEAGUE_HIRED, COLLEAGUE_EARNED_REWARD
// Medium: NEW_UNIVERSITY, NEW_FACULTY,
// Low: WRANK_CHANGED
// Very Low: VOTE_UNLOCKED, USER_INVITED_VERIFIED, AMBASSADOR_LEVEL_CHANGED
