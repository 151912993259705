import React from 'react'
import PropTypes from 'prop-types'
import {
	LOCKED_HIRED_LOST,
	LOCKED_NOT_REGISTERED,
	LOCKED_REGISTERED,
	SECURED_HIRED,
	SECURED_HIRED_REDEEMED,
	SECURED_NOT_HIRED, SECURED_NOT_REGISTERED,
	UNLOCKED_HIRED_LOST,
	UNLOCKED_NOT_REGISTERED,
	UNLOCKED_REGISTERED,
	VOTE_STATUSSES
} from '@Molecules/Vote/voteStatusses'
import User from '@Icons/User'

const VoteUserIcon = props => {

	const { voteStatus, unlockFirstVote } = props

	if(unlockFirstVote) return (
		<div className="position-relative">
			<User userColor="black"/>
		</div>
	)

	switch (voteStatus) {
		/* LOCKED */
		case LOCKED_NOT_REGISTERED:
		case LOCKED_REGISTERED:
			return (
				<div className="position-relative">
					<User icon="lock" iconColor="black" userColor="gray"/>
				</div>
			)
		/* LOST */
		case LOCKED_HIRED_LOST:
			return (
				<div className="position-relative">
					<User icon="lock" iconColor="black" userColor="gray"/>
				</div>
			)
		case UNLOCKED_HIRED_LOST:
			return (
				<div className="position-relative">
					<User icon="check" iconColor="success" userColor="black"/>
				</div>
			)
		/* UNLOCKED */
		case UNLOCKED_NOT_REGISTERED:
		case SECURED_NOT_REGISTERED:
			return (
				<div className="position-relative">
					<User icon="plus" iconColor="secondary" userColor="gray"/>
				</div>
			)
		case UNLOCKED_REGISTERED:
			return (
				<div className="position-relative">
					<User icon="check" iconColor="primary" userColor="black"/>
				</div>
			)
		/* SECURED */
		case SECURED_NOT_HIRED:
		case SECURED_HIRED:
		case SECURED_HIRED_REDEEMED:
			return (
				<div className="position-relative">
					<User icon="check" iconColor="success" userColor="black"/>
				</div>
			)
	}
	return (
		<div className="position-relative">
			<User userColor="black"/>
		</div>
	)
}

VoteUserIcon.defaultProps = {
	unlockFirstVote: false,
}

VoteUserIcon.propTypes = {
	voteStatus: PropTypes.oneOf(VOTE_STATUSSES),
	unlockFirstVote: PropTypes.bool,
}

export default VoteUserIcon
