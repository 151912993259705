import React, { useEffect, useState } from 'react'
import BackdropContent from '@Atoms/BackdropContent'
import { useDispatch, useSelector } from 'react-redux'
import { FIRST_TO_BE_UNLOCKED, SECURED_NOT_HIRED, UNLOCKED_NOT_REGISTERED, UNLOCKED_REGISTERED, UNLOCKED_VOTE_AGAIN } from '@Molecules/Vote/voteStatusses'
import { INVITE_TO_SECURE_THE_VOTE, SECURE_THE_VOTE_INVITED, SECURE_THE_VOTE_NOT_INVITED, UNLOCK_FIRST_VOTE, VOTE_AGAIN, VOTE_SECURED_DO_MORE } from '@Molecules/FlowUnlockVote/flowActionStatus'
import { setVoteFlowActive } from '@Store/votes/actions'
import SecureTheVoteInvited from '@Molecules/FlowUnlockVote/SecureTheVoteInvited'
import SecureTheVoteNotInvited from '@Molecules/FlowUnlockVote/SecureTheVoteNotInvited'
import VoteSecuredDoMore from '@Molecules/FlowUnlockVote/VoteSecuredDoMore'
import InviteToSecureTheVote from '@Molecules/FlowUnlockVote/InviteToSecureTheVote'
import VoteAgain from '@Molecules/FlowUnlockVote/VoteAgain'
import UnlockFirstVote from '@Molecules/FlowUnlockVote/UnlockFirstVote'

const FlowUnlockVote = () => {

	const dispatch = useDispatch()

	const remoteStorageData = useSelector(state => state.RemoteStorage.remoteStorage)
	const [remoteStorage, setRemoteStorage] = useState(remoteStorageData)
	useEffect(() => setRemoteStorage(remoteStorageData), [remoteStorageData])

	const userData = useSelector(state => state.User.user)
	const [user, setUser] = useState(userData)
	useEffect(() => setUser(userData), [userData])

	const networkData = useSelector(state => state.Network.network)
	const [network, setNetwork] = useState(networkData)
	useEffect(() => setNetwork(networkData), [networkData])

	const voteFlowData = useSelector(state => state.Votes.voteFlowActive)
	const [voteFlow, setVoteFlow] = useState(voteFlowData)
	useEffect(() => setVoteFlow(voteFlowData), [voteFlowData])

	// List of vote's state that user can interact with
	const emptyAction = { vote: null, action: null }
	const [nextAction, setNextAction] = useState(emptyAction)

	const [isOpen, setIsOpen] = React.useState(false)
	const toggle = () => setIsOpen(!isOpen)

	const setNewAction = newAction => {
		if (newAction.vote !== nextAction.vote) setNextAction(emptyAction)
		if (newAction.action !== nextAction.action) setNextAction(newAction)
	}

	const updateNextAction = () => {
		// If votes are not yet being updated, skip
		if (!network?.votes[0]?.status) return null

		let activeVote = null
		network.votes.map((vote, k) => {
			if (remoteStorage.votesData.hasOwnProperty(vote.id)) {
				if (activeVote === null) {

					if (
						vote.numberVote === 1 &&
						remoteStorage.votesData[vote.id].hasOwnProperty(FIRST_TO_BE_UNLOCKED) &&
						!remoteStorage.votesData[vote.id][FIRST_TO_BE_UNLOCKED] === true
					) {
						// The first vote has a special case
						// If it is not already manually unlocked, it needs to be unlocked
						activeVote = vote.id
						setNewAction({ vote: vote.id, action: UNLOCK_FIRST_VOTE })
					} else if (vote.status === UNLOCKED_REGISTERED) {
						// The user needs to secure the vote
						if (
							remoteStorage.votesData[vote.id].hasOwnProperty(UNLOCKED_VOTE_AGAIN) &&
							remoteStorage.votesData[vote.id][UNLOCKED_VOTE_AGAIN] === true
						) {
							activeVote = vote.id
							setNewAction({ vote: vote.id, action: VOTE_AGAIN })
						} else if (user.referrals.items.some(item => item.fullName === vote.vote)) {
							activeVote = vote.id
							setNewAction({ vote: vote.id, action: SECURE_THE_VOTE_INVITED })
						} else {
							activeVote = vote.id
							setNewAction({ vote: vote.id, action: SECURE_THE_VOTE_NOT_INVITED })
						}
					} else if (
						vote.status === UNLOCKED_NOT_REGISTERED &&
						remoteStorage.votesData[vote.id].hasOwnProperty(UNLOCKED_NOT_REGISTERED) &&
						!remoteStorage.votesData[vote.id][UNLOCKED_NOT_REGISTERED] === true
					) {
						activeVote = vote.id
						// The user needs to invite someone
						setNewAction({ vote: vote.id, action: INVITE_TO_SECURE_THE_VOTE })
					} else if (
						vote.status === SECURED_NOT_HIRED &&
						remoteStorage.votesData[vote.id].hasOwnProperty(SECURED_NOT_HIRED) &&
						!remoteStorage.votesData[vote.id][SECURED_NOT_HIRED] === true
					) {
						activeVote = vote.id
						setNewAction({ vote: vote.id, action: VOTE_SECURED_DO_MORE })
					} else if (voteFlow !== null && voteFlow === vote.id) {
						// If the flow for the current vote is over, we close the flow
						setNewAction(emptyAction)
					}
				}
			}
		})
	}

	useEffect(() => {
		if (
			remoteStorage?.votesData &&
			network?.votes
		) updateNextAction()
	}, [remoteStorage, network, voteFlow])

	if (
		!network?.votes ||
		!user?.referrals ||
		!remoteStorage ||
		!remoteStorage.completedWelcome ||
		!remoteStorage.votesData
	) return null

	// If there is an action to do for a vote, and that action is not already active, activate the flow
	if (voteFlow === null && nextAction.vote !== null) {
		// There is an action to do, activate the flow
		dispatch(setVoteFlowActive(nextAction.vote))
	} else if (voteFlow !== null && nextAction.vote === null) {
		// There is no more action to do, close the flow
		dispatch(setVoteFlowActive(null))
		setNewAction(emptyAction)
	} else if (voteFlow !== nextAction.vote) {
		// There is an action to do on a different vote, close the flow
		dispatch(setVoteFlowActive(null))
		setNewAction(emptyAction)
	}

	if (voteFlow !== null && !isOpen) toggle()
	else if (voteFlow === null && isOpen) toggle()

	// Check if there are something to do for each vote
	if (voteFlow === null || nextAction.action === null) return null

	// votes data
	const vote = network.votes.find(vote => vote.id === voteFlow)

	return (
		<BackdropContent isOpen={isOpen} toggle={toggle}>
			<div className="w-100" id="flow-unlock-vote">
				{nextAction.action === UNLOCK_FIRST_VOTE && (
					<UnlockFirstVote vote={vote} user={user} unlockFirstVote/>
				)}
				{nextAction.action === VOTE_AGAIN && (
					<VoteAgain vote={vote} user={user} votes={network.votes}/>
				)}
				{nextAction.action === INVITE_TO_SECURE_THE_VOTE && (
					<InviteToSecureTheVote vote={vote} user={user}/>
				)}
				{nextAction.action === SECURE_THE_VOTE_INVITED && (
					<SecureTheVoteInvited vote={vote}/>
				)}
				{nextAction.action === SECURE_THE_VOTE_NOT_INVITED && (
					<SecureTheVoteNotInvited vote={vote}/>
				)}
				{nextAction.action === VOTE_SECURED_DO_MORE && (
					<VoteSecuredDoMore vote={vote}/>
				)}
			</div>
		</BackdropContent>
	)
}

export default FlowUnlockVote
