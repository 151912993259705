import ModalAmbassador from '@Modals/ModalInfos/ModalAmbassador'
import ModalGoal from '@Modals/ModalInfos/ModalGoal'
import ModalMeritocracyExplain from '@Modals/ModalInfos/ModalMeritocracyExplain'
import ModalRank from '@Modals/ModalInfos/ModalRank'
import ModalRankConfidence from '@Modals/ModalInfos/ModalRankConfidence'
import ModalShare from '@Modals/ModalInfos/ModalShare'
import ModalVerifyEmailSent from '@Modals/ModalInfos/ModalVerifyEmailSent'
import ModalCheckEmail from '@Modals/ModalInfos/ModalCheckEmail'
import ModalWithdraw from '@Modals/ModalInfos/ModalWithdraw'
import ModalWelcomeVerifyEmail from '@Modals/ModalInfos/ModalWelcomeVerifyEmail'
import ModalLinkInviteToVote from '@Modals/ModalInfos/ModalLinkInviteToVote'
import ModalAdditionalVoteLocked from '@Modals/ModalInfos/ModalAdditionalVoteLocked'
import ModalContest from '@Modals/ModalInfos/ModalContest'
import ModalShareCertificate from '@Modals/ModalInfos/ModalShareCertificate'

// Modal Forgot Password
import ModalForgotPassword from '@Modals/ModalForgotPassword/ModalForgotPassword'
import ModalForgotPasswordConfirm from '@Modals/ModalForgotPassword/ModalForgotPasswordConfirm'

export default {
	ModalAmbassador,
	ModalGoal,
	ModalMeritocracyExplain,
	ModalRank,
	ModalRankConfidence,
	ModalShare,
	ModalVerifyEmailSent,
	ModalCheckEmail,
	ModalWithdraw,
	ModalWelcomeVerifyEmail,
	ModalLinkInviteToVote,
	ModalAdditionalVoteLocked,
	ModalContest,
	ModalShareCertificate,

	ModalForgotPassword,
	ModalForgotPasswordConfirm,
}
