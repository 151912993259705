import React, { useState } from 'react'
import { Trans } from 'react-i18next'
import { Input, InputGroup, Popover, PopoverBody, Button } from 'reactstrap'
import PropTypes from 'prop-types'
import _ from 'lodash'

const popoverId = _.uniqueId('popover-')

const InputShare = props => {

	const [popover, setPopover] = useState(false)

	return (
		<React.Fragment>
			<InputGroup size="sm" className={props.className} style={{ maxWidth: '280px' }}>
				<Input
					id={popoverId}
					value={props.url}
					disabled
				/>
				<Button
					onClick={() => {
						setPopover(!popover)
						navigator.clipboard.writeText(props.url)
					}}
					color="primary"
					outline
				><Trans i18nKey="cardShare.copy"/></Button>
			</InputGroup>
			<Popover
				onClick={() => setPopover(!popover)}
				placement="top"
				isOpen={popover}
				target={popoverId}
				trigger="legacy"
				toggle={() => setPopover(!popover)}
				popperClassName="popover-share"
			>
				<PopoverBody>
					<strong><Trans i18nKey="cardShare.copied"/></strong>
				</PopoverBody>
			</Popover>
		</React.Fragment>
	)
}

InputShare.propTypes = {
	url: PropTypes.string.isRequired,
}

export default InputShare
