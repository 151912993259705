import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Modal from '@Modals/Modal'
import _ from 'lodash'
import { openShare, requestWithdraw, toggleModal } from '@Store/actions'
import { MODAL_WITHDRAW } from '@Modals/ModalInfos/modals'
import { Col, Row } from 'reactstrap'
import Cards from '@Cards'
import Button from '@Atoms/Button'
import AskNotification from '@Atoms/askNotification'
import { MODAL_REFERRAL } from '@Modals/ModalPages/modals'
import { Trans } from 'react-i18next'

class ModalWithdraw extends React.Component {

	constructor (props) {
		super(props)
		this.state = {
			user: props.user,
			network: props.network,
			balance: props.balance,
		}
	}

	componentDidUpdate (prevProps, prevState, snapshot) {
		if (!_.isEqual(this.props.user, prevProps.user)) this.setState({ user: this.props.user })
		if (!_.isEqual(this.props.network, prevProps.network)) this.setState({ network: this.props.network })
		if (!_.isEqual(this.props.balance, prevProps.balance)) this.setState({ balance: this.props.balance })
	}

	render () {

		const { user, network, balance } = this.state

		if (
			!user ||
			!user.reward ||
			!network ||
			!network.groupGoals
		) return null

		return (
			<Modal type="modal-mobile-center" name={MODAL_WITHDRAW} centered>
				<h3 className="title-big"><Trans i18nKey="modalWithdraw.title"/></h3>
				<div className="caption">
					<Trans i18nKey="modalWithdraw.content"/>
				</div>
				<h3 className="title my-3"><Trans i18nKey="modalWithdraw.realizedReward"/></h3>
				<Row>
					<Col xs={6}>
						<Cards.CardRewardCurrent/>
					</Col>
					<Col xs={6}>
						<Cards.CardRewardWithdrawn/>
					</Col>
				</Row>
				<Button
					className="w-75"
					color="primary"
					disabled={user.balance === 0 || balance.withdrawRequested}
					onClick={() => this.props.requestWithdraw()}
				>
					<Trans i18nKey="modalWithdraw.getAmazonGiftCard"/>
				</Button>
				{balance.withdrawStatus === true && (
					<div className="mt-2">
						<i className="fa fa-check text-success" />&nbsp;
						We sent you an email at:<br/>
						{user.email}
					</div>
				)}
				{balance.withdrawStatus === false && (
					<div className="mt-2 text-danger">
						<i className="fa fa-exclamation-triangle text-danger" />&nbsp;
						Error: {balance.withdrawErrorMessage}
					</div>
				)}
				<AskNotification>
					<div className="link my-3"><Trans i18nKey="modalWithdraw.notify"/></div>
				</AskNotification>
				<h3 className="title my-3"><Trans i18nKey="modalWithdraw.expectedReward"/></h3>
				<Row>
					<Col xs={6}>
						<Cards.CardRewardUnlocked/>
					</Col>
					<Col xs={6}>
						<Cards.CardRewardLocked/>
					</Col>
				</Row>
				<Button
					className="w-75"
					color="primary"
					onClick={() => this.props.toggleModal(MODAL_REFERRAL)}
				><Trans i18nKey="modalWithdraw.increaseReward"/></Button>
			</Modal>
		)
	}
}

const mapStateToProps = state => {
	return {
		user: state.User.user,
		network: state.Network.network,
		balance: state.Balance,
	}
}
const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			openShare,
			toggleModal,
			requestWithdraw,
			// add other watcher sagas to this object to map them to props
		},
		dispatch
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ModalWithdraw))
