import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { Card, CardBody } from 'reactstrap'
import _ from 'lodash'
import ChartistGraph from 'react-chartist'
import 'chartist/dist/scss/chartist.scss'
import '@Assets/scss/chartist.scss'
import Cards from '@Cards'
import AskNotification from '@Atoms/askNotification'
import { Trans } from 'react-i18next'
import { CartesianGrid, Dot, Line, LineChart, ResponsiveContainer, XAxis, YAxis } from 'recharts'

const chartDataDefault = {
	labels: [],
	series: [
		[40, 30, 10, 20],
	]
}
const lineChartOptions = {
	fullWidth: true,
	onlyInteger: true,
	chartPadding: {
		right: 10
	},
	axisY: {
		offset: 60,
		onlyInteger: true,
		labelInterpolationFnc: (value) => (`Top ${value === 0 ? 1 : -value}%`),
	}
}

class CardRankGraph extends React.Component {

	constructor (props) {
		super(props)
		this.state = {
			user: props.user,
			network: props.network,
		}
	}

	componentDidUpdate (prevProps, prevState, snapshot) {
		if (!_.isEqual(this.props.user, prevProps.user)) this.setState({ user: this.props.user })
		if (!_.isEqual(this.props.network, prevProps.network)) this.setState({ network: this.props.network })
	}

	render () {

		const { user, network } = this.state

		if (
			!user ||
			!user.rankHistory ||
			!network ||
			!network.university
		) return null

		if (!user.receivedVotes || user.wRank.rank === 100) return null

		let showMessageRankDown = false
		/* If user ever lost 2 position sequently, show message */
		const data = _.cloneDeep(user.rankHistory)

		data.map((i, k) => {
			data[k].position = i.rank * 100
			data[k].show = false

			if (
				data[k - 1] &&
				data[k - 2] &&
				data[k - 2].position < data[k - 1].position &&
				data[k - 1].position < data[k].position
			) {
				showMessageRankDown = true
				data[k].show = true
			}
		})

		return (
			<Card className={`d-flex flex-direction-row align-items-center `}>
				<CardBody className="text-center w-100">
					<h3 className="title mb-2"><Trans i18nKey="cardRankGraph.title"/></h3>
					{showMessageRankDown && (
						<div className="caption-small text-start mb-2">
							<i className="fas fa-dot-circle me-1" style={{ color: '#DC3545' }}/>
							<Trans i18nKey="cardRankGraph.rankDown"/>
						</div>
					)}
					{user.verified ? (
						<React.Fragment>
							<ResponsiveContainer
								width="100%"
								height={200}
							>
								<LineChart
									data={data}
								>
									<CartesianGrid strokeDasharray="2 4"/>
									<XAxis
										hide
										domain={['auto', 'auto']}
									/>
									<YAxis
										type="number"
										scale="linear"
										domain={['dataMin', 'dataMax']}
										tickFormatter={v => (`Top ${v}%`)}
										interval="preserveStartEnd"
										reversed
										stroke="#adb5bd"
										axisLine={false}
										style={{ fontSize: '10px' }}
									/>
									<Line
										type="monotone"
										dataKey="position"
										stroke="#4CAF50"
										strokeWidth={2}
										dot={(v) => v.payload.show ? <Dot {...v} style={{ stroke: '#DC3545' }}/> : <Dot {...v} />}
									/>
								</LineChart>
							</ResponsiveContainer>
							{this.props.showAskNotification && (
								<AskNotification>
									<hr/>
									<div className="link">
										<Trans i18nKey="cardRankGraph.notify"/>
									</div>
								</AskNotification>
							)}
						</React.Fragment>
					) : (
						<div className="position-relative">
							<ChartistGraph
								data={{
									...chartDataDefault,
									series: [chartDataDefault.series[0].map((v) => -v)]
								}}
								options={lineChartOptions}
								type={'Line'}
								style={{ height: '200px', padding: 0, filter: 'blur(3px)' }}
							/>
							<div className="d-flex align-items-center justify-content-center" style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
								<Cards.CardWarningShort content={<Trans i18nKey="cardRankGraph.verifyEmail" values={{ university: network.university.name }}/>}/>
							</div>
						</div>
					)}

				</CardBody>
			</Card>
		)
	}
}

const mapStateToProps = state => {
	return {
		user: state.User.user,
		network: state.Network.network,
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			// add other watcher sagas to this object to map them to props
		},
		dispatch
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CardRankGraph))
