import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { changePage, loadCertificate, toggleModal } from '@Store/actions'
import { Trans } from 'react-i18next'
import _ from 'lodash'
import CertificateFound from '@Pages/Certificate/CertificateFound'
import { Card, CardBody, Col, Row } from 'reactstrap'
import routes from '@Routes/routeConfig'
import { Helmet } from 'react-helmet'

class Certificate extends React.Component {

	constructor (props) {
		super(props)
		this.state = {
			user: props.user,
			hashDoc: null,
			network: props.network,
			page: props.page,
		}
	}

	componentDidMount () {
		/* Keep this condition as we experienced sometimes a multiple call to changePage function */
		if (this.state.page.route !== routes.CERTIFICATE_OPEN.route) this.props.changePage(routes.CERTIFICATE_OPEN)
		const { user, hashDoc } = this.props.match.params
		this.props.loadCertificate(user, hashDoc)
		this.setState({ hashDoc })
	}

	componentDidUpdate (prevProps, prevState, snapshot) {
		if (!_.isEqual(this.props.user, prevProps.user)) this.setState({ user: this.props.user })
		if (!_.isEqual(this.props.page, prevProps.page)) this.setState({ page: this.props.page })
		if (!_.isEqual(this.props.network, prevProps.network)) this.setState({ network: this.props.network })
	}

	render () {
		let { isAuthorized: isLogged } = this.props
		const { user, hashDoc, network } = this.state
		const { user: hashUser } = this.props.match.params

		if (
			!user ||
			!user.certificates ||
			_.isUndefined(user.certificates[hashDoc]) ||
			(isLogged && !network?.certificate)
		) return null

		const isAuthorized = isLogged && network?.certificate?.code === hashDoc

		const userData = user.certificates[hashDoc]

		const title = `${userData?.fullName}`
		const description = ``

		return (
			<React.Fragment>
				<Helmet>
					<title>{title}</title>
					<meta name="description" content={description}/>
					<meta property="og:title" content={title}/>
					<meta property="og:image" content={userData?.diplomatic?.pngSm || ''}/>
				</Helmet>
				<h1 className="page-title text-gray-dark">
					{isAuthorized ? (
						<Trans i18nKey="pageCertificate.title"/>
					) : (
						<React.Fragment>
							{!isAuthorized && userData?.fullName ? (
								<React.Fragment>{userData?.fullName}</React.Fragment>
							) : (
								<Trans i18nKey="pageCertificate.certificateNotFound"/>
							)}
						</React.Fragment>
					)}
				</h1>
				{!_.isNull(userData) && (
					<CertificateFound
						pageUrl={`${process.env.REACT_APP_WIRAKI_WEBSITE}/c/${hashDoc}/${hashUser}`}
						university={userData.university}
						faculty={userData.faculty}
						isAuthorized={isAuthorized}
						certificates={userData.diplomatic}
					/>
				)}
				{!isAuthorized && (
					<Card>
						<CardBody>
							<Row>
								<Col xs={6}
									 className="align-items-center d-flex flex-column justify-content-between caption-big text-gray-dark text-center" style={{ borderRight: '1px solid #dedede' }}>
									<div>
										<Trans
											i18nKey="pageCertificate.areYouStudent"
											components={{
												icon: <i className="fa fa-graduation-cap"/>,
												small: <div className="mt-1 caption"/>
											}}
										/>
									</div>
									<a
										href={`${process.env.REACT_APP_VOTE_URL}`}
										className={`btn btn-sm mt-3 btn-primary text-white caption clickable w-75`}
									>
										<Trans i18nKey="pageCertificate.areYouStudentButton" />
									</a>
								</Col>
								<Col xs={6}
									 className="align-items-center d-flex flex-column justify-content-between caption-big text-gray-dark text-center">
									<div>
										<Trans
											i18nKey="pageCertificate.areYouRecruiter"
											components={{
												icon: <i className="fa fa-suitcase"/>,
												small: <div className="mt-1 caption"/>
											}}
										/>
									</div>
									<a
										href={`mailto:info@wiraki.com`}
										className={`btn btn-sm mt-3 btn-primary text-white caption clickable w-75`}
									>
										<Trans i18nKey="pageCertificate.areYouRecruiterButton" />
									</a>
								</Col>
							</Row>
						</CardBody>
					</Card>
				)}
			</React.Fragment>
		)
	}
}

const mapStateToProps = state => {
	return {
		user: state.User.user,
		network: state.Network.network,
		page: state.Template.page,
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			changePage,
			toggleModal,
			loadCertificate,
		},
		dispatch
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Certificate))
