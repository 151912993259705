import React from 'react'
import Modal from '@Modals/Modal'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { MODAL_FORGOT_PASSWORD_CONFIRM } from '@Modals/ModalInfos/modals'
import eyes from '@Assets/images/eyes.svg'

const ModalForgotPasswordConfirm = props => {

	const modal = useSelector(state => state.Template.modal[MODAL_FORGOT_PASSWORD_CONFIRM])

	const { t } = useTranslation()

	if (!modal) return null

	return (
		<Modal type="modal-mobile-center" className="w-85 mx-auto" name={MODAL_FORGOT_PASSWORD_CONFIRM} centered>
			<img className="my-3" src={eyes} alt="" />
			<div className="h5 mb-1"><Trans i18nKey="pageLogin.modalForgotPasswordConfirm.title"/></div>
			<div className="mb-3 px-3">
				<Trans i18nKey="pageLogin.modalForgotPasswordConfirm.text"/>
			</div>
		</Modal>
	)
}

export default ModalForgotPasswordConfirm

