import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { Button, Card, CardBody, Input, InputGroup, Popover, PopoverBody } from 'reactstrap'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { formatCurrency } from '@Helpers/number_helper'
import { Trans, withTranslation } from 'react-i18next'
import { shareLink, shareMessage } from '@Helpers/share'
import { openShare } from '@Store/share/actions'
import { CARD_SHARE } from '@Constants/constants'
import TitleBolts from '@Atoms/titleBolts'
import Share from '@Atoms/share/Share'
import ShareTelegram from '@Atoms/share/ShareTelegram'
import ShareWhatsapp from '@Atoms/share/ShareWhatsapp'

class CardShare extends React.Component {

	constructor (props) {
		super(props)
		this.state = {
			user: props.user,
			name: props.name,
			icon: props.icon,
			popoverId: _.uniqueId('popover-')
		}
	}

	componentDidUpdate (prevProps, prevState, snapshot) {
		if (!_.isEqual(this.props.user, prevProps.user)) this.setState({ user: this.props.user })
		if (!_.isEqual(this.props.name, prevProps.name)) this.setState({ name: this.props.name })
		if (!_.isEqual(this.props.icon, prevProps.icon)) this.setState({ icon: this.props.icon })
	}

	render () {
		const { user } = this.state

		if (
			!user ||
			!user.inviteCode
		) return null

		const urlShare = shareLink(user.inviteCode.code)
		const messageShare = shareMessage(user.inviteCode.code)

		return (
			<React.Fragment>
				<Card className={`align-self-center card-share ${this.props.className}`}>
					<CardBody className="d-flex flex-column">
						{this.props.icon && (
							<div className="text-center">
								{this.props.icon}
							</div>
						)}
						<div className="h6 mb-3 text-start">
							<Trans
								i18nKey={`cardShare.${this.props.type}.title`}
								values={{
									amountExternalShare: formatCurrency(CARD_SHARE.AMOUNT_EXTERNAL_SHARE, user.currency),
									name: this.props.name,
									top: user.wRank.rank,
								}}
								components={{ TitleBolt: <TitleBolts/> }}
							/>
						</div>
						<div className="mb-3 text-start">
							<Trans
								i18nKey={`cardShare.${this.props.type}.content`}
								values={{
									amountExternalShare: formatCurrency(CARD_SHARE.AMOUNT_EXTERNAL_SHARE, user.currency),
									name: this.props.name,
								}}
								components={{
									br: <br/>,
									bold: <span className="font-weight-bolder" />
								}}/>
						</div>
						<div className="container-button-share mb-3">
							<Share />
							<ShareTelegram message={messageShare}/>
							<ShareWhatsapp message={messageShare}/>
						</div>
						<div className="d-flex link-share justify-content-center">
							<InputGroup size="sm" style={{maxWidth: '280px'}}>
								<Input
									id={this.state.popoverId}
									value={urlShare}
									disabled
								/>
								<Button
									onClick={() => this.setState({ popoverOpen: !this.state.popoverOpen })}
									color="primary"
									outline
								><Trans i18nKey="cardShare.copy" /></Button>
							</InputGroup>
						</div>
					</CardBody>
				</Card>
				<Popover
					onClick={() => this.setState({ popoverOpen: !this.state.popoverOpen })}
					placement="top"
					isOpen={this.state.popoverOpen}
					target={this.state.popoverId}
					trigger="legacy"
					toggle={() => this.setState({ popoverOpen: !this.state.popoverOpen })}
					popperClassName="popover-share"
				>
					<PopoverBody>
						<strong><Trans i18nKey="cardShare.copied" /></strong>
					</PopoverBody>
				</Popover>
			</React.Fragment>
		)
	}
}

const mapStateToProps = state => {
	return {
		user: state.User.user,
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			openShare
			// add other watcher sagas to this object to map them to props
		},
		dispatch
	)
}

CardShare.propTypes = {
	type: PropTypes.oneOf(['HelpSpreadMeritocracy', 'YouAreTop', 'ReachTheGoal', 'ImproveRank', 'GettingVoted', 'ShareToGainMore', 'HowIsYourRank']).isRequired,
	name: PropTypes.string,
	icon: PropTypes.object,
	className: PropTypes.string,
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(CardShare)))
