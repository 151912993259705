import React, { useEffect, useState } from 'react'
import Cards from '@Cards'
import Modal from '@Modals/Modal'
import { MODAL_PARTICIPANTS } from '@Modals/ModalPages/modals'
import { useSelector } from 'react-redux'

const ModalParticipants = props => {

	const userData = useSelector(state => state.User.user)
	const networkData = useSelector(state => state.Network.network)

	const [user, setUser] = useState(userData)
	const [network, setNetwork] = useState(networkData)

	useEffect(() => setUser(userData), [userData])
	useEffect(() => setNetwork(networkData), [networkData])

	if (
		!user ||
		!network ||
		!network.university ||
		!network.faculty
	) return null

	return (
		<Modal type="modal-mobile-full" name={MODAL_PARTICIPANTS}>
			<img alt="" width={40} src={network.university.logo} style={{ borderRadius: '10px' }}/>
			<div className="title">
				{network.faculty.name}
			</div>
			<Cards.CardVerifyEmailShort/>
			<Cards.CardGoal/>
			<Cards.CardParticipantsList/>
			<Cards.CardCollectiveGoals/>
			<Cards.CardShare type="ReachTheGoal"/>
		</Modal>
	)
}

export default ModalParticipants
