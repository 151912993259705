import { API_ERROR, LOGIN_SUCCESS, LOGIN_USER, LOGIN_WITH_TOKEN, LOGOUT_USER, LOGOUT_USER_SUCCESS, } from './actionTypes'
import routes from '@Routes/routeConfig'

const initialState = {
	error: '',
	loading: false,
}

const login = (state = initialState, action) => {
	switch (action.type) {
		case LOGIN_USER:
			state = {
				...state,
				loading: true,
			}
			break
		case LOGIN_SUCCESS:
			localStorage.setItem('authUser', `Bearer ${action.payload}`)
			window.location.href = routes.HOME.location
			break
		case LOGOUT_USER:
			state = { ...state }
			break
		case LOGOUT_USER_SUCCESS:
			state = { ...state }
			break
		case API_ERROR:
			state = { ...state, error: action.payload, loading: false }
			break
		case LOGIN_WITH_TOKEN:
			localStorage.setItem('authUser', `Bearer ${action.payload}`)
			window.location.href = routes.HOME.location
			break
		default:
			state = { ...state }
			break
	}
	return state
}

export default login
