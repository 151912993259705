import React, { useEffect, useState } from 'react'
import { Col, Container } from 'reactstrap'
import Header from '@Components/Layout/Elements/Header'
import { MODAL_YOU_CAN_HELP } from '@Modals/ModalPages/modals'
import { useDispatch, useSelector } from 'react-redux'
import Modals from '@Modals'
import { toggleModal } from '@Store/actions'
import Welcome from '@Components/Layout/Elements/Welcome'
import FlowUnlockVote from '@Molecules/FlowUnlockVote'

const AuthDefault = props => {

	const dispatch = useDispatch()

	const pageData = useSelector(state => state.Template.page)
	const remoteStorageData = useSelector(state => state.RemoteStorage.remoteStorage)

	const [page, setPage] = useState(pageData)
	const [remoteStorage, setRemoteStorage] = useState(remoteStorageData)

	useEffect(() => setPage(pageData), [pageData])
	useEffect(() => setRemoteStorage(remoteStorageData), [remoteStorageData])

	if (!page) return null

	return (
		<React.Fragment>
			{/* First page of welcome for those who come here for the first time */}
			{remoteStorage && !remoteStorage.completedWelcome && (<Welcome/>)}
			{/* --- */}
			<div className="main-content">
				<Header cardHeader={<></>} clickLogo={() => dispatch(toggleModal(MODAL_YOU_CAN_HELP))}/>

				<div className="page-content" style={{ overflowY: 'auto' }} id="page-scroll">
					<Container fluid style={{ height: '100%' }} id={`page-${page.name}`}>
						<Col
							lg={{ size: 6, offset: 3 }}
							md={{ size: 8, offset: 2 }}
							sm={{ size: 10, offset: 1 }}
							xs={12}
							className="d-flex flex-column"
						>
							{props.children}
						</Col>
					</Container>
				</div>
			</div>

			<FlowUnlockVote />

			{/* LOAD MODALS */}
			<Modals.ModalRewards showShare/>
			<Modals.ModalParticipants/>
			<Modals.ModalReferral/>
			<Modals.ModalLeaderboard/>
			<Modals.ModalShare/>
			<Modals.ModalCollectiveGoal/>
			<Modals.ModalYouCanHelp/>
			<Modals.ModalMeritocracyExplain/>
			<Modals.ModalAmbassador/>
			<Modals.ModalVerifyEmailSent/>
			<Modals.ModalGoal/>
			<Modals.ModalRank/>
			<Modals.ModalRankConfidence/>
			<Modals.ModalCheckEmail/>
			<Modals.ModalWithdraw/>
			<Modals.ModalWelcomeVerifyEmail/>
			<Modals.ModalLinkInviteToVote/>
			<Modals.ModalAdditionalVoteLocked/>
			<Modals.ModalContest/>
			{/* /Load modals */}
		</React.Fragment>)
}

export default AuthDefault
