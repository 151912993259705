import React from 'react'
import Cards from '@Components/Cards'

const ListFeeds = props => {

	return (
		<>
			<Cards.CardListFeeds />
		</>
	)
}

export default ListFeeds
