import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { Card, CardBody } from 'reactstrap'
import _ from 'lodash'
import { toggleModal } from '@Store/template/actions'
import { Trans, withTranslation } from 'react-i18next'
import { CARD_COLLECTIVE_GOALS, NUM_PARTICIPANTS_TO_SHOW } from '@Constants/constants'
import Objective from '@Atoms/objective'
import { formatCurrency } from '@Helpers/number_helper'
import LockIcon from '@mui/icons-material/Lock'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'
import AskNotification from '@Atoms/askNotification'
import Badge from '@Atoms/badge'
import HighlightedItem from '@Atoms/HighlighedItem'
import { MODAL_GOAL } from '@Modals/ModalInfos/modals'
import Info from '@Icons/Info'

class CardCollectiveGoals extends React.Component {

	constructor (props) {
		super(props)
		this.state = {
			user: props.user,
			network: props.network,
		}
	}

	componentDidUpdate (prevProps, prevState, snapshot) {
		if (!_.isEqual(this.props.user, prevProps.user)) this.setState({ user: this.props.user })
		if (!_.isEqual(this.props.network, prevProps.network)) this.setState({ network: this.props.network })
	}

	getSentenceGoal (isNextGoal, isActive, achieved) {
		if (isNextGoal && achieved) return <Trans i18nKey="cardCollectiveReward.continue"/>
		if (achieved) return <Trans i18nKey="cardCollectiveReward.success"/>
		if (isNextGoal) return <Trans i18nKey="cardCollectiveReward.info"/>
		if (!isActive) return null
		return <Trans i18nKey="cardCollectiveReward.fail"/>
	}

	render () {
		const { user, network } = this.state
		if (
			!user &&
			!network &&
			!network.groupGoals &&
			!network.participants
		) return null

		const partecipantsVerified = network.participants.filter(p => p.verified).length

		return (
			<React.Fragment>
				<Card className="align-self-center w-100">
					<CardBody className="d-flex flex-column">
						<h3 className="title mb-2">
							{/* React i18n cannot escape "<" character, so don't change those lines */}
							{this.props.t('cardCollectiveGoals.title', {
								participants: partecipantsVerified >= NUM_PARTICIPANTS_TO_SHOW ? partecipantsVerified : `< ${NUM_PARTICIPANTS_TO_SHOW}`
							})}
						</h3>
						<div className="caption mb-2">
							<Trans i18nKey="cardCollectiveGoals.subtitle"/>
						</div>
						<div className="objectives-box">
							{CARD_COLLECTIVE_GOALS.GOALS.map((goal, k) => {
								const isActive = goal.value <= partecipantsVerified
								let isNextGoal = false
								if (
									(k === 0 && goal.value > partecipantsVerified)
									||
									(k !== 0 && goal.value > partecipantsVerified && CARD_COLLECTIVE_GOALS.GOALS[k - 1].value <= partecipantsVerified)
								) {
									isNextGoal = true
								}
								return (
									<HighlightedItem
										key={k}
										bordered={isNextGoal}
										caption={isNextGoal ? <><Trans i18nKey="cardCollectiveGoals.nextGoal"/>&nbsp;<Info/></> : ''}
									>
										<div
											className={`d-flex align-items-center ${isNextGoal ? 'clickable' : ''}`}
											onClick={() => isNextGoal ? this.props.toggleModal(MODAL_GOAL) : null}
										>
											<Objective
												icon={goal.goalIcon}
												content={goal.goalLabel}
												active={isActive}
											/>
											<div className="flex-grow-1 mx-2">
												<div className={`caption text-start ${isActive || isNextGoal ? 'text-gray-dark' : 'text-gray-light'}`}>
													{isActive && (
														<React.Fragment>
															{network.groupGoals[k].achieved ? (
																<CheckRoundedIcon className="text-success font-size-16"/>
															) : (
																<ClearRoundedIcon className="text-danger font-size-16"/>
															)}
														</React.Fragment>
													)}
													<Trans
														i18nKey={goal.text}
														values={{
															amount60: formatCurrency(60, user.currency),
															amount30: formatCurrency(30, user.currency),
															amount15: formatCurrency(15, user.currency),
															amount9: formatCurrency(9, user.currency),
														}}
													/>
												</div>
												<div className={`caption-small text-start text-gray-light`}>
													{this.getSentenceGoal(isNextGoal, isActive, network.groupGoals[k].achieved)}
												</div>
											</div>
											<div className="position-relative">
												<Badge color={goal.icon}/>
												{!network.groupGoals[k].achieved && (
													<div className="text-gray-dark d-flex justify-content-center objective-lock">
														<LockIcon style={{ width: '14px' }}/>
													</div>
												)}
											</div>
										</div>
									</HighlightedItem>
								)
							})}
						</div>
						<AskNotification>
							<hr/>
							<div className="link">
								<Trans i18nKey="cardCollectiveReward.notify"/>
							</div>
						</AskNotification>
					</CardBody>
				</Card>
			</React.Fragment>
		)
	}
}

const mapStateToProps = state => {
	return {
		user: state.User.user,
		network: state.Network.network,
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			toggleModal
			// add other watcher sagas to this object to map them to props
		},
		dispatch
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(CardCollectiveGoals)))
