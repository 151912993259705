import React from 'react'
import { Trans } from 'react-i18next'
import Vote from '@Molecules/Vote/Vote'
import PropTypes from 'prop-types'

const UnlockFirstVote = props => {

	const { vote, user, unlockFirstVote } = props

	return (
		<>
			<div className="b1 mb-4">
				<Trans
					i18nKey="FlowUnlockVote.UNLOCK_FIRST_VOTE.text"
					values={{ name: user.fullName }}
					components={{
						span: <span className="h7"/>
					}}
				/>
			</div>
			<div className="flow-title overline text-white">
				<Trans i18nKey="FlowUnlockVote.UNLOCK_FIRST_VOTE.title" />
			</div>
			<Vote data={vote} unlockFirstVote={unlockFirstVote}/>
		</>
	)
}

UnlockFirstVote.defaultProps = {
	unlockFirstVote: false,
}

UnlockFirstVote.propTypes = {
	unlockFirstVote: PropTypes.bool,
}

export default UnlockFirstVote
