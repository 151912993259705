import React from 'react'
import { Card, CardBody } from 'reactstrap'
import { useSelector } from 'react-redux'
import { Trans } from 'react-i18next'

const CardNotifyHiring = props => {

	const network = useSelector(state => state.Network.network)
	const user = useSelector(state => state.User.user)

	if (
		!network.listHirings
		||
		network.listHirings.length === 0
		||
		// The user is the students that has been hired
		network.listHirings[0].id === user.id
	) return null

	const lastHiring = network.listHirings[0]

	return (
		<Card className="card-balance bordered-secondary bordered-success border-thick mx-3">
			<CardBody className="d-flex flex-column">
				<div className="text-center fs-4 fw-bold px-3" style={{ lineHeight: '120%' }}>
					<Trans
						i18nKey="cardNotifyHiring.title"
						values={{ name: lastHiring.name }}
						components={{
							green: <strong className="text-success"/>,
						}}
					/>
				</div>
				<div className="caption-big pt-3">

					{/* Not voted by me */}
					{!lastHiring.votedByMe && (
						<div className="mb-2">
							<Trans
								i18nKey="cardNotifyHiring.notVoted"
								components={{
									p: <p className="mb-1"/>,
									p1: <p className="mb-4"/>,
									ol: <ol className="mt-1 mb-0"/>,
									li: <li/>
								}}
							/>
						</div>
					)}

					{/* Voted by me but not claimed */}
					{lastHiring.votedByMe && !lastHiring.votedByMeClaimed && (
						<div className="mb-2">
							<Trans
								i18nKey="cardNotifyHiring.votedNotClaimed"
								values={{ name: lastHiring.name }}
								components={{ ol: <ol/>, li: <li/> }}
							/>
						</div>
					)}
					{/* Voted by me and claimed */}
					{lastHiring.votedByMe && lastHiring.votedByMeClaimed && (
						<Trans i18nKey="cardNotifyHiring.votedClaimed" values={{ name: lastHiring.name }}/>
					)}
				</div>
			</CardBody>
		</Card>
	)
}

export default CardNotifyHiring
