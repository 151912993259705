import { call, put, takeEvery } from 'redux-saga/effects'
import routes from '@Routes/routeConfig'

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from './actionTypes'
import { apiError, loginSuccess } from './actions'

//Include Both Helper File with needed methods
import axios from 'axios'

function * loginUser ({ payload: { user, history } }) {
	try {
		const body = {
			email: user.email,
			password: user.password
		}

		const response = yield call(() => axios.post(`${process.env.REACT_APP_ENDPOINT_API_USER}/v1/authentication/login`, body))

		yield put(loginSuccess(response.data.access_token))
	} catch (error) {
		yield put(apiError('Invalid credentials'))
	}
}

function * logoutUser ({ payload: { history } }) {
	try {
		localStorage.removeItem('authUser')
		history.push(routes.LOGIN)
	} catch (error) {
		yield put(apiError(error))
	}
}

function * authSaga () {
	yield takeEvery(LOGIN_USER, loginUser)
	yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
