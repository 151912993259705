import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Cards from '@Cards'
import Modal from '@Modals/Modal'
import ButtonShare from '@Atoms/ButtonShare'
import { MODAL_REFERRAL } from '@Modals/ModalPages/modals'
import { Trans } from 'react-i18next'

const ModalReferral = props => {

	const userData = useSelector(state => state.User.user)

	const [user, setUser] = useState(userData)

	useEffect(() => setUser(userData), [userData])

	if (
		!user ||
		!user.rewardAmbassador ||
		!user.referrals
	) return null

	return (
		<Modal
			type="modal-mobile-full"
			name={MODAL_REFERRAL}
			stickyAction={(
				<ButtonShare
					color="primary"
					className="w-75"
					type="inviteShort"
				/>
			)}
		>
			<h3 className="title-big">
				<Trans i18nKey="modalReferral.title"/>
			</h3>
			<div className="caption mb-2 text-white">
				<Trans i18nKey="modalReferral.subtitle"/>
			</div>
			<ButtonShare className="link text-secondary mb-2" type="joinColleague"/>
			<Cards.CardNextInvite/>
			<Cards.CardAmbassadorRank/>
			{(user.referrals.events.length === 0 && user.referrals.items.length === 0) ? (
				<React.Fragment>
					<Cards.CardSocietyImpact/>
					<Cards.CardReferralGraph/>
				</React.Fragment>
			) : (
				<React.Fragment>
					<Cards.CardReferralGraph/>
					<Cards.CardReferralList/>
					<Cards.CardSocietyImpact/>
				</React.Fragment>
			)}
		</Modal>
	)
}

export default ModalReferral
