import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { Card, CardBody } from 'reactstrap'
import _ from 'lodash'
import WarningRoundedIcon from '@mui/icons-material/WarningRounded'
import { Trans } from 'react-i18next'

class CardVerifyEmailShort extends React.Component {

	constructor (props) {
		super(props)
		this.state = {
			network: props.network,
			user: props.user,
		}
	}

	componentDidUpdate (prevProps, prevState, snapshot) {
		if (!_.isEqual(this.props.network, prevProps.network)) this.setState({ network: this.props.network })
		if (!_.isEqual(this.props.user, prevProps.user)) this.setState({ user: this.props.user })
	}

	render () {

		const { network, user } = this.state

		if (
			!user ||
			!network ||
			!network.university
		) return null

		if (user.verified) return null

		return (
			<Card className="bg-primary-red">
				<CardBody className="d-flex flex-direction-row align-items-center">
					<WarningRoundedIcon className="me-2 text-primary"/>
					<div className="caption-small text-start">
						<Trans i18nKey="cardVerifyEmailShort.content" values={{university: network.university.name}}/>
					</div>
				</CardBody>
			</Card>
		)
	}
}

const mapStateToProps = state => {
	return {
		network: state.Network.network,
		user: state.User.user,
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			// add other watcher sagas to this object to map them to props
		},
		dispatch
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CardVerifyEmailShort))
