import explorer from '@Assets/images/explorer.svg'
import PropTypes from 'prop-types'

const Explorer = props => {

	return (
		<img src={explorer} className={props.className} style={props.style} alt="" />
	)
}

Explorer.propTypes = {
	className: PropTypes.string,
	style: PropTypes.object,
}

export default Explorer
