import { Trans } from 'react-i18next'
import React, { useEffect } from 'react'
import { getArrowDelta } from '@Helpers/global'
import { useDispatch } from 'react-redux'
import { openShare } from '@Store/actions'

const LeaderboardRank = props => {

	const { network, user } = props

	const checkIfVoted = (fullName) => {
		let voted = null

		network.votes.map((vote) => {
			if (fullName === vote.name) voted = vote
		})
		return voted
	}

	const dispatch = useDispatch()
	const triggerOpenShare = () => dispatch(openShare())

	useEffect(() => {}, [network, user])


	return (
		<React.Fragment>
			<h3 className="caption-small mb-3">
				{network.countDistinctPeople >= 12 ? (
					<Trans i18nKey="cardLeaderboard.subtitleWithVoters" values={{ people: network.countDistinctPeople }}/>
				) : (
					<Trans i18nKey="cardLeaderboard.subtitle"/>
				)}
			</h3>
			{/* Print list of top 30% most voted */}
			{network.leaderboardRank.map((u, k) => {
				const userVoted = checkIfVoted(u.name)
				const isCurrent = u.name === user.fullName
				return (
					<div key={k} className="d-flex flex-row align-items-baseline mb-1">
						<div className="mx-1 caption">{u.position}.</div>
						<div className="mx-1 caption">{getArrowDelta(u.delta)}</div>
						<div className="mx-1 caption text-start flex-grow-1">
							{k === 0 && <>🥇&nbsp;</>}
							{k === 1 && <>🥈&nbsp;</>}
							{k === 2 && <>🥉&nbsp;</>}
							{/* Print student's name */}
							{u.hired && (<span className="font-weight-bold text-success">{u.name}&nbsp;🎉</span>)}
							{!u.hired && isCurrent && (<span className="font-weight-bold">{u.name}</span>)}
							{!u.hired && !isCurrent && (u.name)}
						</div>
						{!u.hired && userVoted && userVoted.active && userVoted.verified && (
							<div className="mx-1 caption-small text-decoration-underline text-success">
								<Trans i18nKey="cardLeaderboard.voteUnlocked"/>
							</div>
						)}
						{!u.hired && userVoted && !userVoted.active && (
							<div
								className="mx-1 caption-small text-decoration-underline text-secondary clickable"
								onClick={() => triggerOpenShare()}
							>
								<Trans i18nKey="cardLeaderboard.unlockVote"/>
							</div>
						)}
						{!u.hired && userVoted && userVoted.active && !userVoted.verified && (
							<div
								className="mx-1 caption-small text-decoration-underline clickable text-secondary"
								onClick={() => triggerOpenShare()}
							>
								<Trans i18nKey="cardLeaderboard.invite"/>
							</div>
						)}
					</div>
				)
			})}
			{/* Print "...and X more" if user is in top rank */}
			{user.wRank.rankRaw <= 30 && (
				<Trans
					i18nKey="cardLeaderboard.otherPeople"
					count={network.countDistinctPeople - network.leaderboardRank.length}
					values={{colleagues: network.countDistinctPeople - network.leaderboardRank.length}}
				/>
			)}
			{user.wRank.rankRaw > 30 && user.wRank.rankRaw !== 100 && (
				<React.Fragment>
					<div className="d-flex flex-row align-items-center justify-content-center mt-2">
						<div className="caption-small mx-2"><Trans i18nKey="cardLeaderboard.only20shown"/></div>
					</div>
					<div className="text-start">
						<span>...</span>
						{user.wRank.rankPrevMe && user.wRank.rankPrevMe.name !== network.leaderboardRank[network.leaderboardRank.length - 1].name && (
							<div className="d-flex flex-row align-items-baseline mb-1">
								<div className="mx-1 caption">{user.wRank.rankPrevMe.position}.</div>
								<div className="mx-1 caption">{getArrowDelta(user.wRank.rankPrevMe.delta)}</div>
								<div className="mx-1 caption text-start flex-grow-1">{user.wRank.rankPrevMe.name}</div>
							</div>
						)}
						<div className="d-flex flex-row align-items-baseline mb-1">
							<div className="mx-1 caption font-weight-bold">{user.wRank.rankMe.position}.</div>
							<div className="mx-1 caption">{getArrowDelta(user.wRank.rankMe.delta)}</div>
							<div className="mx-1 caption text-start flex-grow-1 font-weight-bold">{user.fullName}</div>
						</div>
						{user.wRank.rankNextMe && (
							<div className="d-flex flex-row align-items-baseline mb-1">
								<div className="mx-1 caption">{user.wRank.rankNextMe.position}.</div>
								<div className="mx-1 caption">{getArrowDelta(user.wRank.rankNextMe.delta)}</div>
								<div className="mx-1 caption text-start flex-grow-1">{user.wRank.rankNextMe.name}</div>
							</div>
						)}
						<span>...</span>
					</div>
				</React.Fragment>
			)}
		</React.Fragment>
	)
}

export default LeaderboardRank
