import React from 'react'
import PropTypes from 'prop-types'
import BoltIcon from '@mui/icons-material/Bolt';

const TitleBolts = props => {

	return (
		<React.Fragment>
			<BoltIcon className="text-warning" />
			{`${props.content}`}
			<BoltIcon className="text-warning" />
		</React.Fragment>
	)
}

TitleBolts.propTypes = {
	content: PropTypes.string
}


export default TitleBolts
