import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'reactstrap'
import Logo from '@Icons/Logo'
import Navigation from '@Components/Layout/Elements/Navigation'
import { useDispatch, useSelector } from 'react-redux'
import Countdown from '@Atoms/countdown'
import { toggleBannerEntryBonus } from '@Store/template/actions'

const Header = props => {

	const dispatch = useDispatch()
	const userData = useSelector(state => state.User.user)
	const [isAuthorized, setIsAuthorized] = useState(!!userData && !!userData.fullName)
	useEffect(() => setIsAuthorized(!!userData && !!userData.fullName), [userData])

	const entryBonusCountdownData = useSelector(state => state.User.entryBonusCountdown)
	const [timeLeft, setTimeLeft] = useState(entryBonusCountdownData)
	useEffect(() => setTimeLeft(entryBonusCountdownData), [entryBonusCountdownData])


	return (
		<div id="topbar" className="container-fluid">
			<Row className="h-100">
				<Col
					lg={{ size: 6, offset: 3 }}
					md={{ size: 8, offset: 2 }}
					sm={{ size: 10, offset: 1 }}
					xs={12}
				>
					<div className={`container-topbar ${!!timeLeft ? 'justify-content-between' : 'justify-content-center'}`}>
						<div className="d-flex flex-row align-items-center justify-content-center clickable" onClick={props.clickLogo}>
							<Logo className="logo"/>
							<span className="logo-text">Wiraki</span>
						</div>
						<Countdown
							className="topbar-countdown"
							compact
							icon={false}
							date={userData.bonusVerifyBefore}
						/>
					</div>
				</Col>
			</Row>
			{isAuthorized && (
				<div className="container-topbar-menu">
					<Row className="h-100">
						<Col
							lg={{ size: 6, offset: 3 }}
							md={{ size: 8, offset: 2 }}
							sm={{ size: 10, offset: 1 }}
							xs={12}
							className="d-flex flex-column px-0 px-sm-2 px-md-0"
						>
							<Navigation/>
						</Col>
					</Row>
				</div>
			)}
		</div>
	)
}

Header.propTypes = {
	clickLogo: PropTypes.func
}

export default Header
