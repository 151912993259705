import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Modal from '@Modals/Modal'
import _ from 'lodash'
import { openShare } from '@Store/share/actions'
import Cards from '@Cards'
import AskNotification from '@Atoms/askNotification'
import PaperPlaneSend from '@Icons/PaperPlaneSend'
import ButtonShare from '@Atoms/ButtonShare'
import { MODAL_AMBASSADOR } from '@Modals/ModalInfos/modals'
import InfoTitle from '@Atoms/InfoTitle'
import { formatCurrency } from '@Helpers/number_helper'
import { Trans } from 'react-i18next'

class ModalAmbassador extends React.Component {

	constructor (props) {
		super(props)
		this.state = {
			user: props.user,
		}
	}

	componentDidUpdate (prevProps, prevState, snapshot) {
		if (!_.isEqual(this.props.user, prevProps.user)) this.setState({ user: this.props.user })
	}

	render () {

		const { user } = this.state

		if (
			!user
		) return null

		return (
			<Modal type="modal-mobile-center" name={MODAL_AMBASSADOR} centered>
				<InfoTitle text="Ambassador level"/>
				<div className="caption mb-2"><Trans i18nKey="modalAmbassador.title"/></div>
				<div className="caption mb-2">
					<Trans i18nKey="modalAmbassador.subtitle" values={{amount: formatCurrency(120000, user.currency)}} />
				</div>
				<Cards.CardAmbassador/>
				<Cards.CardMeritocracy />
				<AskNotification>
					<div className="link mb-2">
						<Trans i18nKey="modalAmbassador.notify" />
					</div>
				</AskNotification>
				<div className="px-3">
					<ButtonShare color="primary" fullwidth type="spreadRevolution" />
				</div>
			</Modal>
		)
	}
}

const mapStateToProps = state => {
	return {
		user: state.User.user,
	}
}
const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			openShare
			// add other watcher sagas to this object to map them to props
		},
		dispatch
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ModalAmbassador))
