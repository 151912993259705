
/* THE VOTE IS LOCKED */
/* The voted person is not on Wiraki */
export const LOCKED_NOT_REGISTERED = 'LOCKED_NOT_REGISTERED'
/* The voted person is on Wiraki */
export const LOCKED_REGISTERED = 'LOCKED_REGISTERED'
/* The voted person is get hired - The vote is lost */
export const LOCKED_HIRED_LOST = 'LOCKED_HIRED_LOST'

/* THE VOTE IS UNLOCKED BUT NOT YET SECURED */
/* The voted person is not on Wiraki */
export const UNLOCKED_NOT_REGISTERED = 'UNLOCKED_NOT_REGISTERED'
/* The voted person is on Wiraki */
export const UNLOCKED_REGISTERED = 'UNLOCKED_REGISTERED'
/* The voted person is get hired - The vote is lost */
export const UNLOCKED_HIRED_LOST = 'UNLOCKED_HIRED_LOST'
/* The voted person already got 3 unlock - Vote again */
export const UNLOCKED_VOTE_AGAIN = 'UNLOCKED_VOTE_AGAIN'

/* THE VOTE IS SECURED */
/* The voted person is not yet get hired */
export const SECURED_NOT_HIRED = 'SECURED_NOT_HIRED'
/* The voted person is get hired - to be redeemed */
export const SECURED_HIRED = 'SECURED_HIRED'
/* The voted person is not on the platform but is secured */
export const SECURED_NOT_REGISTERED = 'SECURED_NOT_REGISTERED'
/* The voted person is get hired and user redeemed it - win  */

export const SECURED_HIRED_REDEEMED = 'SECURED_HIRED_REDEEMED'
/* This is the first vote that we unlock regardless other logics */
export const FIRST_TO_BE_UNLOCKED = 'FIRST_TO_BE_UNLOCKED'
export const AVAILABLE = 'AVAILABLE'

export const VOTE_STATUSSES = [
	LOCKED_NOT_REGISTERED,
	LOCKED_REGISTERED,
	LOCKED_HIRED_LOST,

	UNLOCKED_NOT_REGISTERED,
	UNLOCKED_REGISTERED,
	UNLOCKED_HIRED_LOST,

	SECURED_NOT_HIRED,
	SECURED_HIRED,
	SECURED_HIRED_REDEEMED,
	SECURED_NOT_REGISTERED,

	AVAILABLE
]
