import React from 'react'
import { Card, CardBody, Progress } from 'reactstrap'
import _ from 'lodash'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { toggleModal } from '@Store/actions'
import { MODAL_AMBASSADOR } from '@Modals/ModalInfos/modals'
import { Trans } from 'react-i18next'
import Badge from '@Atoms/badge'

class CardAmbassadorRank extends React.Component {

	constructor (props) {
		super(props)
		this.state = {
			user: props.user
		}
	}

	componentDidUpdate (prevProps, prevState, snapshot) {
		if (!_.isEqual(this.props.user, prevProps.user)) this.setState({ user: this.props.user })
	}

	getPercentageBar () {
		const { user } = this.state

		if (!user.rewardAmbassador.nextLevel) return 100

		const total = user.rewardAmbassador.nextLevel.numInvites
		const actual = user.rewardAmbassador.numberInvitedPeople
		const percentage = 100 / total * actual
		return percentage === 0 ? 5 : percentage
	}

	getStyleOffsetBar () {
		const percentage = this.getPercentageBar()

		if (percentage <= 10) return { left: `10%` }
		if (percentage <= 50) return { left: `${percentage}%` }
		if (percentage >= 90) return { right: `10%` }
		return { right: `${100 - percentage}%` }
	}

	render () {

		const { user } = this.state

		if (!user) return null

		return (
			<Card className="clickable" onClick={() => this.props.toggleModal(MODAL_AMBASSADOR)}>

				<CardBody>
					<div className="caption-big font-weight-bold text-success">
						<Trans i18nKey={`ambassadorLevel.${user.rewardAmbassador.label}`}/> ({user.rewardAmbassador.sharePercentage}% bonus)
						<Badge color="info" small className="float-end"/>
					</div>
					<div className="position-relative">
						<Progress
							value={this.getPercentageBar()}
							color="info"
						/>
						<span className={`caption-small mt-1 float-start ${user.rewardAmbassador.numberInvitedPeople === 0 ? 'text-info font-weight-bold' : ''}`}>0</span>
						{user.rewardAmbassador.numberInvitedPeople === 0 ? (
							<span
								className="caption-small mt-1 text-info font-weight-bold"
								style={{ position: 'absolute', left: '5%' }}
							><Trans i18nKey="cardAmbassadorRank.acceptedInvite"/></span>
						) : (
							<span
								className="caption-small mt-1 text-info font-weight-bold"
								style={{ position: 'absolute', ...this.getStyleOffsetBar() }}
							><Trans i18nKey="cardAmbassadorRank.invite" count={user.rewardAmbassador.numberInvitedPeople}/></span>
						)}
						<span className="caption-small mt-1 float-end">
							{user.rewardAmbassador.nextLevel ? user.rewardAmbassador.nextLevel.numInvites : user.rewardAmbassador.numInvites}
						</span>
					</div>
				</CardBody>
			</Card>
		)
	}
}

const mapStateToProps = state => {
	return {
		user: state.User.user,
	}
}
const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			toggleModal,
			// add other watcher sagas to this object to map them to props
		},
		dispatch
	)
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CardAmbassadorRank))
