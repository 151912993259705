import React, { useState } from 'react'
import { Popover, PopoverBody } from 'reactstrap'
import { Trans } from 'react-i18next'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { openShare } from '@Store/actions'
import PropTypes from 'prop-types'

const popoverId = _.uniqueId('popover-')

const Share = props => {

	const [popover, setPopover] = useState(false)

	const dispatch = useDispatch()

	const handleOpenShare = () => dispatch(openShare(props.customUrl, props.customMessage))

	return (
		<React.Fragment>
			<button
				id={popoverId}
				type="button"
				className="btn btn-social"
				onClick={() => {
					handleOpenShare()
					setPopover(!popover)
				}}
			>
				<i className="bx bx-share-alt"/>
			</button>
			<Popover
				onClick={() => setPopover(!popover)}
				placement="top"
				isOpen={popover}
				target={popoverId}
				trigger="legacy"
				toggle={() => setPopover(!popover)}
				popperClassName="popover-share"
			>
				<PopoverBody>
					<strong><Trans i18nKey="cardShare.copied"/></strong>
				</PopoverBody>
			</Popover>
		</React.Fragment>
	)
}

Share.propTypes = {
	customUrl: PropTypes.string,
	customMessage: PropTypes.string
}

export default Share
