import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { Card, CardBody } from 'reactstrap'
import _ from 'lodash'
import { Trans } from 'react-i18next'
import Badge from '@Atoms/badge'
import { toggleModal } from '@Store/actions'
import { CONTEST } from '@Constants/constants'
import moment from 'moment'
import Countdown from '@Atoms/countdown'
import { MODAL_CONTEST } from '@Modals/ModalInfos/modals'

class CardContest extends React.Component {

	constructor (props) {
		super(props)
		this.state = {
			user: props.user,
			network: props.network,
		}
	}

	componentDidUpdate (prevProps, prevState, snapshot) {
		if (!_.isEqual(this.props.user, prevProps.user)) this.setState({ user: this.props.user })
		if (!_.isEqual(this.props.network, prevProps.network)) this.setState({ network: this.props.network })
	}

	getContestBody (network, userUrlSlug) {
		const participants = network.participants.filter(p => p.verified).length
		const contestEnd = network.contest.endedAt
		const contestStarted = network.contest.startedAt

		if (!contestStarted) return (
			<React.Fragment>
				<span className="caption-small"><Trans i18nKey="cardContest.startTheContest.title"/></span>
				<h5 className="caption text-primary mb-0">
					<Trans
						i18nKey="cardContest.startTheContest.content"
						components={{ bold: <span className="font-weight-bold"/> }}
						count={CONTEST.numParticipantForContest - participants}
					/>
				</h5>
			</React.Fragment>
		)
		if (network.certificate && network.certificate.url && network.certificate.code) return (
			<React.Fragment>
				<div className="caption-small"><Trans i18nKey="cardContest.certificateAvailable.title"/></div>
				<Link to={`/certificate/${network.certificate.code}/${userUrlSlug}`}>
					<div className="btn btn-sm clickable btn-primary w-75 mt-2">
						<Trans
							i18nKey="cardContest.certificateAvailable.button"
							components={{ bold: <span className="font-weight-bold"/> }}
						/>
					</div>
				</Link>
			</React.Fragment>
		)
		if (moment(contestEnd) > moment()) return (
			<React.Fragment>
				<span className="caption-small"><Trans i18nKey="cardContest.contestRunning.title"/></span>
				<h5 className="caption-big text-primary mb-0 font-weight-bold">
					<Countdown date={contestEnd} icon={false} showDays/>
				</h5>
			</React.Fragment>
		)
		return (
			<h5 className="caption text-primary mb-0">
				<Trans
					i18nKey="cardContest.contestEnded.content"
					components={{ bold: <span className="font-weight-bold"/> }}
				/>
			</h5>
		)
	}

	render () {
		const { user, network } = this.state

		if (
			!user ||
			!network
		) return null

		return (
			<Card className={`card-short clickable ${this.state.className}`} onClick={() => this.props.toggleModal(MODAL_CONTEST)}>
				<CardBody className="text-center position-relative">
					<Badge
						color="info"
						small
						style={{ position: 'absolute', right: '8px' }}
					/>

					{this.getContestBody(network, user.profile.urlName)}

				</CardBody>
			</Card>
		)
	}
}

const mapStateToProps = state => {
	return {
		user: state.User.user,
		network: state.Network.network,
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			toggleModal,
			// add other watcher sagas to this object to map them to props
		},
		dispatch
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CardContest))
