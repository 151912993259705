import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Modal from '@Modals/Modal'
import { Trans, useTranslation } from 'react-i18next'
import JoinerBonusLine from '@Atoms/JoinerBonusLine'
import { MODAL_MERITOCRACY_EXPLAIN } from '@Modals/ModalInfos/modals'
import InfoTitle from '@Atoms/InfoTitle'
import ButtonShare from '@Atoms/ButtonShare'

const ModalMeritocracyExplain = props => {

	const { i18n } = useTranslation()

	const [image, setImage] = useState()

	import(`@Assets/images/infographic/money_distribution_${i18n.languages[0]}.png`).then((img) => setImage(img.default))

	const user = useSelector(state => state.User.user)

	useEffect(() => {}, [user])

	if (
		!user ||
		!user.reward
	) return null

	return (
		<Modal type="modal-mobile-center" name={MODAL_MERITOCRACY_EXPLAIN} centered>
			<InfoTitle text={<Trans i18nKey="modalMeritocracyExplain.title"/>}/>
			<p className="caption mb-3">
				<Trans i18nKey="modalMeritocracyExplain.content"/>
			</p>
			<img src={image} className="p-2 mb-2 w-100" alt=""/>
			<p className="caption mb-3">
				<Trans i18nKey="modalMeritocracyExplain.headhunter"/>
			</p>
			<ButtonShare color="primary" fullwidth type="inviteFriend"/>
			<JoinerBonusLine/>
		</Modal>
	)
}

export default ModalMeritocracyExplain
