import React from 'react'
import { Trans } from 'react-i18next'

const WhoRewardsWork = props => {

	const steps = [
		{
			stepNumber: 1,
			color: 'danger',
			title: <Trans i18nKey="faq.howRewardsWork.1.title" components={{ span: <span className="body-regular text-danger"/> }}/>,
			text: <Trans i18nKey="faq.howRewardsWork.1.text"/>
		},
		{
			stepNumber: 2,
			color: 'primary',
			title: <Trans i18nKey="faq.howRewardsWork.2.title" components={{ span: <span className="body-regular text-primary"/> }}/>,
			text: <Trans i18nKey="faq.howRewardsWork.2.text"/>
		},
		{
			stepNumber: 3,
			color: 'success',
			title: <Trans i18nKey="faq.howRewardsWork.3.title" components={{ span: <span className="body-regular text-success"/> }}/>,
			text: <Trans i18nKey="faq.howRewardsWork.3.text"/>
		},
		{
			stepNumber: 4,
			color: 'secondary-dark',
			title: <Trans i18nKey="faq.howRewardsWork.4.title" components={{ span: <span className="body-regular text-secondary-dark"/> }}/>,
			text: <Trans i18nKey="faq.howRewardsWork.4.text"/>
		}
	]

	return {
		title: (
			<div className="d-flex flex-row align-items-center justify-content-center">
				<div className="text-center h5 mb-0 me-1" style={{ width: '24px' }}>🗳</div>
				<Trans i18nKey="faq.howRewardsWork.title"/>
			</div>
		),
		content: (
			<div id="how-rewards-work">
				{steps.map((step, k) => (
					<div key={k} className="faq-element">
						<div className="faq-number">
							<span className={`h6 text-${step.color}`}>{step.stepNumber}</span>
						</div>
						<div className="faq-content">
							<div className="faq-title">{step.title}</div>
							<div className="faq-text">{step.text}</div>
						</div>
					</div>
				))}
			</div>
		)
	}
}

export default WhoRewardsWork
