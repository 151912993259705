import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import _ from 'lodash'
import { updateEntryBonusCountdown } from '@Store/user/actions'

const EntryBonusCountown = props => {

	const dispatch = useDispatch()
	const { bonusVerifyBefore } = useSelector(state => state.User.user)

	function calculateTimeLeft () {

		const difference = moment(bonusVerifyBefore).diff(moment(new Date()), 'seconds')

		let timeLeft = {}
		if (difference > 0) {
			timeLeft = {
				days: Math.floor(difference / (60 * 60 * 24)),
				hours: Math.floor((difference / (60 * 60)) % 24),
				minutes: Math.floor((difference / 60) % 60),
				seconds: Math.floor((difference) % 60),
			}
		}


		if (!_.isEmpty(timeLeft)) {
			dispatch(updateEntryBonusCountdown(timeLeft))
		} else {
			dispatch(updateEntryBonusCountdown(null))
		}

		return timeLeft
	}

	const [timeLeft, setTimeLeft] = useState()

	useEffect(() => calculateTimeLeft(), [bonusVerifyBefore])

	useEffect(() => {
		const id = setTimeout(() => {
			const timeLeft = calculateTimeLeft()
			if (!_.isEmpty(timeLeft)) {
				setTimeLeft(timeLeft)
			}
		}, 1000)
		return () => { clearTimeout(id) }
	})

	return null
}

export default EntryBonusCountown
