import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { formatCurrency } from '@Helpers/number_helper'
import _ from 'lodash'
import { MODAL_MERITOCRACY_EXPLAIN } from '@Modals/ModalInfos/modals'
import { Col, Row } from 'reactstrap'
import { Trans } from 'react-i18next'
import { toggleModal } from '@Store/actions'
import PropTypes from 'prop-types'

class AmountWhySoHigh extends React.Component {

	constructor (props) {
		super(props)
		this.state = {
			user: props.user,
			amount: props.amount,
		}
	}

	componentDidUpdate (prevProps, prevState, snapshot) {
		if (!_.isEqual(this.props.user, prevProps.user)) this.setState({ user: this.props.user })
		if (!_.isEqual(this.props.amount, prevProps.amount)) this.setState({ amount: this.props.amount })
	}

	render () {
		const { user, amount } = this.state
		if (
			!user ||
			!amount
		) return null

		let amountSize = 0
		let positionTop = 5
		switch (this.props.size) {
			case 'big':
				amountSize = '1.5rem'
				positionTop = 13
				break
			case 'medium':
				amountSize = '0.875rem'
				break
			case 'small':
				amountSize = '0.75rem'
				break
		}

		return (
			<div className={`clickable ${this.props.className}`} onClick={() => this.props.toggleModal(MODAL_MERITOCRACY_EXPLAIN)}>
				{this.props.yourReward && (
					<Row>
						<Col xs={{ size: 4, offset: 4 }}>
							<div className="my-2 caption-small">
								<Trans i18nKey="atomAmountWhySoHigh.yourReward"/>
							</div>
						</Col>
					</Row>
				)}
				<Row>
					<Col xs={{ size: 4, offset: 4 }} className="px-0">
						<div className="position-relative">
							<span style={{ fontSize: amountSize, marginLeft: '-10px' }} className={`text-${this.props.color} font-weight-bold`}>
								{this.props.amountWithTilde && ('~ ')}
								{formatCurrency(amount, user.currency)}
							</span>
							<div
								className="caption-small text-start text-decoration-underline text-secondary"
								style={{ position: 'absolute', right: '-75px', top: positionTop }}
							>
								<Trans i18nKey="atomAmountWhySoHigh.whySoHigh"/>
							</div>
						</div>
					</Col>
				</Row>
			</div>
		)
	}
}

AmountWhySoHigh.defaultProps = { yourReward: true, amountWithTilde: true, size: 'small', color: 'success' }
AmountWhySoHigh.propTypes = {
	yourReward: PropTypes.bool,
	amountWithTilde: PropTypes.bool,
	size: PropTypes.oneOf(['big', 'medium', 'small']),
	color: PropTypes.oneOf(['success', 'primary']),
	className: PropTypes.string,
	amount: PropTypes.number,
}

const mapStateToProps = state => {
	return {
		user: state.User.user,
	}
}
const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			toggleModal
			// add other watcher sagas to this object to map them to props
		},
		dispatch
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AmountWhySoHigh))
