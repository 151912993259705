import React from 'react'
import { Button, Card, CardBody } from 'reactstrap'
import PropTypes from 'prop-types'
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded'
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded'

const CardJobOffer = props => {

	return (
		<Card
			className={`clickable d-flex flex-direction-row align-items-center card-short ${props.className}`}
			onClick={props.onClick}
		>
			<CardBody className="p-3 w-100 text-left d-flex flex-row align-items-center justify-content-start">
				<img width={50} className="me-2" src={props.company.logo}/>
				<div className="d-flex flex-column align-items-start justify-content-center w-100">
					<h3 className="mb-2" style={{ fontSize: '16px' }}>{props.job.title}</h3>
					<div className="d-flex flex-row justify-content-between align-items-end w-100">
						<div>
							<h3 className="d-flex align-items-center text-gray-normal w-100" style={{ fontSize: '12px' }}>
								<ApartmentRoundedIcon style={{ fontSize: '20px', color: '#333' }}/>&nbsp;{props.company.name}
							</h3>
							<h3 className="d-flex align-items-center text-gray-normal w-100" style={{ fontSize: '12px' }}>
								<LocationOnRoundedIcon style={{ fontSize: '20px', color: '#333' }}/>&nbsp;{props.job.location}
							</h3>
						</div>
						<Button size="md" color="primary">Apply</Button>
					</div>
				</div>
			</CardBody>
		</Card>
	)
}

CardJobOffer.propTypes = {
	className: PropTypes.any,
	job: PropTypes.object.isRequired,
	company: PropTypes.string.isRequired,
	onClick: PropTypes.func,
}

export default CardJobOffer
