import React, { useEffect } from 'react'
import { BrowserRouter, Route, Switch, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
// Import Routes all
import { authProtectedRoutes, publicRoutes } from '@Routes/index'
// Import all middleware
import Authmiddleware from '@Routes/authmiddleware'
// layouts Format
import Page404 from '@Pages/Utility/page-404'
import EntryBonusCountown from '@Hook/EntryBonusCountdown'

// Import scss
import '@Assets/scss/theme.scss'
import { loadInitialData } from '@Store/actions'
import { ToastContainer } from 'react-toastify'

const App = props => {

	const dispatch = useDispatch()

	useEffect(() => {
		if (localStorage.getItem('authUser')) dispatch(loadInitialData())
	}, [])

	return (
		<React.Fragment>
			<EntryBonusCountown />
			<ToastContainer/>
			<BrowserRouter>
				<Switch>
					{publicRoutes.map((route, k) => (
						<Authmiddleware
							path={route.path}
							layout={route.layout}
							component={route.component}
							key={k}
							isAuthProtected={false}
							redirectIfAuthorized={route.redirectIfAuthorized}
							exact={route.exact}
							layoutProps={route.layoutProps}
						/>
					))}

					{authProtectedRoutes.map((route, k) => (
						<Authmiddleware
							path={route.path}
							layout={route.layout}
							component={route.component}
							key={k}
							isAuthProtected={true}
							exact={route.exact}
							layoutProps={route.layoutProps}
						/>
					))}
					<Route component={Page404}/>
				</Switch>
			</BrowserRouter>
		</React.Fragment>
	)
}

export default App
