import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Card, CardBody} from 'reactstrap'
import {Trans} from 'react-i18next'
import PropTypes from 'prop-types'
import wirakiLogo from '@Assets/images/Logo.png'
import ButtonShare from '@Atoms/ButtonShare'
import {MODAL_PARTICIPANTS} from '@Modals/ModalPages/modals'
import {NUM_PARTICIPANTS_TO_SHOW} from '@Constants/constants'
import FAQ from '@Molecules/FAQ'
import {toggleModal} from '@Store/actions'
import Vote from '@Molecules/Vote/Vote'

const CardNetwork = props => {

    const dispatch = useDispatch()

    const userData = useSelector(state => state.User.user)
    const [user, setUser] = useState(userData)
    useEffect(() => setUser(userData), [userData])

    const {buttonShare, network, goal} = props

    if (
        !user ||
        !user.inviteCode ||
        !goal ||
        !network ||
        !network.university ||
        !network.faculty ||
        !network.participants ||
        !network.votes
    ) return null

    const partecipantsVerified = network.participants.filter(p => p.verified)

    return (
        <Card className="card-network">
            <CardBody className="d-flex flex-column pt-4 pb-3">
                <div className="mb-4">
                    <h5 className="fw-semibold mb-1"><Trans i18nKey="cardNetwork.title"/></h5>
                    <div><Trans i18nKey="cardNetwork.subtitle"/></div>
                </div>
                <div className="w-100 w-lg-75 mx-auto">
                    <div className="d-flex mb-3 mx-auto w-100">
                        <div className="d-flex pe-2">
                            {network.university.logo ? (
                                <img alt="" height={37} width={37} src={network.university.logo}
                                     style={{borderRadius: '4px'}}/>
                            ) : (
                                <img alt="" height={37} width={37} src={wirakiLogo}
                                     style={{fontSize: '40px', borderRadius: '4px', border: '1px solid #EA6B24'}}/>
                            )}
                        </div>
                        <div className="d-flex flex-column">
                            <div className="h7">{network.faculty.name}</div>
                            <div className="text-secondary fw-bold clickable"
                                 onClick={() => dispatch(toggleModal(MODAL_PARTICIPANTS))}>
                                {partecipantsVerified.length < NUM_PARTICIPANTS_TO_SHOW ? (
                                    <Trans
                                        i18nKey="cardNetwork.buttonColleaguesLess"
                                        values={{amount: NUM_PARTICIPANTS_TO_SHOW}}
                                        components={{span: <span/>, less: <i className="fa fa-angle-left"/>}}
                                    />
                                ) : (
                                    <Trans
                                        i18nKey="cardNetwork.buttonColleagues"
                                        values={{amount: partecipantsVerified.length}}
                                        components={{span: <span/>}}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    {network.votes.map((vote, k) => (
                        <Vote key={k} data={vote}/>
                    ))}
                    <div className="text-center mb-2" style={{marginTop: '42px'}}>
                        <ButtonShare className="w-75" color={buttonShare} type="inviteToUnlock"/>
                    </div>
                </div>

                <hr/>

                <FAQ/>

            </CardBody>
        </Card>
    )
}

CardNetwork.defaultProps = {
    buttonShare: 'primary'
}

CardNetwork.propTypes = {
    network: PropTypes.object.isRequired,
    buttonShare: PropTypes.string,
}

export default CardNetwork
