// @flow
import { CHANGE_LAYOUT, CHANGE_PAGE, TOGGLE_INTERNAL_PAGE, TOGGLE_MODAL, } from './actionTypes'
import { scrollToTop } from '@Helpers/global'

const INIT_STATE = {
	page: {
		title: '',
		subtitle: '',
	},
	modal: {},
	internalPage: {},
	layout: 'emptyNetwork',
}
let newState = {}

const Template = (state = INIT_STATE, action) => {
	switch (action.type) {
		case CHANGE_LAYOUT:
			return {
				...state,
				layout: action.payload
			}
		case CHANGE_PAGE:
			scrollToTop()
			return {
				...state,
				page: {
					...action.payload
				},
			}
		case TOGGLE_MODAL:
			if (action.payload.dismissable) window.history.pushState(null, document.title, window.location.href)
			document.body.style.overflow = action.payload.open ? 'hidden' : 'unset'

			newState = {
				...state,
				modal: {
					...state.modal,
					[action.payload.modal]: {
						...state.modal[action.payload.modal],
						open: action.payload.open,
						dismissable: action.payload.dismissable,
					}
				}
			}
			// Update only if there are some data
			if (action.payload.data) {
				newState.modal[action.payload.modal].data = action.payload.data
			}

			return newState
		case TOGGLE_INTERNAL_PAGE:
			if (action.payload.dismissable) window.history.pushState(null, document.title, window.location.href)
			document.body.style.overflow = action.payload.open ? 'hidden' : 'unset'

			newState = {
				...state,
				internalPage: {
					...state.internalPage,
					[action.payload.pageName]: {
						...state.internalPage[action.payload.pageName],
						open: action.payload.open,
						dismissable: action.payload.dismissable,
					}
				}
			}
			// Update only if there are some data
			if (action.payload.data) {
				newState.internalPage[action.payload.pageName].data = action.payload.data
			}
			return newState
		default:
			return state
	}
}

export default Template
