import { API_ERROR, LOGIN_SUCCESS, LOGIN_USER, LOGIN_WITH_TOKEN, LOGOUT_USER, LOGOUT_USER_SUCCESS, SOCIAL_LOGIN, } from './actionTypes'

export const loginUser = (user, history) => {
	return {
		type: LOGIN_USER,
		payload: { user, history },
	}
}

export const loginSuccess = token => ({
	type: LOGIN_SUCCESS,
	payload: token,
})

export const logoutUser = history => {
	return {
		type: LOGOUT_USER,
		payload: { history },
	}
}

export const logoutUserSuccess = () => {
	return {
		type: LOGOUT_USER_SUCCESS,
		payload: {},
	}
}

export const apiError = error => {
	return {
		type: API_ERROR,
		payload: error,
	}
}

export const socialLogin = (data, history, type) => {
	return {
		type: SOCIAL_LOGIN,
		payload: { data, history, type },
	}
}

export const loginWithToken = token => ({
	type: LOGIN_WITH_TOKEN,
	payload: token
})
