import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { Card, CardBody } from 'reactstrap'
import _ from 'lodash'
import { Trans, withTranslation } from 'react-i18next'
import Badge from '@Atoms/badge'
import { toggleModal } from '@Store/actions'
import { MODAL_RANK_CONFIDENCE } from '@Modals/ModalInfos/modals'

class CardRankConfidence extends React.Component {

	constructor (props) {
		super(props)
		this.state = {
			user: props.user,
			className: props.className,
		}
	}

	componentDidUpdate (prevProps, prevState, snapshot) {
		if (!_.isEqual(this.props.user, prevProps.user)) this.setState({ user: this.props.user })
		if (!_.isEqual(this.props.className, prevProps.className)) this.setState({ className: this.props.className })
	}

	render () {
		const { user } = this.state

		if (
			!user
		) return null

		return (
			<Card className={`card-short clickable ${this.state.className}`} onClick={() => this.props.toggleModal(MODAL_RANK_CONFIDENCE)}>
				<CardBody className="text-center position-relative">
					<Badge
						color="info"
						small
						style={{ position: 'absolute', right: '8px' }}
					/>
					<span className="caption-small"><Trans i18nKey="cardRankConfidence.title"/></span>
					<h5 className="caption-big text-primary mb-0 font-weight-bold">
						<div style={{ filter: !user.verified ? 'blur(3px)' : '' }}>
							<Trans i18nKey={`rankPrecision.${user.wRank.rankLabel}`}/>
						</div>
					</h5>
				</CardBody>
			</Card>
		)
	}
}

const mapStateToProps = state => {
	return {
		user: state.User.user,
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			toggleModal,
			// add other watcher sagas to this object to map them to props
		},
		dispatch
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(CardRankConfidence)))
