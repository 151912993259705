import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { Card, CardBody } from 'reactstrap'
import _ from 'lodash'
import { toggleModal } from '@Store/template/actions'
import { resendEmailVerify } from '@Store/global/actions'
import { Trans } from 'react-i18next'

class CardVerifyEmail extends React.Component {

	constructor (props) {
		super(props)
		this.state = {
			user: props.user,
			showAsCard: props.showAsCard || false,
			emailVerifySent: false,
		}
	}

	componentDidUpdate (prevProps, prevState, snapshot) {
		if (!_.isEqual(this.props.user, prevProps.user)) this.setState({ user: this.props.user })
		if (!_.isEqual(this.props.emailVerifySent, prevProps.emailVerifySent)) {
			setTimeout(function () {
				this.setState({ emailVerifySent: false })
			}.bind(this), 10000)
			this.setState({ emailVerifySent: this.props.emailVerifySent })
		}
	}

	render () {
		const { user, emailVerifySent } = this.state

		if (!user) return null
		if (user.verified) return null


		return (
			<React.Fragment>
				<Card className="card-verify-email">
					<CardBody className="d-flex flex-column">
						<h3 className="title mb-2">
							<Trans i18nKey="cardVerifyEmail.verifyEmailParticipate"/>
						</h3>
						<button
							className={`btn btn-link ${emailVerifySent ? 'text-success' : 'text-primary'} text-decoration-underline clickable`}
							onClick={() => this.props.resendEmailVerify()}
							disabled={emailVerifySent}
						>
						</button>
						<span className="h7 text-gray-light">
							<Trans i18nKey="cardVerifyEmail.toEmail" values={{email: user.email}}/></span>
					</CardBody>
				</Card>
			</React.Fragment>
		)
	}
}

const mapStateToProps = state => {
	return {
		...state.Template,
		user: state.User.user,
		emailVerifySent: state.Global.emailVerifySent,
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			toggleModal,
			resendEmailVerify,
			// add other watcher sagas to this object to map them to props
		},
		dispatch
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CardVerifyEmail))
