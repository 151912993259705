import React from 'react'
import { Trans } from 'react-i18next'
import { MAX_VOTED_PER_VOTE } from '@Constants/constants'
import PropTypes from 'prop-types'
import {
	AVAILABLE,
	LOCKED_HIRED_LOST,
	LOCKED_NOT_REGISTERED,
	LOCKED_REGISTERED,
	SECURED_HIRED,
	SECURED_HIRED_REDEEMED,
	SECURED_NOT_HIRED, SECURED_NOT_REGISTERED,
	UNLOCKED_HIRED_LOST,
	UNLOCKED_NOT_REGISTERED,
	UNLOCKED_REGISTERED,
	VOTE_STATUSSES
} from '@Molecules/Vote/voteStatusses'

const VoteName = props => {

	const { voteStatus, vote, unlockFirstVote } = props

	const textVotesLeft = vote.count < MAX_VOTED_PER_VOTE ? (
		<Trans
			i18nKey="cardNetwork.votesRemaining"
			count={MAX_VOTED_PER_VOTE - vote.count}
			values={{ total: MAX_VOTED_PER_VOTE }}
			components={{
				bold: <span className="font-weight-bold"/>
			}}
		/>
	) : (
		<Trans
			i18nKey="cardNetwork.noVotesRemaining"
			components={{
				bold: <span className="font-weight-bold"/>
			}}
		/>
	)

	if(unlockFirstVote) return (
		<div className="gray-6 b1">{vote.vote}</div>
	)

	switch (voteStatus) {
		/* LOCKED */
		case LOCKED_NOT_REGISTERED:
			return (
				<>
					<div className="gray-4 b1">{vote.vote}</div>
					<small className="text-secondary"><Trans i18nKey="cardNetwork.invite"/></small>
				</>
			)
		case LOCKED_REGISTERED:
			return (
				<>
					<div className="gray-4 b1">{vote.vote}</div>
					<small className="gray-4">{textVotesLeft}</small>
				</>
			)
		/* LOST */
		case LOCKED_HIRED_LOST:
		case UNLOCKED_HIRED_LOST:
		case SECURED_HIRED:
		case SECURED_HIRED_REDEEMED:
			return (
				<>
					<div className="gray-6 b1">{vote.vote}&nbsp;🎉</div>
					<small className="gray-6"><Trans i18nKey="cardNetwork.hired"/></small>
				</>
			)
		/* UNLOCKED */
		case UNLOCKED_NOT_REGISTERED:
			return (
				<>
					<div className="gray-5 b1">{vote.vote}</div>
					<small className="text-secondary"><Trans i18nKey="cardNetwork.invite"/></small>
				</>
			)
		case UNLOCKED_REGISTERED:
			return (
				<>
					<div className="gray-6 b1">{vote.vote}</div>
					<small className="gray-4">{textVotesLeft}</small>
				</>
			)
		/* SECURED */
		case SECURED_NOT_HIRED:
			return (
				<>
					<div className="gray-6 b1">{vote.vote}</div>
					<small className="gray-4"><Trans i18nKey="cardNetwork.waitingHire"/></small>
				</>
			)
		case SECURED_NOT_REGISTERED:
			return (
				<>
					<div className="gray-6 b1">{vote.vote}</div>
					<small className="text-secondary"><Trans i18nKey="cardNetwork.invite"/></small>
				</>
			)
		case AVAILABLE:
			return (
				<>
					<div className="gray-6 b1">{vote.vote}</div>
					<small className="gray-4">
						<Trans i18nKey="cardNetwork.subtitleVoteAvailable"/>
					</small>
				</>
			)
	}
	return (<div className="gray-5 b1">{vote.vote}</div>)

}

VoteName.defaultProps = {
	unlockFirstVote: false,
}

VoteName.propTypes = {
	vote: PropTypes.any.isRequired,
	voteStatus: PropTypes.oneOf(VOTE_STATUSSES),
	unlockFirstVote: PropTypes.bool,
}

export default VoteName
