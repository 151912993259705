import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import routes from '@Routes/routeConfig'
import { useSelector } from 'react-redux'
import { Trans } from 'react-i18next'

const menu = [
	routes.HOME,
	routes.RANK,
	routes.JOBS,
]

const Navigation = props => {

	const pageData = useSelector(state => state.Template.page)
	const remoteStorageData = useSelector(state => state.RemoteStorage.remoteStorage)

	const [page, setPage] = useState(pageData)
	const [remoteStorage, setRemoteStorage] = useState(remoteStorageData)

	useEffect(() => setPage(pageData), [pageData])
	useEffect(() => setRemoteStorage(remoteStorageData), [remoteStorageData])

	if (!page) return null

	return (
		<div className="navigation">
			<div className="d-flex flex-row justify-content-evenly w-100 px-4">
				{menu.map((item, k) => {
					const className = !remoteStorage.completedWelcome ? 'disabled-link' : page.name === item.name ? 'active' : ''
					return (
						<Link
							key={k}
							to={`${item.route}`}
							className={`nav-item ${className}`}
						>
							<span><Trans i18nKey={item.menuTitle}/></span>
						</Link>
					)
				})}
			</div>

		</div>
	)
}

export default Navigation
