import React from 'react'
import Modal from '@Modals/Modal'
import { MODAL_LINK_INVITE_TO_VOTE } from '@Modals/ModalInfos/modals'
import { Trans } from 'react-i18next'
import InputShare from '@Atoms/share/InputShare'
import { useSelector } from 'react-redux'
import ShareWhatsapp from '@Atoms/share/ShareWhatsapp'
import ShareTelegram from '@Atoms/share/ShareTelegram'
import { shareLink } from '@Helpers/share'

const ModalLinkInviteToVote = props => {

	const inviteToVoteLink = useSelector(state => state.Votes.inviteToVoteLink)

	if (!inviteToVoteLink) return null

	const url = shareLink(inviteToVoteLink)

	return (
		<Modal type="modal-mobile-center" name={MODAL_LINK_INVITE_TO_VOTE} centered>
			<h3 className="title mb-3"><Trans i18nKey="modalLinkInvitetoVote.title"/></h3>
			<p className="caption mb-3">
				<Trans
					i18nKey="modalLinkInvitetoVote.content"
					components={{
						success: <span className="text-success"/>
					}}
				/>
			</p>
			<p className="caption mb-2 font-weight-bold">
				<Trans i18nKey="modalLinkInvitetoVote.voteShare"/>
			</p>
			<InputShare url={url} className="m-auto mb-3"/>
			<div className="container-button-share">
				<ShareTelegram message={url.replace('&', '%26')}/>
				<ShareWhatsapp message={url.replace('&', '%26')}/>
			</div>
		</Modal>
	)
}

export default ModalLinkInviteToVote
