import React, {useEffect, useState} from 'react'
import {Card, CardBody} from 'reactstrap'
import {Trans} from 'react-i18next'
import PropTypes from 'prop-types'
import {useDispatch, useSelector} from 'react-redux'
import ButtonShare from '@Atoms/ButtonShare'
import {AVAILABLE} from '@Molecules/Vote/voteStatusses'
import Vote from '@Molecules/Vote/Vote'

const CardPrivateNetwork = props => {

    const dispatch = useDispatch()

    const {votes, votesAvailable} = props

    const userData = useSelector(state => state.User.user)

    const [user, setUser] = useState(userData)

    useEffect(() => setUser(userData), [userData])

    if (
        !user ||
        !user.inviteCode ||
        !votes
    ) return null

    if (votes.length === 0 && votesAvailable > 0) {
        votes.push({
            status: AVAILABLE,
            vote: <Trans i18nKey="cardNetwork.titleVoteAvailable"/>
        })
    }

    return (
        <Card className="card-network">
            <CardBody className="d-flex flex-column">
                <div className="h5 fw-semibold mb-2"><Trans i18nKey="cardPrivateNetwork.title"/></div>
                <div className="mb-4">
                    <div className="mb-1"><Trans i18nKey="cardPrivateNetwork.row1"/></div>
                    <div><Trans i18nKey="cardPrivateNetwork.row2"/></div>
                </div>
                <div className="w-100 w-lg-75 mx-auto">
                    {votes.map((vote, k) => (
                        <Vote key={k} data={vote}/>
                    ))}
                    {votesAvailable === 1 && (
                        <div className="text-center mb-2" style={{marginTop: '42px'}}>
                            <ButtonShare color="primary" className="w-75" type="inviteNow"/>
                        </div>
                    )}
                </div>
            </CardBody>
        </Card>
    )
}

CardPrivateNetwork.propTypes = {
    votes: PropTypes.array,
    votesAvailable: PropTypes.number.isRequired,
}

export default CardPrivateNetwork
