import { LOAD_FEEDS_SUCCESS } from '@Store/feeds/actionTypes'

const INIT_STATE = {
	feeds: [],
}

const Feeds = (state = INIT_STATE, action) => {
	switch (action.type) {
		case LOAD_FEEDS_SUCCESS:
			return {
				...state,
				feeds: action.payload
			}
		default:
			return state
	}
}

export default Feeds
