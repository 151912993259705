import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { formatDate } from '@Helpers/number_helper'
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined'
import { Trans, useTranslation } from 'react-i18next'
import moment from 'moment'
import { useSelector } from 'react-redux'

const Countdown = props => {

	const { t } = useTranslation()

	const entryBonusCountdownData = useSelector(state => state.User.entryBonusCountdown)

	const [timeLeft, setTimeLeft] = useState(entryBonusCountdownData)

	useEffect(() => setTimeLeft(entryBonusCountdownData), [entryBonusCountdownData])

	function drawTimer () {

		return (
			<>
				{timeLeft.days > 0 && (
					<>
						<div className="countdown-element">
							<span className="number">{('0' + timeLeft.days).slice(-2)}</span>
							<span className="text">{t("time.day", {count: timeLeft.days})}</span>
						</div>
						<div className="countdown-element">
							<span className="number">:</span>
							<span className="text">&nbsp;</span>
						</div>
					</>
				)}
				{(timeLeft.days > 0 || timeLeft.hours > 0) && (
					<>
						<div className="countdown-element">
							<span className="number">{('0' + timeLeft.hours).slice(-2)}</span>
							<span className="text">{t("time.hour", {count: timeLeft.hours})}</span>
						</div>
						<div className="countdown-element">
							<span className="number">:</span>
							<span className="text">&nbsp;</span>
						</div>
					</>
				)}
				{(timeLeft.days > 0 || timeLeft.hours > 0 || timeLeft.minutes > 0) && (
					<>
						<div className="countdown-element">
							<span className="number">{('0' + timeLeft.minutes).slice(-2)}</span>
							<span className="text">{t("time.minute", {count: timeLeft.minutes})}</span>
						</div>
						<div className="countdown-element">
							<span className="number">:</span>
							<span className="text">&nbsp;</span>
						</div>
					</>
				)}
				{(timeLeft.days > 0 || timeLeft.hours > 0 || timeLeft.minutes > 0 || timeLeft.seconds > 0) && (
					<div className="countdown-element">
						<span className="number">{('0' + timeLeft.seconds).slice(-2)}</span>
						<span className="text">{t("time.second", {count: timeLeft.seconds})}</span>
					</div>
				)}
			</>
		)
	}

	if (!timeLeft) return null

	const renderCountdown = () => {

		if(typeof timeLeft.seconds === 'undefined') return null

		if(props.compact) return (
			<React.Fragment>
				<div className={`${props.className} countdown-compact ${props.small ? ' countdown-small' : ''}`} style={props.style} onClick={props.onClick}>
					{props.icon && (
						<AccessTimeOutlinedIcon/>
					)}
					{props.showDays ? formatDate(timeLeft, ':', true, true) : formatDate(timeLeft, ':')}
				</div>
			</React.Fragment>
		)

		return (
			<div className={props.className} style={props.style} onClick={props.onClick}>
				{drawTimer()}
			</div>
		)
	}



	return (renderCountdown())
}

Countdown.defaultProps = {
	icon: true,
}

Countdown.propTypes = {
	date: PropTypes.string,
	compact: PropTypes.bool,
	small: PropTypes.bool,
	icon: PropTypes.bool,
	showDays: PropTypes.bool,
	onClick: PropTypes.func,
}

export default Countdown
