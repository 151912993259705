import React, { useState } from 'react'
import Modal from '@Modals/Modal'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'
import { MODAL_SHARE_CERTIFICATE } from '@Modals/ModalInfos/modals'
import Share from '@Atoms/share/Share'
import ShareTelegram from '@Atoms/share/ShareTelegram'
import ShareWhatsapp from '@Atoms/share/ShareWhatsapp'
import { Button, Input, InputGroup, Popover, PopoverBody } from 'reactstrap'
import ShareLinkedin from '@Atoms/share/ShareLinkedin'

const ModalShareCertificate = props => {

	const [popover, togglePopover] = useState(false)

	const modal = useSelector(state => state.Template.modal[MODAL_SHARE_CERTIFICATE])

	if (!modal || !modal.data) return null

	const urlShare = modal.data.urlPage

	const messageShare = `${urlShare}`

	return (
		<Modal type="modal-mobile-center" name={MODAL_SHARE_CERTIFICATE} centered>
			<h3 className="title">
				<Trans i18nKey="modalShareCertificate.title"/>
			</h3>
			<div className="mb-3">
				<img src={modal.data.url} alt="" style={{ maxWidth: '100%' }}/>
			</div>
			<div className="container-button-share mb-3">
				<Share/>
				<ShareTelegram message={messageShare}/>
				<ShareWhatsapp message={messageShare}/>
				<ShareLinkedin url={urlShare}/>
			</div>
			<div className="d-flex link-share justify-content-center">
				<InputGroup size="sm" style={{ maxWidth: '280px' }}>
					<Input
						id="popoverShareCertificate"
						value={urlShare}
						disabled
					/>
					<Button
						onClick={() => togglePopover(!popover)}
						color="primary"
						outline
					><Trans i18nKey="modalShareCertificate.copy"/></Button>
				</InputGroup>
			</div>
			<Popover
				onClick={() => togglePopover(!popover)}
				placement="top"
				isOpen={popover}
				target="popoverShareCertificate"
				trigger="legacy"
				toggle={() => togglePopover(!popover)}
				popperClassName="popover-share"
			>
				<PopoverBody>
					<strong><Trans i18nKey="modalShareCertificate.copied"/></strong>
				</PopoverBody>
			</Popover>
		</Modal>
	)
}

export default ModalShareCertificate

