import PropTypes from 'prop-types'
import { default as IconBadge } from '@Components/Icons/Badge'

const Badge = props => {

	let icon = ''
	let color = 'text-gray-normal'
	let width = props.width || 20
	switch (props.color) {
		case 'bronze':
			icon = 'single'
			color = 'text-bronze'
			break
		case 'silver':
			icon = 'double'
			color = 'text-silver'
			width = width + 6
			break
		case 'gold':
			icon = 'triple'
			color = 'text-gold'
			width = width + 6
			break
		case 'diamond':
			icon = 'diamond'
			color = 'text-info'
			width = width + 4
			break
		default:
			icon = 'missing'
			break
	}

	if (props.small) width = width - 6

	let style = {
		...props.style,
		width: `${width}px`
	}
	if (props.center) style.margin = 'auto'

	return (
		<div className={`${props.className} ${color} text-center clickable`} style={style} onClick={props.onClick}>
			<IconBadge type={icon} width={width} height={width}/>
		</div>
	)
}

Badge.propTypes = {
	color: PropTypes.oneOf(['info', 'bronze', 'silver', 'gold', 'diamond']),
	small: PropTypes.bool,
	center: PropTypes.bool,
	style: PropTypes.any,
	onClick: PropTypes.func,
	width: PropTypes.number,
	className: PropTypes.string,
}

export default Badge
