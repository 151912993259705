import React, { useEffect, useState } from 'react'
import { Col } from 'reactstrap'
import routes from '@Routes/routeConfig'
import Cards from '@Cards'
import { useDispatch, useSelector } from 'react-redux'
import { changePage } from '@Store/actions'

const Home = props => {

	const dispatch = useDispatch()

	const userData = useSelector(state => state.User.user)
	const [user, setUser] = useState(userData)
	useEffect(() => setUser(userData), [userData])

	const networksData = useSelector(state => state.Network.networks)
	const [networks, setNetworks] = useState(networksData)
	useEffect(() => setNetworks(networksData), [networksData])

	const layoutData = useSelector(state => state.Template.layout)
	const [layout, setLayout] = useState(layoutData)
	useEffect(() => setLayout(layoutData), [layoutData])

	const pageData = useSelector(state => state.Template.page)
	const [page, setPage] = useState(pageData)
	useEffect(() => setPage(pageData), [pageData])

	useEffect(() => {
		if (page?.route !== routes.HOME.route) dispatch(changePage(routes.HOME))

		if (window?.dataLayer) {
			window.dataLayer.push({
				event: 'pageview',
				page: { url: routes.HOME.route, title: 'Page Network' }
			})
		}
	}, [])

	if (
		!user ||
		!networks ||
		!layout
	) return null

	/* NOTE: at the moment we take only the first for each network */
	let publicNetwork = {}
	let privateNetwork = {}
	Object.keys(networks).map(key => networks[key].private ? privateNetwork = networks[key] : publicNetwork = networks[key])

	let privateNetworkVotesAvailable = 1
	const privateNetworkVotes = privateNetwork.votes ? privateNetwork.votes : []
	if (privateNetwork.votes && privateNetwork.votes.length === 1) privateNetworkVotesAvailable = 0

	return (
		<Col xs={12}>
			<Cards.CardCountdownNotVerified/>
			<Cards.CardFeedsPreview/>
			<Cards.CardNetwork
				network={publicNetwork}
				goal={user.goal}
			/>
			<Cards.CardPrivateNetwork
				votes={privateNetworkVotes}
				votesAvailable={privateNetworkVotesAvailable}
			/>
			<Cards.CardRankLeaderboard/>
			<Cards.CardMonitorInvites/>
			<Cards.CardBalance/>

		</Col>
	)
}

export default Home
