import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import { SAVE_USER_DATA } from '@Store/user/actionTypes'
import { SAVE_NETWORK_DATA, SAVE_NETWORK_DATA_SAGA } from '@Store/network/actionTypes'
import { loadUserData, saveUserDataSaga } from '@Store/user/actions'
import { getHeaders, handleError } from '@Store/helpers'
import {
	changeVoteErrorAlreadyVoted,
	changeVoteErrorTooManyVotes,
	changeVoteErrorVoteAlreadyClaimed,
	changeVoteErrorVoteChangeTooEarly,
	changeVoteErrorVoteCountNotFound,
	changeVoteSuccess,
	saveInviteToVoteLink
} from '@Store/votes/actions'
import { CHANGE_VOTE, CLAIM_VOTE, GET_INVITE_TO_VOTE_LINK } from '@Store/votes/actionTypes'
import axios from 'axios'
import { loadAllNetworksData, saveNetworkDataSaga, toggleModal, updateVoteRemoteData } from '@Store/actions'
import { MODAL_LINK_INVITE_TO_VOTE, MODAL_VOTE, MODAL_VOTE_UNLOCKED } from '@Modals/ModalInfos/modals'
import { LOADED_REMOTE_DATA } from '@Store/remoteStorage/actionTypes'
import _ from 'lodash'
import {
	LOCKED_HIRED_LOST,
	LOCKED_NOT_REGISTERED,
	LOCKED_REGISTERED,
	SECURED_HIRED,
	SECURED_NOT_HIRED, SECURED_NOT_REGISTERED,
	UNLOCKED_HIRED_LOST,
	UNLOCKED_NOT_REGISTERED,
	UNLOCKED_REGISTERED, UNLOCKED_VOTE_AGAIN
} from '@Molecules/Vote/voteStatusses'

const getStateUser = (state) => state.User.user
const getStateNetworks = (state) => state.Network.networks
const getRemoteStorage = (state) => state.RemoteStorage.remoteStorage

function calcRewards (networkId, rewardVotes, groupGoals, participants, rewardAmbassador) {
	let reward = {
		current: 0,
		expected: 0,
		expectedBonus: 0,
		onInvite: 0,
	}
	let rewardVotesNew = []

	rewardVotes.map((vote) => {

		vote.groupGoal = {}
		if (vote.network === networkId) {
			/* Add group goals */
			vote.groupGoal.goal1 = groupGoals[0].achieved && groupGoals[0].to <= participants ? groupGoals[0].rewardPerVote : 0
			vote.groupGoal.goal2 = groupGoals[1].achieved && groupGoals[1].to <= participants ? groupGoals[1].rewardPerVote : 0
			vote.groupGoal.goal3 = groupGoals[2].achieved && groupGoals[2].to <= participants ? groupGoals[2].rewardPerVote : 0
			vote.groupGoal.goal4 = groupGoals[3].achieved && groupGoals[3].to <= participants ? groupGoals[3].rewardPerVote : 0
		}

		vote.totalReward =
			vote.baseReward +
			vote.inviterReward +
			vote.joinBonusReward +
			vote.newJoinerBonusReward +
			(vote.groupGoal.goal1 ?? 0) +
			(vote.groupGoal.goal2 ?? 0) +
			(vote.groupGoal.goal3 ?? 0) +
			(vote.groupGoal.goal4 ?? 0)

		rewardVotesNew.push(vote)
		if (vote.active && vote.claimed) {
			reward.current += vote.totalReward
			reward.expectedBonus += vote.totalReward
			reward.expected += vote.totalReward
		} else {
			reward.expectedBonus += vote.totalReward
			reward.expected += vote.totalReward - vote.newJoinerBonusReward
		}
	})

	reward.current += rewardAmbassador.amountReward

	return { reward, rewardVotes: rewardVotesNew }
}

function buildVotesData (votes, votesReward, numberVerifiedInvites) {
	let newVotes = []
	votesReward.map(v => {
		votes.map(vr => {
			if (v.id === vr.id) {
				const vote = {
					...vr,
					...v,
					unlocked: vr.numberVote -1 <= numberVerifiedInvites,
					status: null
				}

				if (vote.claimed && !vote.verified) vote.status = SECURED_NOT_REGISTERED
				else if (vote.claimed && vote.getHired) vote.status = SECURED_HIRED
				else if (vote.claimed && !vote.getHired) vote.status = SECURED_NOT_HIRED

				else if (!vote.claimed && !vote.unlocked && vote.getHired) vote.status = LOCKED_HIRED_LOST
				else if (!vote.claimed && !vote.unlocked && !vote.verified) vote.status = LOCKED_NOT_REGISTERED
				else if (!vote.claimed && !vote.unlocked && vote.verified) vote.status = LOCKED_REGISTERED

				else if (!vote.claimed && vote.unlocked && vote.getHired) vote.status = UNLOCKED_HIRED_LOST
				else if (!vote.claimed && vote.unlocked && !vote.verified) vote.status = UNLOCKED_NOT_REGISTERED
				else if (!vote.claimed && vote.unlocked && vote.verified) vote.status = UNLOCKED_REGISTERED

				/*
				TODO: add condition when a vote has not been redeemed yet
				 */

				newVotes.push(vote)
			}
		})
	})
	// reorder newVotes based on the numberVote property
	newVotes = _.orderBy(newVotes, ['numberVote'], ['asc'])

	return newVotes
}

function * buildVotes ({ type, payload }) {
	try {
		let dataUser = _.cloneDeep(yield select(getStateUser))
		let dataNetworks = _.cloneDeep(yield select(getStateNetworks))
		/* State has not been updated, we need to do it here */
		switch (type) {
			case SAVE_NETWORK_DATA:
				const prevDataNetwork = dataNetworks[payload.networkId] || {}
				dataNetworks = {
					...dataNetworks,
					[payload.networkId]: {
						...prevDataNetwork,
						...payload.data
					}
				}
				break
			case SAVE_USER_DATA:
				dataUser = {
					...dataUser,
					...payload
				}
				break
			default:
				return
		}

		if (
			!dataNetworks ||
			!dataUser ||
			!dataUser.rewardVotes ||
			!dataUser.rewardAmbassador ||
			!dataUser.referrals
		) return null

		for (let key of Object.keys(dataNetworks)) {
			if (!dataNetworks[key].hasOwnProperty('private')) continue
			/* Private Network */
			if (dataNetworks[key].private) {
				const votes = buildVotesData(dataNetworks[key].votes, dataUser.rewardVotes, dataUser.referrals?.numberVerifiedAccounts || 0)
				const newNetworkData = {
					...dataNetworks[key],
					votes
				}
				yield put(saveNetworkDataSaga(dataNetworks[key].id, newNetworkData))
			} else {
				/* University Network */
				const { reward, rewardVotes } = calcRewards(key, dataUser.rewardVotes, dataNetworks[key].groupGoals, dataNetworks[key].participants.length, dataUser.rewardAmbassador)
				dataUser.reward = reward
				dataUser.rewardVotes = rewardVotes

				const votes = buildVotesData(dataNetworks[key].votes, dataUser.rewardVotes, dataUser.referrals?.numberVerifiedAccounts || 0)
				const newNetworkData = {
					...dataNetworks[key],
					votes
				}

				yield all([
					put(saveNetworkDataSaga(dataNetworks[key].id, newNetworkData)),
					put(saveUserDataSaga(dataUser))
				])
			}
		}
	} catch (error) { handleError(error) }
}

function * changeVote ({ payload }) {
	try {
		yield call(() => axios.post(`${process.env.REACT_APP_ENDPOINT_API_USER}/v1/votes/${payload.id}/change`, { fullName: payload.vote }, getHeaders()))
		yield all([
			put(loadAllNetworksData()),
			put(loadUserData()),
			put(changeVoteSuccess())
		])
	} catch (error) {
		const errorResponse = error.response?.data?.fields?.vote?.errors[0]
		if (errorResponse) {
			switch (errorResponse.code) {
				case 'VOTE_ALREADY_CLAIMED':
					yield put(changeVoteErrorVoteAlreadyClaimed())
					break
				case 'VOTE_COUNT_NOT_FOUND':
					yield put(changeVoteErrorVoteCountNotFound())
					break
				case 'VOTE_CHANGE_TOO_EARLY':
					yield put(changeVoteErrorVoteChangeTooEarly())
					break
				case 'TOO_MANY_VOTES':
					yield put(changeVoteErrorTooManyVotes())
					break
				case 'ALREADY_VOTED':
					yield put(changeVoteErrorAlreadyVoted())
					break
			}
		}
		handleError(error)
	}
}

function * callClaimVote ({ payload }) {
	try {
		yield call(() => axios.post(`${process.env.REACT_APP_ENDPOINT_API_USER}/v1/votes/${payload.id}/claim`, {}, getHeaders()))
		/* Update data */
		yield all([
			put(loadUserData()),
			put(loadAllNetworksData()),
		])
	} catch (error) {
		const errorResponse = error.response?.data?.fields?.vote?.errors[0]
		if (errorResponse) {
			switch (errorResponse.code) {
				case 'VOTE_ALREADY_CLAIMED':
				case 'VOTE_COUNT_NOT_FOUND':
					yield put(loadAllNetworksData())
					break
				case 'VOTE_CLAIMED_TOO_LATE':
					yield put(updateVoteRemoteData(payload.id, UNLOCKED_VOTE_AGAIN, true))
					break
			}
		}
		handleError(error)
	}
}

function * checkNewVoteUnlocked () {
	try {
		/* Check if there are a new vote unlocked and open modal */
		const dataUser = _.cloneDeep(yield select(getStateUser))
		const dataNetworks = _.cloneDeep(yield select(getStateNetworks))
		const remoteStorage = _.cloneDeep(yield select(getRemoteStorage))

		/* Skip until initialized all variables */
		if (!dataNetworks || !remoteStorage || !remoteStorage.votesData) return

		/* Skip if user is not verified */
		if (!dataUser.verified) return

		/* Skip until user has closed first vote */
		if (!remoteStorage.modalFirstVote) return

		const newVoteUnlocked = []
		const newVoteClaimed = []
		/* Loop through networks */
		for (const key of Object.keys(dataNetworks)) {
			/* Loop through networks votes */
			dataNetworks[key].votes.map(v => {
				/* If remoteStorage has this vote's data */
				if (remoteStorage.votesData.hasOwnProperty(v.id)) {
					/* If those two objects has one or more different values, add as newVoteUnlocked */
					const rv = remoteStorage.votesData[v.id]
					if (v.active && !rv.active) {
						newVoteUnlocked.push(v)
					} else if (v.active && rv.active && v.claimed && !rv.claimed) {
						newVoteClaimed.push(v)
					}
				}
			})
		}

		if (newVoteUnlocked[0]) {
			yield put(toggleModal(MODAL_VOTE_UNLOCKED, { data: newVoteUnlocked[0] }))
		} else if (newVoteClaimed[0]) {
			yield put(toggleModal(MODAL_VOTE, { data: newVoteClaimed[0] }))
		}
	} catch (error) { handleError(error)}
}

function * getLinkInviteToVote () {
	try {
		const { data } = yield call(() => axios.post(`${process.env.REACT_APP_ENDPOINT_API_USER}/v1/invites/external`, {}, getHeaders()))

		yield all([
			put(saveInviteToVoteLink(data.code)),
			put(toggleModal(MODAL_LINK_INVITE_TO_VOTE))
		])
	} catch (error) { handleError(error) }
}

function * VotesSaga () {
	yield all([
		/* Each time user and network get updated, recalculate votes and rewards */
		takeEvery(SAVE_USER_DATA, buildVotes),
		takeEvery(SAVE_NETWORK_DATA, buildVotes),

		takeEvery(CHANGE_VOTE, changeVote),
		takeEvery(CLAIM_VOTE, callClaimVote),

		/* Check if new vote has been unlocked */
		takeEvery(SAVE_NETWORK_DATA_SAGA, checkNewVoteUnlocked),
		takeEvery(LOADED_REMOTE_DATA, checkNewVoteUnlocked),

		takeEvery(GET_INVITE_TO_VOTE_LINK, getLinkInviteToVote)
	])
}

export default VotesSaga
