import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { Card, CardBody } from 'reactstrap'
import _ from 'lodash'
import { formatCurrency } from '@Helpers/number_helper'
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined'
import ShareRoundedIcon from '@mui/icons-material/ShareRounded'
import PropTypes from 'prop-types'
import Badge from '@Atoms/badge'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Info from '@Icons/Info'
import { claimVote, toggleModal } from '@Store/actions'
import JoinerBonusLine from '@Atoms/JoinerBonusLine'
import { MODAL_AMBASSADOR } from '@Modals/ModalInfos/modals'
import { MODAL_COLLECTIVE_GOAL, MODAL_REFERRAL } from '@Modals/ModalPages/modals'
import ButtonShare from '@Atoms/ButtonShare'
import { Trans } from 'react-i18next'
import Button from '@Atoms/Button'

class CardRewardDetail extends React.Component {

	constructor (props) {
		super(props)
		this.state = {
			user: props.user,
			network: props.network,
			className: props.className,
			votesAccordion: {},
			ambassadorAccordion: false,
			votes: props.votes,
			dateExpire: props.dateExpire,
		}
	}

	componentDidUpdate (prevProps, prevState, snapshot) {
		if (!_.isEqual(this.props.user, prevProps.user)) this.setState({ user: this.props.user })
		if (!_.isEqual(this.props.network, prevProps.network)) this.setState({ network: this.props.network })
		if (!_.isEqual(this.props.className, prevProps.className)) this.setState({ className: this.props.className })
		if (!_.isEqual(this.props.votes, prevProps.votes)) this.setState({ votes: this.props.votes })
		if (!_.isEqual(this.props.dateExpire, prevProps.dateExpire)) this.setState({ dateExpire: this.props.dateExpire })
	}

	render () {

		const { user, votesAccordion, votes, network, ambassadorAccordion } = this.state

		const { type } = this.props

		if (
			!user ||
			!votes ||
			!network ||
			!network.university
		) return null

		let colorBorder = ''
		switch (type) {
			case 'claimed':

				break
			case 'unlocked':
				colorBorder = 'bordered-success'
				break
			case 'locked':
				colorBorder = 'bordered-red'
				break
		}

		return (
			<Card className={`d-flex flex-direction-row align-items-center card-reward-detail ${colorBorder}`}>
				<CardBody className="w-100">
					{type === 'locked' && (
						<JoinerBonusLine className="text-primary" bold/>
					)}
					<table className="w-100 table-rewards">
						<thead>
						<tr>
							<th className="pb-2 caption-small text-start"/>
							<th width="60px" className="pb-2 caption-small"/>
							<th width="60px"/>
							<th width="60px"/>
							<th width="40px"/>
						</tr>
						</thead>
						<tbody>
						<tr>
							<td className="pb-2 caption-small text-start"><Trans i18nKey="cardRewardDetail.votes"/></td>
							<td colSpan="3" className="pb-2 caption-small"><Trans i18nKey="cardRewardDetail.rewards"/></td>
							<td/>
						</tr>

						{/* VOTES */}
						{votes.map((vote, k) => (
							<React.Fragment key={k}>
								<tr className="caption clickable" onClick={() => this.setState({ votesAccordion: { [k]: !votesAccordion[k] } })}>
									<td colSpan="3" className="py-2">{vote.name}</td>
									<td className={`font-weight-bold text-success ${votesAccordion[k] ? 'border-bottom-total' : ''}`}>
										{vote.active ? (
											<React.Fragment>
												{formatCurrency(vote.totalReward, user.currency)}
											</React.Fragment>
										) : (
											<div className="caption d-flex align-items-center justify-content-end">
												<LockOutlinedIcon className="caption text-gray-normal"/>
												&nbsp;
												<span className="text-primary font-weight-bold">{formatCurrency(vote.totalReward, user.currency)}</span>
											</div>
										)}
									</td>
									<td>
										{votesAccordion[k] ? (
											<i className="fas fa-angle-up fa-1-5x"/>
										) : (
											<i className="fas fa-angle-down fa-1-5x"/>
										)}
									</td>
								</tr>
								{/* DETAILS */}
								{votesAccordion[k] && (
									<React.Fragment>
										<RowTable label={<Trans i18nKey="cardRewardDetail.baseReward"/>} value={vote.baseReward} currency={user.currency}/>
										<RowTable label={<Trans i18nKey="cardRewardDetail.joiningBonus"/>} value={vote.joinBonusReward} currency={user.currency}/>
										<RowTable
											label={<Trans i18nKey="cardRewardDetail.newJoinerBonus"/>}
											value={vote.newJoinerBonusReward}
											currency={user.currency}
											valueDefault={100}
											icon={<AccessTimeOutlinedIcon className="text-primary caption"/>}
										/>
										{!_.isEmpty(vote.groupGoal) && (
											<React.Fragment>
												<RowTable
													label={<Trans i18nKey="cardRewardDetail.groupGoal" values={{ university: network.university.name }}/>}
													underlined
													value={null}
													onClick={() => this.props.toggleModal(MODAL_COLLECTIVE_GOAL)}
												/>
												<RowTable
													onClick={() => this.props.toggleModal(MODAL_COLLECTIVE_GOAL)}
													label={<>
														<Badge color="diamond" small/>
														&nbsp;
														<Trans i18nKey="cardRewardDetail.participants" count={5}/>
													</>}
													value={vote.groupGoal.goal1}
													valueDefault={network.groupGoals[0].granted ? network.groupGoals[0].rewardPerVote : null}
													currency={user.currency}
													offset
													icon={<Badge color="diamond" small center/>}/>
												<RowTable
													onClick={() => this.props.toggleModal(MODAL_COLLECTIVE_GOAL)}
													label={<>
														<Badge color="gold" small/>
														&nbsp;
														<Trans i18nKey="cardRewardDetail.participants" count={12}/>
													</>}
													value={vote.groupGoal.goal2}
													valueDefault={network.groupGoals[1].granted ? network.groupGoals[1].rewardPerVote : null}
													currency={user.currency} offset icon={<Badge color="gold" small center/>}/>
												<RowTable
													onClick={() => this.props.toggleModal(MODAL_COLLECTIVE_GOAL)}
													label={<>
														<Badge color="silver" small/>
														&nbsp;
														<Trans i18nKey="cardRewardDetail.participants" count={25}/>
													</>}
													value={vote.groupGoal.goal3}
													valueDefault={network.groupGoals[2].granted ? network.groupGoals[2].rewardPerVote : null}
													currency={user.currency}
													offset
													icon={<Badge color="silver" small center/>}/>
												<RowTable
													onClick={() => this.props.toggleModal(MODAL_COLLECTIVE_GOAL)}
													label={<>
														<Badge color="bronze" small/>
														&nbsp;
														<Trans i18nKey="cardRewardDetail.participants" count={50}/>
													</>}
													value={vote.groupGoal.goal4}
													valueDefault={network.groupGoals[3].granted ? network.groupGoals[3].rewardPerVote : null}
													currency={user.currency}
													offset
													icon={<Badge color="bronze" small center/>}/>
											</React.Fragment>
										)}
										<RowTable
											label={<Trans i18nKey="cardRewardDetail.invitationBonus"/>}
											value={vote.inviterReward}
											valueDefault={15}
											currency={user.currency}
											underlined
											icon={<ShareRoundedIcon className="text-gray-dark caption"/>}
											className="pb-2"
											onClick={() => this.props.toggleModal(MODAL_REFERRAL)}
										/>
									</React.Fragment>
								)}
								{/* /DETAILS */}
							</React.Fragment>
						))}
						{/* /VOTES */}

						{/* AMBASSADOR LEVEL */}
						{this.props.showAmbassador && (
							<React.Fragment>
								<tr>
									<td colSpan="4" className="caption-small pt-2" style={{ borderTop: '1px solid #dedede' }}>
										<Trans i18nKey="cardRewardDetail.ambassadorLevel"/>
										<span className="float-end"><Trans i18nKey="cardRewardDetail.invitesReward"/></span>
									</td>
									<td/>
								</tr>
								<tr className="caption">
									<td colSpan="3">
										<span className="clickable" onClick={() => this.setState({ ambassadorAccordion: !ambassadorAccordion })}>
											<Trans i18nKey={`ambassadorLevel.${user.rewardAmbassador.label}`}/>&nbsp;
											({user.rewardAmbassador.sharePercentage}%)
										</span>
										<Info className="clickable" style={{ padding: '0 10px' }} onClick={() => this.props.toggleModal(MODAL_AMBASSADOR)}/>
									</td>
									<td
										className="text-success font-weight-bold clickable"
										onClick={() => this.setState({ ambassadorAccordion: !ambassadorAccordion })}
									>
										{formatCurrency(user.rewardAmbassador.amountReward, user.currency)}
									</td>
									<td className="clickable" onClick={() => this.setState({ ambassadorAccordion: !ambassadorAccordion })}>
										{ambassadorAccordion ? (
											<i className="fas fa-angle-up fa-1-5x"/>
										) : (
											<i className="fas fa-angle-down fa-1-5x"/>
										)}
									</td>
								</tr>
								{/* DETAILS */}
								{ambassadorAccordion && (
									<React.Fragment>
										<tr className="caption-small">
											<td colSpan="3"><Trans i18nKey="cardRewardDetail.inviteesReward"/></td>
											<td className="text-success">{formatCurrency(user.rewardAmbassador.amountRewardReferred, user.currency)}</td>
											<td/>
										</tr>
										<tr className="caption-small">
											<td colSpan="3"><Trans i18nKey="cardRewardDetail.yourAmbassadorLevel"/></td>
											<td className="text-success">{user.rewardAmbassador.sharePercentage}%</td>
											<td className="clickable" onClick={() => this.props.toggleModal(MODAL_AMBASSADOR)}><Info/></td>
										</tr>
									</React.Fragment>
								)}
								{/* /DETAILS */}
							</React.Fragment>
						)}
						{this.props.type === 'unlocked' && (
							<React.Fragment>
								<tr>
									<td colSpan="4">
										<Button
											className="float-end btn-rounded btn-sm px-4"
											fontSize={12}
											color="success"
											onClick={() => {this.props.claimVote(votes[0])}}
										>
											<Trans i18nKey="cardRewardDetail.claim" values={{ amount: formatCurrency(votes[0].totalReward, user.currency) }}/>
										</Button>
									</td>
									<td/>
								</tr>
							</React.Fragment>
						)}
						{this.props.addButtonInvite && (
							<React.Fragment>
								<tr>
									<td colSpan="4">
										<ButtonShare className="float-end btn-rounded btn-sm px-4" fontSize={12} color="primary" type="invite"/>
									</td>
									<td/>
								</tr>
							</React.Fragment>
						)}
						{/* /AMBASSADOR LEVEL */}

						</tbody>
					</table>
				</CardBody>
			</Card>
		)
	}
}

const RowTable = props => {

	let value = props.value

	let valueColor = ''
	switch (props.valueColor) {
		case 'success':
			valueColor = 'text-success'
			break
		case 'primary':
			valueColor = 'text-primary'
			break
		case 'gray':
			valueColor = 'text-gray'
			break
		default:
			valueColor = props.value === 0 ? 'text-primary' : 'text-success'
			break
	}

	/* If there are an amount default, print it gray with line-through */
	if (props.valueDefault && props.value === 0) {
		value = props.valueDefault
		valueColor = 'text-gray text-decoration-line-through'
	}
	return (
		<React.Fragment>
			<tr className={`caption-small clickable ${props.offset ? 'detail-2' : 'detail'}`} onClick={props.onClick}>
				<td colSpan={props.value !== null ? 3 : 4} className={props.className} onClick={props.onClick}>
					<li className={`${props.underlined && 'text-decoration-underline'}`}>
						<div className="d-inline-flex">
							{props.label}
						</div>
					</li>
				</td>
				{value !== null && (
					<td className={valueColor}>{formatCurrency(value, props.currency)}</td>
				)}
				{props.icon ? (
					<td className="text-center">{props.icon}</td>
				) : (
					<td/>
				)}
			</tr>
		</React.Fragment>
	)
}

RowTable.propTypes = {
	label: PropTypes.any.isRequired,
	value: PropTypes.number,
	currency: PropTypes.string,
	underlined: PropTypes.bool,
	icon: PropTypes.any,
	offset: PropTypes.bool,
	onClick: PropTypes.func,
	valueColor: PropTypes.oneOf(['success', 'primary', 'gray']),
	valueDefault: PropTypes.number
}

const mapStateToProps = state => {
	return {
		user: state.User.user,
		network: state.Network.network,
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			toggleModal,
			claimVote,
			// add other watcher sagas to this object to map them to props
		},
		dispatch
	)
}

CardRewardDetail.propTypes = {
	votes: PropTypes.array.isRequired,
	showAmbassador: PropTypes.bool,
	addButtonInvite: PropTypes.bool,
	type: PropTypes.oneOf(['claimed', 'unlocked', 'locked']).isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CardRewardDetail))
