import { LOADED_USER_JOB, SAVE_LIST_JOBS, SAVE_USER_JOB } from '@Store/jobs/actionTypes'

const INIT_STATE = {
	listJobs: [],
	userJob: {
		interestedInternship: false,
		interestedPartTime: false,
		interestedFullTime: false,
		graduation: {
			month: null,
			year: null
		}
	}
}

const Jobs = (state = INIT_STATE, action) => {
	switch (action.type) {
		case SAVE_LIST_JOBS:
			return {
				...state,
				listJobs: action.payload
			}
		case LOADED_USER_JOB:
			return {
				...state,
				userJob: {
					...action.payload
				}
			}
		case SAVE_USER_JOB:
			return {
				...state,
				userJob: {
					interestedInternship: action.payload.interestedInternship,
					interestedPartTime: action.payload.interestedPartTime,
					interestedFullTime: action.payload.interestedFullTime,
					graduation: {
						month: action.payload.graduationMonth || state.userJob.graduation.month,
						year: action.payload.graduationYear || state.userJob.graduation.year
					}
				}
			}
		default:
			return state
	}
}

export default Jobs
