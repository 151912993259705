import React, { useEffect, useState } from 'react'
import Modal from '@Modals/Modal'
import Cards from '@Cards'
import { Col, Row } from 'reactstrap'
import AskNotification from '@Atoms/askNotification'
import ButtonShare from '@Atoms/ButtonShare'
import { MODAL_REWARDS } from '@Modals/ModalPages/modals'
import { MODAL_WITHDRAW } from '@Modals/ModalInfos/modals'
import { Trans } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { toggleModal } from '@Store/actions'

const ModalRewards = props => {

	const dispatch = useDispatch()

	const userData = useSelector(state => state.User.user)
	const networkData = useSelector(state => state.Network.network)

	const [user, setUser] = useState(userData)
	const [network, setNetwork] = useState(networkData)

	useEffect(() => setUser(userData), [userData])
	useEffect(() => setNetwork(networkData), [networkData])

	if (
		!user ||
		!user.reward ||
		!network ||
		!network.university ||
		!network.faculty ||
		!network.participants
	) return null

	const rewardClaimed = user.rewardVotes.filter((o) => o.active && o.claimed)
	const rewardUnlocked = user.rewardVotes.filter((o) => o.active && !o.claimed)
	const rewardLocked = user.rewardVotes.filter((o) => !o.active)

	return (
		<Modal type="modal-mobile-full" name={MODAL_REWARDS}>
			<div className="title-big">
				<Trans i18nKey="modalRewards.title"/>
			</div>
			<h3 className="title">
				<Trans i18nKey="modalRewards.subtitle"/>
			</h3>
			<Row>
				<Col xs={6}>
					<Cards.CardRewardUnlocked/>
				</Col>
				<Col xs={6}>
					<Cards.CardRewardLocked/>
				</Col>
			</Row>
			<div className="link mb-3" onClick={() => dispatch(toggleModal(MODAL_WITHDRAW))}>
				<div className="btn btn-rounded btn-primary w-75">
					<Trans i18nKey="modalRewards.withdraw"/>
				</div>
			</div>
			<AskNotification>
				<div className="link mb-2"><Trans i18nKey="modalRewards.notify"/></div>
			</AskNotification>
			{/* REWARD CLAIMED */}
			{rewardClaimed.length > 0 && (
				<React.Fragment>
					<h3 className="title mb-2"><Trans i18nKey="modalRewards.rewardsDetails"/></h3>
					<Cards.CardRewardDetail votes={rewardClaimed} showAmbassador addButtonInvite type="claimed"/>
				</React.Fragment>
			)}
			{/* REWARD UNLOCKED */}
			{rewardUnlocked.length > 0 && (
				<React.Fragment>
					<h3 className="title mb-2"><Trans i18nKey="modalRewards.rewardsToClaim"/></h3>
					<Cards.CardRewardDetail votes={rewardUnlocked} type="unlocked"/>
				</React.Fragment>
			)}
			{/* REWARD LOCKED */}
			{rewardLocked.length > 0 && (
				<React.Fragment>
					<h3 className="title mb-2"><Trans i18nKey="modalRewards.rewardsToUnlock"/></h3>
					<Cards.CardRewardDetail votes={rewardLocked} type="locked"/>
				</React.Fragment>
			)}
			<div className="px-3">
				<ButtonShare color="primary" type="inviteFriend" fullwidth/>
			</div>
		</Modal>
	)
}

export default ModalRewards
