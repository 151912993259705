import React, { useEffect, useState } from 'react'
import { Card, CardBody } from 'reactstrap'
import InputBox from '@Molecules/InputBox'
import Input from '@Atoms/Input'
import { useForm } from 'react-hook-form'
import Button from '@Atoms/Button'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { changeVote } from '@Store/votes/actions'
import { Trans, useTranslation } from 'react-i18next'

const VoteAgain = props => {

	const { vote, user, votes } = props

	const changeVoteErrorData = useSelector(state => state.Votes.changeVoteError)
	const [changeVoteError, setChangeVoteError] = useState(changeVoteErrorData)
	useEffect(() => setChangeVoteError(changeVoteErrorData), [changeVoteErrorData])

	if (!vote.id) return null

	const dispatch = useDispatch()
	const { t } = useTranslation()

	const onSubmit = (data) => {
		if (data.newVote) dispatch(changeVote({ id: vote.id, vote: data.newVote }))
	}

	const {
		register,
		handleSubmit,
		formState: { errors },
		formState,
		setValue,
		watch
	} = useForm({
		mode: 'onBlur',
		reValidateMode: 'onChange',
	})

	return (
		<Card id="vote-again">
			<CardBody>
				<div className="h5"><Trans i18nKey="FlowUnlockVote.VOTE_AGAIN.title" /></div>
				<div className="h8"><Trans i18nKey="FlowUnlockVote.VOTE_AGAIN.subtitle" /></div>
				<div className="mb-4"><Trans i18nKey="FlowUnlockVote.VOTE_AGAIN.text" /></div>
				<div className="table-votes">
					<div className="table-row th overline">
						<div className="table-name"><Trans i18nKey="FlowUnlockVote.VOTE_AGAIN.currentVotes" /></div>
						<div className="table-availability"><Trans i18nKey="FlowUnlockVote.VOTE_AGAIN.availability" /></div>
					</div>
					{votes.map((v, k) => (
						<div key={k} className={`table-row bg ${v.id === vote.id ? 'current' : ''}`}>
							<div className="table-name">{v.vote}</div>
							{v.id === vote.id && (
								<div className="table-availability">0/3</div>
							)}
							{v.id !== vote.id && v.claimed && (
								<div className="table-availability">✓</div>
							)}
							{v.id !== vote.id && !v.claimed && (
								<div className="table-availability">{3 - v.count === 0 ? 1 : 3 - v.count}/3</div>
							)}
						</div>
					))}
				</div>
				<hr className="my-4"/>
				<div className="mb-2"><Trans i18nKey="FlowUnlockVote.VOTE_AGAIN.sentence1" values={{name: vote.vote}} /></div>
				<div className="mb-4"><Trans i18nKey="FlowUnlockVote.VOTE_AGAIN.sentence2"/></div>
				<div className="">
					<div className="text-uppercase overline"><Trans i18nKey="FlowUnlockVote.VOTE_AGAIN.replaceVote" /></div>
					<form onSubmit={handleSubmit(onSubmit)} className="container-form">
						<InputBox
							errors={errors.newVote}
							touched={formState.touchedFields.newVote || !!watch().newVote}
						>
							<Input
								required
								register={register}
								name="newVote"
								errors={errors.newVote}
								touched={formState.touchedFields.newVote || !!watch().newVote}
								placeholder=""
								inputErrorMessage={t('FlowUnlockVote.VOTE_AGAIN.inputErrorMessage')}
								registerOptions={{ required: true, validate: async (value) => { return /^.+\s.+$/.test(value)} }}
								type="text"
							/>
						</InputBox>
						<div className="text-center">
							{changeVoteError && (
								<div className="text-danger mb-2">
									<Trans i18nKey={`FlowUnlockVote.VOTE_AGAIN.error.${changeVoteError}`}/>
								</div>
							)}
							<Button color="primary" className="w-75" type="submit">
								<Trans i18nKey="FlowUnlockVote.VOTE_AGAIN.cta"/>
							</Button>
						</div>
					</form>
				</div>
			</CardBody>
		</Card>
	)
}

export default VoteAgain
