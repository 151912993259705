import { all, fork } from 'redux-saga/effects'

//public
import AccountSaga from '@Store/auth/register/saga'
import AuthSaga from '@Store/auth/login/saga'
import forgetPasswordSaga from '@Store/auth/forgetPassword/saga'
import resetPasswordSaga from '@Store/auth/resetPassword/saga'

import SystemSaga from '@Store/system/saga'

import GlobalSaga from '@Store/global/saga'
import RemoteStorageSaga from '@Store/remoteStorage/saga'
import ShareSaga from '@Store/share/saga'
import PushNotificationsSaga from '@Store/pushNotifications/saga'
import InternalNotificationsSaga from '@Store/internalNotifications/saga'
import VotesSaga from '@Store/votes/saga'
import UserSaga from '@Store/user/saga'
import NetworkSaga from '@Store/network/saga'
import TemplateSaga from '@Store/template/saga'
import BalanceSaga from '@Store/balance/saga'
import JobsSaga from '@Store/jobs/saga'
import FeedsSaga from '@Store/feeds/saga'

export default function * rootSaga () {
	yield all([
		//public
		fork(AccountSaga),
		fork(AuthSaga),
		fork(forgetPasswordSaga),
		fork(resetPasswordSaga),

		fork(SystemSaga),

		fork(GlobalSaga),
		fork(RemoteStorageSaga),
		fork(ShareSaga),
		fork(PushNotificationsSaga),
		fork(InternalNotificationsSaga),
		fork(VotesSaga),
		fork(UserSaga),
		fork(NetworkSaga),
		fork(TemplateSaga),
		fork(BalanceSaga),
		fork(JobsSaga),
		fork(FeedsSaga),
	])
}
