import React, { useEffect, useState } from 'react'
import { Trans } from 'react-i18next'
import Modal from '@Modals/Modal'
import Cards from '@Cards'
import ButtonShare from '@Atoms/ButtonShare'
import { MODAL_YOU_CAN_HELP } from '@Modals/ModalPages/modals'
import { useSelector } from 'react-redux'

const ModalYouCanHelp = props => {

	const userData = useSelector(state => state.User.user)

	const [user, setUser] = useState(userData)

	useEffect(() => setUser(userData), [userData])

	if (
		!user ||
		!user.inviteCode
	) return null

	return (
		<Modal
			type="modal-mobile-full"
			name={MODAL_YOU_CAN_HELP}
			stickyAction={(
				<ButtonShare
					color="primary"
					className="w-75"
					type="inviteShort"
				/>
			)}
		>
			<div className="title-big"><Trans i18nKey="modalYouCanHelp.title"/></div>
			<Cards.CardText
				title={(<Trans i18nKey="cardVoteIsWorth.title"/>)}
				text={(<Trans i18nKey="cardVoteIsWorth.text"/>)}
			/>
			<Cards.CardMeritocracy/>
			<Cards.CardSocietyImpact/>
			<Cards.CardText
				title={(<Trans i18nKey="cardWirakiValues.title"/>)}
				text={(<Trans i18nKey="cardWirakiValues.text" components={{ a: <a/> }}/>)}
			/>
		</Modal>
	)
}

export default ModalYouCanHelp
