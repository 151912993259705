import ModalCollectiveGoal from '@Modals/ModalPages/ModalCollectiveGoal'
import ModalLeaderboard from '@Modals/ModalPages/ModalLeaderboard'
import ModalParticipants from '@Modals/ModalPages/ModalParticipants'
import ModalReferral from '@Modals/ModalPages/ModalReferral'
import ModalRewards from '@Modals/ModalPages/ModalRewards'
import ModalYouCanHelp from '@Modals/ModalPages/ModalYouCanHelp'

export default {
	ModalCollectiveGoal,
	ModalLeaderboard,
	ModalParticipants,
	ModalReferral,
	ModalRewards,
	ModalYouCanHelp
}
