import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { changePage, toggleModal } from '@Store/actions'
import { Trans, withTranslation } from 'react-i18next'
import { Card, CardBody } from 'reactstrap'
import routes from '@Routes/routeConfig'
import InputShare from '@Atoms/share/InputShare'
import { formatCurrency } from '@Helpers/number_helper'
import axios from 'axios'
import Share from '@Atoms/share/Share'
import ShareTelegram from '@Atoms/share/ShareTelegram'
import ShareWhatsapp from '@Atoms/share/ShareWhatsapp'

class ContactMe extends React.Component {

	constructor (props) {
		super(props)
		this.state = {
			user: props.user,
			page: props.page,
			idUser: null,
			type: null,
			referrer: ''
		}
	}

	componentDidMount () {
		/* Keep this condition as we experienced sometimes a multiple call to changePage function */
		if (this.state.page.route !== routes.CONTACT_ME_EMAIL_OPEN.route) this.props.changePage(routes.CONTACT_ME_EMAIL_OPEN)
		const { idUser, referrer, type } = this.props.match.params
		if (idUser.length >= 30) {
			axios.post(`${process.env.REACT_APP_ENDPOINT_API_USER}/v1/public/contacts/jobs`, { user: idUser })
		}
		this.setState({ idUser, referrer, type })
	}

	componentDidUpdate (prevProps, prevState, snapshot) {

	}

	render () {
		let { isAuthorized: isLogged } = this.props
		const { user, type, referrer } = this.state

		if (
			!user
		) return null

		const url = `${process.env.REACT_APP_USER_AREA_URL}${routes.REFER_COLLEAGUE_OPEN.location}/${referrer}/${type}`

		return (
			<React.Fragment>
				<Card>
					<CardBody>
						<div className="h6 mb-4">
							<Trans i18nKey="pageContactMe.title"/>
						</div>
						<div>
							<Trans
								i18nKey="pageContactMe.boxContact"
								components={{
									title: <div className="h8 mb-1"/>,
									text: <div className="body-small"/>,
								}}
							/>
						</div>
					</CardBody>
				</Card>
				<Card>
					<CardBody>
						<div className="">
							<Trans
								i18nKey="pageContactMe.boxShare"
								components={{
									title: <div className="h8 mb-1"/>,
									text: <div className="body-small mb-3"/>
								}}
								values={{
									amount: formatCurrency(200, user.currency)
								}}
							/>
							<div className="container-button-share mb-3">
								<Share customUrl={url} customMessage={null}/>
								<ShareTelegram message={this.props.t('pageContactMe.shareText', {link: url})}/>
								<ShareWhatsapp message={this.props.t('pageContactMe.shareText', {link: url})}/>
							</div>
							<InputShare url={url} className="m-auto"/>
						</div>
					</CardBody>
				</Card>
			</React.Fragment>
		)
	}
}

const mapStateToProps = state => {
	return {
		user: state.User.user,
		page: state.Template.page,
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			changePage,
			toggleModal,
		},
		dispatch
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(ContactMe)))
