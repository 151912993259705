import { all, call, put, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { getHeaders, handleError } from '@Store/helpers'
import { CARD_COLLECTIVE_GOALS, RANK_PRECISION } from '@Constants/constants'
import { addCertificate, saveListParticipants, saveNetworkData, saveUserData } from '@Store/actions'
import { LOAD_ALL_NETWORK_DATA, LOAD_CERTIFICATE, LOAD_LIST_PARTICIPANTS, LOAD_NETWORK_DATA } from '@Store/network/actionTypes'
import { getBadge } from '@Helpers/global'

function * getUniversityData (universityId) {
	try {
		if (universityId) {
			const { data: network } = yield call(() => axios.get(`${process.env.REACT_APP_ENDPOINT_API_USER}/v1/public/info/networks/${universityId}`))
			return network
		}
	} catch (error) { handleError(error) }
}

function * getFacultyData (facultyId) {
	try {
		if (facultyId) {
			const { data: faculty } = yield call(() => axios.get(`${process.env.REACT_APP_ENDPOINT_API_USER}/v1/public/faculties/${facultyId}`))
			return faculty
		}
	} catch (error) { handleError(error) }
}

function * getAllNetworks () {
	try {
		let { data: listNetworks } = yield call(() => axios.get(`${process.env.REACT_APP_ENDPOINT_API_USER}/v1/networks`, getHeaders()))

		for (let n of listNetworks) {
			/* Get data network */
			yield call(() => getNetworkData({ payload: n.id }))
		}
	} catch (error) { handleError(error)}
}

function * getNetworkData ({ payload: networkId }) {
	if (!networkId) return
	try {
		let { data: network } = yield call(() => axios.get(`${process.env.REACT_APP_ENDPOINT_API_USER}/v1/networks/${networkId}`, getHeaders()))

		let universityInfo
		let facultyInfo
		let listHirings
		if (network.private) {
			[universityInfo, facultyInfo, listHirings] = yield all([
				//call(() => getUniversityData(network.network)),
				//call(() => getFacultyData(network.faculty)),
				//call(() => getGoals({ payload: { university: network.network, faculty: network.faculty } })),
				//call(() => getRank({ payload: { university: network.network, faculty: network.faculty } })),
				//call(() => getRankHistory({ payload: { university: network.network, faculty: network.faculty } })),
				//call(() => getLeaderboard({ payload: { university: network.network, faculty: network.faculty } })),
				//call(() => getPrivateNetworkData()),
			])
		} else {
			[universityInfo, facultyInfo, listHirings] = yield all([
				call(() => getUniversityData(network.network)),
				call(() => getFacultyData(network.faculty)),
				call(() => getListHirings({ payload: networkId })),
				call(() => getListParticipants({ payload: networkId })),
				call(() => getGoals({ payload: { networkId } })),
				call(() => getRankWRank({ payload: { networkId } })),
				call(() => getRankVoter({ payload: { networkId } })),
				call(() => getRankHistory({ payload: { networkId, group: network.group } })),
				call(() => getLeaderboard({ payload: { networkId, group: network.group } })),
			])
		}

		network.votes.map((vote, k) => {
			network.votes[k].verified = vote.user && vote.user.verified || false
			delete network.votes[k].user
		})

		// Check if voted colleagues are hired
		network.votes.map((vote, k) => {
			network.votes[k].getHired = false
			listHirings.map((hiring, k1) => {
				listHirings[k1].votedByMe = false
				listHirings[k1].votedByMeClaimed = false
				if(vote.key === hiring.id) {
					network.votes[k].getHired = true
					listHirings[k1].votedByMe = true
					listHirings[k1].votedByMeClaimed = vote.claimed
				}
			})
		})

		network.university = universityInfo
		network.faculty = facultyInfo
		network.listHirings = listHirings
		yield put(saveNetworkData(networkId, network))
	} catch (error) { handleError(error) }
}

function * getListHirings ({ payload: networkId }) {
	if(!networkId) return
	try {
		const { data } = yield call(() => axios.get(`${process.env.REACT_APP_ENDPOINT_API_USER}/v1/networks/${networkId}/hirings`, getHeaders()))

		return data
	} catch (error) { handleError(error) }
}

function * getListParticipants ({ payload: networkId }) {
	if (!networkId) return
	try {

		const { data } = yield call(() => axios.get(`${process.env.REACT_APP_ENDPOINT_API_USER}/v1/networks/${networkId}/people`, getHeaders()))

		/* Associate goal badge */
		data.map((p, k) => {
			let icon = null
			let title = 'Follower'
			if (p.goals[0]) {
				const badge = getBadge(p.goals[0])
				icon = badge.icon
				title = badge.title
			}
			data[k].icon = icon
			data[k].title = title
		})

		yield put(saveListParticipants(networkId, data))
	} catch (error) { handleError(error) }
}

function * getRankVoter ({ payload: { networkId } }) {
	try {
		const { data } = yield call(() => axios.get(`${process.env.REACT_APP_ENDPOINT_API_USER}/v1/networks/${networkId}/voter`, getHeaders()))

		const user = {
			voterRank: {
				votesScore: data.details,
				rankMe: { score: data.score, delta: data.delta, position: data.position },
				rankPrevMe: data.prev,
				rankNextMe: data.next,
			}
		}
		yield put(saveUserData(user))
	} catch (error) {
		handleError(error)
	}
}

function * getRankWRank ({ payload: { networkId } }) {
	try {
		const { data } = yield call(() => axios.get(`${process.env.REACT_APP_ENDPOINT_API_USER}/v1/networks/${networkId}/rank`, getHeaders()))

		const rankInfo = RANK_PRECISION.filter(r => r.from <= data.precision && r.to > data.precision)[0]

		const user = {
			wRank: {
				rankRaw: data.rank === null ? 100 : data.rank * 100,
				rank: data.rank === null ? 100 : Math.round(parseFloat(data.rank) * 100),
				rankLabel: rankInfo ? rankInfo.label : RANK_PRECISION[0].label,
				rankMe: { delta: data.delta, position: data.position },
				rankPrevMe: data.prev,
				rankNextMe: data.next,
			}
		}
		yield put(saveUserData(user))
	} catch (error) {
		handleError(error)
	}
}

function * getRankHistory ({ payload: { group } }) {
	try {
		const { data } = yield call(() => axios.get(`${process.env.REACT_APP_ENDPOINT_API_USER}/v1/me/rank/history?group=${group}&orderDir=asc`, getHeaders()))

		const user = {
			rankHistory: data
		}
		yield put(saveUserData(user))
	} catch (error) {
		handleError(error)
	}
}

function * getGoals ({ payload: { networkId } }) {
	try {
		const { data } = yield call(() => axios.get(`${process.env.REACT_APP_ENDPOINT_API_USER}/v1/networks/${networkId}/goals`, getHeaders()))

		const achievedGoalValue = data.filter(goal => goal.achieved)[0]
		const achievedGoal = {
			icon: null,
			title: 'Follower'
		}
		if (achievedGoalValue) {
			const goalInfo = CARD_COLLECTIVE_GOALS.GOALS.filter(goal => goal.value === achievedGoalValue.to)[0]
			achievedGoal.icon = goalInfo.icon
			achievedGoal.title = goalInfo.title
		}

		yield all([
			put(saveNetworkData(networkId, { groupGoals: data })),
			put(saveUserData({ goal: achievedGoal }))
		])
	} catch (error) { handleError(error) }
}

function * getLeaderboard ({ payload: { networkId, group } }) {
	try {
		const [
			{ data: leaderboardRank },
			{ data: leaderboardVoters }
		] = yield all([
			call(() => axios.get(`${process.env.REACT_APP_ENDPOINT_API_USER}/v1/public/leaderboard/ranks?group=${group}`)),
			call(() => axios.get(`${process.env.REACT_APP_ENDPOINT_API_USER}/v1/public/leaderboard/voters?group=${group}`))
		])

		yield put(saveNetworkData(networkId, {
			leaderboardRank,
			leaderboardVoters,
		}))
	} catch (error) { handleError(error) }
}

function * getCertificate ({ payload }) {
	const { userUrl, docHash } = payload
	try {
		const { data } = yield axios.get(`${process.env.REACT_APP_ENDPOINT_API_USER}/v1/public/certificates/${userUrl}/${docHash}`)

		const [university, faculty] = yield all([
			call(() => getUniversityData(data.network)),
			call(() => getFacultyData(data.faculty))
		])

		yield put(addCertificate(docHash, {
			...data,
			university: university,
			faculty: faculty,
		}))
	} catch (error) {
		yield put(addCertificate(docHash, null))
		handleError(error)
	}
}

function * NetworkSaga () {
	yield all([
		takeEvery(LOAD_ALL_NETWORK_DATA, getAllNetworks),
		takeEvery(LOAD_NETWORK_DATA, getNetworkData),
		takeEvery(LOAD_LIST_PARTICIPANTS, getListParticipants),
		takeEvery(LOAD_CERTIFICATE, getCertificate),
	])
}

export default NetworkSaga
