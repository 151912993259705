import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { Card, CardBody } from 'reactstrap'
import _ from 'lodash'
import { toggleModal } from '@Store/template/actions'
import { Trans } from 'react-i18next'
import ButtonShare from '@Atoms/ButtonShare'
import JoinerBonusLine from '@Atoms/JoinerBonusLine'
import PropTypes from 'prop-types'

class CardInvite extends React.Component {

	constructor (props) {
		super(props)
		this.state = {
			showAsCard: props.showAsCard || false,
			user: props.user,
			buttonShare: props.buttonShare
		}
	}

	componentDidUpdate (prevProps, prevState, snapshot) {
		if (!_.isEqual(this.props.user, prevProps.user)) this.setState({ user: this.props.user })
	}

	render () {
		const { user } = this.state
		const { buttonShare } = this.props

		if (!user) return null

		return (
			<Card className="card-top-features">
				<CardBody className="d-flex flex-column">
					<h3 className="caption text-center mb-3 font-weight-bold">
						<Trans i18nKey="cardInvite.title"/>
					</h3>
					<ButtonShare color={buttonShare} fullwidth type="inviteFriend"/>
					<JoinerBonusLine bold/>
				</CardBody>
			</Card>
		)
	}
}

CardInvite.defaultProps = {
	buttonShare: 'primary'
}

CardInvite.propTypes = {
	buttonShare: PropTypes.string,
}

const mapStateToProps = state => {
	return {
		user: state.User.user,
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			toggleModal
			// add other watcher sagas to this object to map them to props
		},
		dispatch
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CardInvite))
