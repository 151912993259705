import { Trans } from 'react-i18next'
import React, { useEffect } from 'react'
import { getArrowDelta, getBadge } from '@Helpers/global'
import UrgencyFlames from '@Atoms/urgencyFlames'
import Badge from '@Atoms/badge'
import { MODAL_VOTE_URGENCY } from '@Modals/ModalInfos/modals'
import { useDispatch } from 'react-redux'
import { toggleModal } from '@Store/actions'
import { NUM_PARTICIPANTS_TO_SHOW } from '@Constants/constants'

const LeaderboardVoters = props => {

	const { network, user } = props

	useEffect(() => {}, [network, user])

	const getBonusValue = (position, highlight) => {
		switch (position) {
			case 0:
				if (highlight) return (<span className="font-weight-bold">15%</span>)
				return '15%'
			case 1:
				if (highlight) return (<span className="font-weight-bold">10%</span>)
				return '10%'
			case 2:
				if (highlight) return (<span className="font-weight-bold">5%</span>)
				return '5%'
		}
	}

	const addFakeParticipants = (count) => {
		const listFakeNames = ['Carlo Martini', 'Luca Mastrostefano', 'Antonio Romano', 'Valentina Ferretti']
		let elems = []
		for (let i = 0; i < count; i++) {
			elems.push(
				<RowVoter
					key={i + 5}
					user={{
						name: listFakeNames[i],
						delta: 0,
						//goal: CARD_COLLECTIVE_GOALS.GOALS[0],
						score: 0
					}}
					isCurrent={false}
					style={{ filter: 'blur(3px)' }}
				/>
			)
		}
		return elems.length === 0 ? null : elems
	}

	const RowVoter = props => {
		return (
			<tr style={props.style}>
				<td className="text-start caption">
					{props.k + 1}.&nbsp;
					{getArrowDelta(props.user.delta)}&nbsp;
					{/* Print name of student */}
					{props.user.hired && (<span className="font-weight-bold text-success">{props.user.name}&nbsp;🎉</span>)}
					{!props.user.hired && props.isCurrent && (<span className="font-weight-bold text-success">{props.user.name}</span>)}
					{!props.user.hired && !props.isCurrent && (props.user.name)}

					{/* Print icon next to the student */}
					{!props.user.hired && props.user.goal && (
						<Badge width={16} color={getBadge(props.user.goal).icon} small className="d-inline-flex ms-1"/>
					)}

				</td>
				<td className="caption">{getBonusValue(props.k, props.isCurrent)}</td>
				<td className={`text-end ${props.isCurrent ? 'text-success font-weight-bold' : ''}`}>{Math.round(props.user.score) || ''}</td>
			</tr>
		)
	}

	if (
		!user.voterRank ||
		!network.votes
	) return null

	/* My rank on next invite */
	let nextInviteScore = 0
	let nextInvitePosition = 0
	if (user.voterRank.votesScore[0]) {
		nextInviteScore = user.voterRank.rankMe.score + user.voterRank.votesScore[0].score
		for (const k in network.leaderboardVoters) {
			if (network.leaderboardVoters[k].score < nextInviteScore) {
				nextInvitePosition = parseInt(k) + 1
				break
			}
		}
	}

	const dispatch = useDispatch()
	const toggleModalVoteUrgency = (data) => dispatch(toggleModal(MODAL_VOTE_URGENCY, { data }))

	return (
		<React.Fragment>
			<h3 className="caption-small mb-2"><Trans i18nKey="cardLeaderboardVoters.subtitle"/></h3>
			<table className="table table-responsive table-condensed table-borderless table-leaderboard table-no-pointer">
				<thead>
				<tr>
					<th className="caption-small font-weight-regular py-2 px-1 text-start"><Trans i18nKey="cardLeaderboardVoters.bestPeople"/></th>
					<th className="caption-small font-weight-regular py-2 px-1 text-center" width="20px"><Trans i18nKey="cardLeaderboardVoters.bonus"/></th>
					<th className="caption-small font-weight-regular py-2 px-1 text-center" width="20px"><Trans i18nKey="cardLeaderboardVoters.score"/></th>
				</tr>
				</thead>
				<tbody>
				{network.leaderboardVoters.map((u, k) => {
					const isCurrent = k + 1 === user.voterRank.rankMe.position
					return (
						<RowVoter key={k} k={k} isCurrent={isCurrent} user={u}/>
					)
				})}
				{addFakeParticipants(NUM_PARTICIPANTS_TO_SHOW - network.leaderboardVoters.length)}
				</tbody>
			</table>


			{/* TODO fix the next table as it exploded! */}
			{/*<table className="table table-responsive table-condensed table-borderless table-leaderboard table-no-pointer mb-0">
				<thead>
				<tr>
					<th className="caption-small font-weight-regular py-2 px-1 text-start"><Trans i18nKey="cardLeaderboardVoters.yourVotes"/></th>
					<th className="caption-small font-weight-regular py-2 px-1 text-center"><Trans i18nKey="cardLeaderboardVoters.urgency"/></th>
					<th className="caption-small font-weight-regular py-2 px-1 text-center" width="20px"><Trans i18nKey="cardLeaderboardVoters.score"/></th>
				</tr>
				</thead>
				<tbody>
				{user.voterRank.votesScore.map((v, k) => {
					const vote = network.votes.filter(vote => vote.name === v.name)[0]

					return (
						<tr key={k}>
							<td className="text-start caption">{v.name}</td>
							<td>
								<UrgencyFlames vote={vote} emptyChar="-"/>
							</td>
							{vote.active ? (
								<td className="text-end caption-small text-success">+{Math.round(v.score)}</td>
							) : (
								<td
									className="text-end caption-small text-secondary text-decoration-underline clickable"
									onClick={() => toggleModalVoteUrgency({
										score: Math.round(v.score),
										vote: vote,
										reward: vote.totalReward,
										name: vote.name,
										numberVoted: vote.count,
									})}
								>
									<Trans i18nKey="cardLeaderboardVoters.unlock" values={{ score: Math.round(v.score) }}/>
								</td>
							)}
						</tr>
					)
				})}
				<tr>
					<td colSpan={3} className="text-end caption-small">
						<Trans i18nKey="cardLeaderboardVoters.nextInvite" values={{ position: nextInvitePosition }}/>
					</td>
				</tr>
				</tbody>
			</table>*/}
		</React.Fragment>
	)
}

export default LeaderboardVoters
