import React from 'react'
import { Card, CardBody } from 'reactstrap'
import PropTypes from 'prop-types'

const CardText = props => {

	return (
		<Card className={props.className} onClick={props.onClick}>
			<CardBody className={`text-start ${props.centered && 'text-center'}`}>
				{props.title && (
					<div className="h6 mb-2">{props.title}</div>
				)}
				<div className={`${props.captionClassName} ${props.small ? 'caption-small' : ''}`}>{props.text}</div>
			</CardBody>
		</Card>
	)
}

CardText.propTypes = {
	title: PropTypes.any,
	text: PropTypes.any.isRequired,
	small: PropTypes.bool,
	centered: PropTypes.bool,
	onClick: PropTypes.func,
	className: PropTypes.string,
	captionClassName: PropTypes.string,
}

export default CardText
