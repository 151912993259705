import React from 'react'
import { Modal } from 'reactstrap'
import PropTypes from 'prop-types'

const BackdropContent = props => {

	const { isOpen, toggle, children } = props

	return (
		<Modal
			isOpen={isOpen}
			toggle={toggle}
			className="modal-dialog-centered modal-dialog-scrollable backdrop-content"
			backdropClassName="modal-backdrop-darker"
			backdrop="static"
		>
			{children}
		</Modal>
	)
}

BackdropContent.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	children: PropTypes.node.isRequired,
}


export default BackdropContent
