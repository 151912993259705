import { all, call, fork, put, select, take, takeEvery } from 'redux-saga/effects'
import { PUSH_NOTIFICATIONS_CHECK, PUSH_NOTIFICATIONS_GRANTED, PUSH_NOTIFICATIONS_INIT_PROMPT } from '@Store/pushNotifications/actionTypes'
import OneSignal from 'react-onesignal'
import { eventChannel } from 'redux-saga'
import { pushNotificationsGranted } from '@Store/pushNotifications/actions'
import axios from 'axios'
import { getHeaders } from '@Store/helpers'
import _ from 'lodash'

const getStateUser = (state) => state.User.user

function * whatchNotifyEventChannel (client) {
	try {
		const channel = eventChannel(emitter => {
			client.on('subscriptionChange', (authorized) => emitter(authorized))
			return () => {
				client.close().then(() => (console.log('logout')))
			}
		})
		while (true) {
			const authorized = yield take(channel)
			if (authorized) yield all([
				call(() => axios.post(`${process.env.REACT_APP_ENDPOINT_API_USER}/v1/notifications/onesignal`, {}, getHeaders())),
				put(pushNotificationsGranted())
			])
		}
	} catch (error) {}
}

function * subscribePushNotifications () {
	try {
		yield fork(whatchNotifyEventChannel, OneSignal)

		OneSignal.showSlidedownPrompt({ force: true })
	} catch (error) {}
}

function * checkOneSignalNotifications () {
	try {
		const isNotificationsEnabled = yield call(() => OneSignal.isPushNotificationsEnabled())
		if (isNotificationsEnabled) {
			yield all([
				call(() => axios.post(`${process.env.REACT_APP_ENDPOINT_API_USER}/v1/notifications/onesignal`, {}, getHeaders())),
				put(pushNotificationsGranted())
			])
		}
	} catch (error) {}
}

function * setDataPushNotificationsGranted () {
	try {

		const dataUser = _.cloneDeep(yield select(getStateUser))
		yield call(() => OneSignal.setExternalUserId(dataUser.id))

	} catch (error) {}
}

function * NotificationsSaga () {
	yield all([
		takeEvery(PUSH_NOTIFICATIONS_INIT_PROMPT, subscribePushNotifications),
		takeEvery(PUSH_NOTIFICATIONS_CHECK, checkOneSignalNotifications),
		takeEvery(PUSH_NOTIFICATIONS_GRANTED, setDataPushNotificationsGranted)
	])
}

export default NotificationsSaga
