import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Modal from '@Modals/Modal'
import Cards from '@Cards'
import { MODAL_COLLECTIVE_GOAL } from '@Modals/ModalPages/modals'
import { Trans } from 'react-i18next'

const ModalCollectiveGoal = props => {

	const userData = useSelector(state => state.User.user)
	const networkData = useSelector(state => state.Network.network)

	const [user, setUser] = useState(userData)
	const [network, setNetwork] = useState(networkData)

	useEffect(() => setUser(userData), [userData])
	useEffect(() => setNetwork(networkData), [networkData])

	if (
		!user ||
		!network ||
		!network.university ||
		!network.faculty ||
		!network.participants
	) return null

	return (
		<Modal type="modal-mobile-full" name={MODAL_COLLECTIVE_GOAL}>
			<img alt="" width={40} src={network.university.logo} style={{ borderRadius: '10px' }}/>
			<div className="title">
				{network.faculty.name}
			</div>
			<div className="title" style={{ fontSize: '24px' }}>
				<Trans i18nKey="modalCollectiveGoal.title"/>
			</div>
			<Cards.CardVerifyEmailShort/>
			<Cards.CardGoal/>
			<Cards.CardCollectiveGoals/>
			<Cards.CardShare type="ReachTheGoal"/>
			<Cards.CardParticipantsList/>
		</Modal>
	)
}

export default ModalCollectiveGoal
