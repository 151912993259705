import React, { useEffect, useState } from 'react'
import { Trans } from 'react-i18next'
import { AMBASSADOR_LEVELS, CARD_COLLECTIVE_GOALS, CARD_SHARE } from '@Constants/constants'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { formatCurrency } from '@Helpers/number_helper'

const Feed = props => {

	const { data: dataFeed, type, short, showTimeLeft, className } = props

	const data = _.cloneDeep(dataFeed)

	const entryBonusCountdownData = useSelector(state => state.User.entryBonusCountdown)
	const [timeLeft, setTimeLeft] = useState(entryBonusCountdownData)
	useEffect(() => setTimeLeft(entryBonusCountdownData), [entryBonusCountdownData])

	if (!data) return null
	const getTimeLeft = () => {
		console.log(timeLeft)
		if (!timeLeft) return null
		if (timeLeft.days > 0) return <Trans i18nKey={`feed.COUNTDOWN.timeLeft.day`} values={{val: timeLeft.days }} count={timeLeft.days}/>
		else if (timeLeft.hours > 0) return <Trans i18nKey={`feed.COUNTDOWN.timeLeft.hour`} values={{val: timeLeft.hours }} count={timeLeft.hours}/>
		else if (timeLeft.minutes > 0) return <Trans i18nKey={`feed.COUNTDOWN.timeLeft.minute`} values={{val: timeLeft.minutes }} count={timeLeft.minutes}/>
		else if (timeLeft.seconds > 0) return <Trans i18nKey={`feed.COUNTDOWN.timeLeft.second`} values={{val: timeLeft.seconds }} count={timeLeft.seconds}/>
		return null
	}

	const objData = {
		...data,
		ambassadorLevel: AMBASSADOR_LEVELS[data.parameters?.numInvites]?.label || '',
		ambassadorInvites: AMBASSADOR_LEVELS[data.parameters?.numInvites]?.numInvites || '',
		ambassadorPercentage: AMBASSADOR_LEVELS[data.parameters?.numInvites]?.sharePercentage || '',

		amountInvited: formatCurrency(CARD_SHARE.AMOUNT_EXTERNAL_SHARE, 'USD'),

		groupGoalLabel: CARD_COLLECTIVE_GOALS.GOALS.find(v => v.value === data.parameters?.numPeople)?.title || '',
		groupGoalAmount: formatCurrency(data.parameters?.reward, 'USD'),
	}



	return (
		<div className={`feed ${className}`}>
			<div className="type">
				<div className="text-uppercase">
					<Trans
						i18nKey={`feed.${type}.type`}
						components={{
							emoji: <span style={{fontSize: '13px'}}/>,
							icon: <i className="fa fa-circle text-danger blink"/>,
							red: <span className="text-danger"/>,
						}}
					/>
				</div>
				{showTimeLeft && type === 'COUNTDOWN' && (
					<div>
						{getTimeLeft()}
					</div>
				)}
			</div>
			<div className="h8 title">
				<Trans i18nKey={`feed.${type}.title`} values={objData} />
			</div>
			{!short && (
				<div className="content">
					<Trans i18nKey={`feed.${type}.content`} values={objData} />
				</div>
			)}
		</div>
	)
}

Feed.defaultProps = {
	short: false,
	showTimeLeft: false,
}

Feed.propTypes = {
	data: PropTypes.object,
	type: PropTypes.string,
	short: PropTypes.bool,
	showTimeLeft: PropTypes.bool,
	className: PropTypes.string,
}

export default Feed
