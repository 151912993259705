import React from 'react'
const routes = {
	HOME: {
		name: 'home',
		title: 'Home',
		menuTitle: 'page.HOME',
		route: '/',
		location: '/',
		icon: 'bx bx-calendar-edit'
	},
	RANK: {
		name: 'your-rank',
		title: 'Your Rank',
		menuTitle: 'page.RANK',
		route: '/your-rank',
		location: '/your-rank',
		icon: 'bx bx-award'
	},
	JOBS: {
		name: 'jobs',
		title: 'Jobs',
		menuTitle: 'page.JOBS',
		route: '/jobs',
		location: '/jobs',
		icon: 'bx bx-chevron-left-circle'
	},
	LOGIN: {
		name: 'login',
		title: 'Login',
		menuTitle: 'page.LOGIN',
		route: '/login',
		location: '/login',
		icon: ''
	},
	LOGOUT: {
		name: 'logout',
		title: 'Logout',
		menuTitle: 'page.LOGOUT',
		route: '/logout',
		location: '/logout',
		icon: ''
	},
	LOGIN_WITH_TOKEN: {
		name: 'login-with-token',
		title: 'Login',
		menuTitle: 'page.LOGIN_WITH_TOKEN',
		route: '/login-with-token',
		location: '/login-with-token',
		icon: ''
	},
	RESET_PASSWORD: {
		name: 'reset-password',
		title: 'Reset Password',
		menuTitle: 'page.RESET_PASSWORD',
		route: '/reset-password',
		location: '/reset-password',
		icon: ''
	},
	CERTIFICATE_OPEN: {
		name: 'certificate',
		title: 'Certificate',
		menuTitle: 'page.CERTIFICATE_OPEN',
		route: '/certificate/:hashDoc/:user',
		location: '/certificate',
		icon: ''
	},
	CONTACT_ME_EMAIL_OPEN: {
		name: 'contact-me-email',
		title: 'Contact Me',
		menuTitle: 'page.CONTACT_ME_EMAIL_OPEN',
		route: '/contact-me/:idUser/:referrer/:type?',
		location: '/contact-me',
		icon: ''
	},
	REFER_COLLEAGUE_OPEN: {
		name: 'refer-colleague',
		title: 'Refer Collague',
		menuTitle: 'page.REFER_COLLEAGUE_OPEN',
		route: '/refer-colleague/:referrer/:type?',
		location: '/refer-colleague',
		icon: ''
	},
	MY_INVITES: {
		name: 'my-invites',
		title: 'My Invites',
		menuTitle: '',
		route: '*/my-invites',
		location: '',
		icon: ''
	},
	LIST_FEEDS: {
		name: 'my-feed',
		title: 'My Feed',
		menuTitle: '',
		route: '/my-feed',
		location: '/my-feed',
		icon: ''
	}
}
export default routes
