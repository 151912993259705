import { OPEN_SHARE, SHARE_CERTIFICATE } from '@Store/share/actionTypes'

export const openShare = (customUrl = null, customMessage = null) => ({
	type: OPEN_SHARE,
	payload: {
		customUrl,
		customMessage
	}
})

export const shareCertificate = (urlPng, urlPage, message) => ({
	type: SHARE_CERTIFICATE,
	payload: {
		urlPng,
		urlPage,
		message,
	}
})
