import React from 'react'
import { Trans } from 'react-i18next'
import Vote from '@Molecules/Vote/Vote'

const SecureTheVoteInvited = props => {

	const { vote } = props

	return (
		<>
			<div className="b1 mb-4">
				<Trans
					i18nKey="FlowUnlockVote.SECURE_THE_VOTE_INVITED.text"
					values={{ name: vote.vote }}
					components={{
						span: <span className="h7"/>
					}}
				/>
			</div>
			<div className="flow-title overline text-white">
				<Trans i18nKey="FlowUnlockVote.SECURE_THE_VOTE_INVITED.title" />
			</div>
			<Vote data={vote}/>
		</>
	)
}

export default SecureTheVoteInvited
