import {ReactComponent as EnvelopeSendIcon} from '@Assets/images/envelope_send.svg'
import PropTypes from 'prop-types'

const EnvelopeSend = props => {

	return (
		<EnvelopeSendIcon className={props.className} style={props.style} />
	)
}

EnvelopeSend.propTypes = {
	className: PropTypes.string,
	style: PropTypes.object,
}

export default EnvelopeSend
