import React from 'react'
import Modal from '@Modals/Modal'
import { Trans } from 'react-i18next'
import InfoTitle from '@Atoms/InfoTitle'
import ButtonShare from '@Atoms/ButtonShare'
import { MODAL_CONTEST } from '@Modals/ModalInfos/modals'
import { useSelector } from 'react-redux'
import moment from 'moment'

const ModalContest = props => {

	const network = useSelector(state => state.Network.network)

	if (!network) return null

	const getButton = (contest) => {
		if (!contest?.startedAt) return <ButtonShare color="primary" fullwidth type="inviteStartContest"/>
		if(moment(contest.endedAt) <= moment()) return <ButtonShare color="primary" fullwidth type="inviteFriend"/>
		return <ButtonShare color="primary" fullwidth type="inviteIncreaseContest"/>
	}

	return (
		<Modal type="modal-mobile-center" name={MODAL_CONTEST} centered>
			<InfoTitle text="Contest"/>
			<div className="caption mb-2">
				<Trans i18nKey="modalContest.content"/>
			</div>
			<div className="px-3">
				{getButton(network.contest)}
			</div>
		</Modal>
	)
}

export default ModalContest

