import {all, call, put, takeEvery} from 'redux-saga/effects'
import axios from 'axios'
import {getHeaders, handleError} from '@Store/helpers'
import {loadFeedsSuccess} from '@Store/feeds/actions'
import {LOAD_FEEDS} from '@Store/feeds/actionTypes'
import {FEEDS} from '@Constants/constants'
import _ from 'lodash'

function* getFeeds() {
    try {
        const {data} = yield call(() => axios.get(`${process.env.REACT_APP_ENDPOINT_API_USER}/v1/feeds`, getHeaders()))

        yield put(loadFeedsSuccess(data))
    } catch (error) {
        handleError(error)
    }
}

function* FeedsSaga() {
    yield all([
        takeEvery(LOAD_FEEDS, getFeeds),
    ])
}

export default FeedsSaga
