import React, { useEffect, useState } from 'react'
import { Button, ButtonGroup, Card, CardBody, Col } from 'reactstrap'
import { Trans } from 'react-i18next'
import TableJobOffers from '@Molecules/TableJobOffers'
import { Link } from 'react-router-dom'
import { jobFinance, jobTech } from '@Data/jobOffers'
import { useDispatch, useSelector } from 'react-redux'
import routes from '@Routes/routeConfig'
import { changePage } from '@Store/template/actions'
import Cards from '@Cards'

const Jobs = props => {

	const dispatch = useDispatch()

	const userData = useSelector(state => state.User.user)
	const pageData = useSelector(state => state.Template.page)

	const [user, setUser] = useState(userData)
	const [jobTabActive, setJobTabActive] = useState('tech')
	const [page, setPage] = useState(pageData)

	useEffect(() => setUser(userData), [userData])
	useEffect(() => setPage(pageData), [pageData])

	useEffect(() => {
		if (page?.route !== routes.JOBS.route) dispatch(changePage(routes.JOBS))
		window.dataLayer.push({
			event: 'pageview',
			page: { url: routes.JOBS.route, title: 'Page Jobs' }
		})
	}, [])

	if (
		!user ||
		!user.inviteCode
	) return null

	const jobOffers = jobTabActive === 'tech' ? jobTech : jobFinance
	const linkKnowMore = `/contact-me/${user.id}/${user.inviteCode.code}/${jobTabActive === 'tech' ? 't' : 'f'}`

	return (
		<Col xs={12}>
			<Cards.CardJobs/>
			<div className="d-flex flex-column align-items-center">
				<Card>
					<CardBody>
						<div className="">
							<div className="h5">
								{jobTabActive === 'tech' ? (
									<Trans i18nKey="pageJobs.subtitleTech"/>
								) : (
									<Trans i18nKey="pageJobs.subtitleFinance"/>
								)}
							</div>
							<p className="caption-big mb-5"><Trans i18nKey="pageJobs.jobOffers.title"/></p>
							<div className="text-center mb-4">
								<ButtonGroup className="btn-rounded w-75 btn-no-focus mb-3">
									<Button
										type="button"
										color="primary"
										outline
										active={jobTabActive === 'tech'}
										onClick={() => setJobTabActive('tech')}
										className="w-50"
									>
										<Trans i18nKey="pageJobs.jobOffers.tech"/>
									</Button>
									<Button
										type="button"
										color="primary"
										outline
										active={jobTabActive === 'finance'}
										onClick={() => setJobTabActive('finance')}
										className="w-50"
									>
										<Trans i18nKey="pageJobs.jobOffers.finance"/>
									</Button>
								</ButtonGroup>
							</div>
						</div>
						<TableJobOffers jobOffers={jobOffers}/>
						<p className="text-center caption-big text-primary font-weight-bold">...and more</p>
						<div className="d-flex mb-3">
							<div className="body-small m-auto">
								<Trans i18nKey="pageJobs.jobOffers.footer"/>
							</div>
						</div>
						<div className="text-center">
							<Link to={linkKnowMore} className="btn btn-primary btn-rounded w-75"><Trans i18nKey="pageJobs.jobOffers.CTA"/></Link>
						</div>
					</CardBody>
				</Card>
			</div>
		</Col>
	)
}

export default Jobs
