import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import { openShare, toggleModal } from '@Store/actions'
import { Card, CardBody } from 'reactstrap'
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined'
import ShareRoundedIcon from '@mui/icons-material/ShareRounded'
import { MODAL_REFERRAL } from '@Modals/ModalPages/modals'
import { Trans } from 'react-i18next'

class CardReferral extends React.Component {

	constructor (props) {
		super(props)
		this.state = {
			user: props.user,
		}
	}

	componentDidUpdate (prevProps, prevState, snapshot) {
		if (!_.isEqual(this.props.user, prevProps.user)) this.setState({ user: this.props.user })
	}

	render () {
		const { user } = this.state

		if (
			!user ||
			!user.referrals
		) return null

		if (!user.verified) return null

		// Count referrals Accepted
		const referralsAccepted = user.referrals.items.reduce((num, item) => item.verified ? ++num : num, 0)
		const referralsPending = user.referrals.items.length - referralsAccepted

		return (
			<React.Fragment>
				<Card className="align-self-center card-referral clickable">
					<CardBody className="d-flex flex-row">
						<div className="d-flex justify-content-center align-items-center" onClick={() => this.props.toggleModal(MODAL_REFERRAL)}>
							<InsertChartOutlinedIcon style={{ width: '24px', height: '24px' }}/>
						</div>
						<div
							className="d-flex flex-column flex-grow-1 text-center justify-content-center align-items-center"
							style={{
								borderLeft: '1px solid #dedede',
								borderRight: '1px solid #dedede',
								margin: '0 16px'
							}}
							onClick={() => this.props.toggleModal(MODAL_REFERRAL)}
						>
							<div className="caption"><Trans i18nKey="cardReferral.title"/></div>
							<div className="caption-small">
								<Trans
									i18nKey="cardReferral.subtitle"
									values={{
										visits: user.referrals.visits || 0,
										referralsPending,
										referralsAccepted
									}}
								/>
							</div>
						</div>
						<div className="d-flex justify-content-center align-items-center" onClick={() => this.props.openShare()}>
							<ShareRoundedIcon style={{ width: '24px', height: '24px' }}/>
						</div>

					</CardBody>
				</Card>
			</React.Fragment>
		)
	}
}

const mapStateToProps = state => {
	return {
		user: state.User.user,
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			toggleModal,
			openShare
			// add other watcher sagas to this object to map them to props
		},
		dispatch
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CardReferral))
