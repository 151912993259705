import { Trans } from 'react-i18next'
import React from 'react'

export const jobTech = [
	{
		company: 'Leaf Space',
		logo: `${process.env.REACT_APP_ASSETS}/img/companies/leaf-space.png`,
		description: <Trans i18nKey="companies.leafspace" ns="jobs"/>,
		openPositions: ['Junior site reliability engineer', 'Junior backend software developer', 'Junior mission manager']
	},
	{
		company: 'AnotheReality',
		logo: `${process.env.REACT_APP_ASSETS}/img/companies/anothereality.png`,
		description: <Trans i18nKey="companies.anothereality" ns="jobs"/>,
		openPositions: ['Junior game developer']
	},
	{
		company: 'Contents',
		logo: `${process.env.REACT_APP_ASSETS}/img/companies/contents.png`,
		description: <Trans i18nKey="companies.contents" ns="jobs"/>,
		openPositions: ['Junior data scientist (NLP)', 'Junior product owner']
	}
]

export const jobFinance = [
	{
		company: 'Be Shaping the Future',
		logo: `${process.env.REACT_APP_ASSETS}/img/companies/be.png`,
		description: <Trans i18nKey="companies.be" ns="jobs"/>,
		openPositions: ['Junior business analyst']
	},
	{
		company: 'Coverzen',
		logo: `${process.env.REACT_APP_ASSETS}/img/companies/coverzen.png`,
		description: <Trans i18nKey="companies.coverzen" ns="jobs"/>,
		openPositions: ['Junior account manager']
	},
	{
		company: 'Fiscozen',
		logo: `${process.env.REACT_APP_ASSETS}/img/companies/fiscozen.png`,
		description: <Trans i18nKey="companies.fiscozen" ns="jobs"/>,
		openPositions: ['Junior business intelligence', 'Juior business developer']
	},
	{
		company: 'TechBricks',
		logo: `${process.env.REACT_APP_ASSETS}/img/companies/techbricks_logo.jpg`,
		description: <Trans i18nKey="companies.techbricks" ns="jobs"/>,
		openPositions: ['Female founder']
	}
]
