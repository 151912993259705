import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { pushNotificationsInitPrompt } from '@Store/actions'

class AskNotification extends React.Component {
	constructor (props) {
		super(props)
		this.state = {
			children: props.children,
			notificationsEnabled: props.notificationsEnabled,
		}
	}

	componentDidUpdate (prevProps, prevState, snapshot) {
		if (!_.isEqual(this.props.children, prevProps.children)) this.setState({ children: this.props.children })
		if (!_.isEqual(this.props.notificationsEnabled, prevProps.notificationsEnabled)) this.setState({ notificationsEnabled: this.props.notificationsEnabled })
	}

	render () {
		/* Disabled */
		return null
		const { notificationsEnabled } = this.state


		if(
			notificationsEnabled
		) return null

		return (
			<React.Fragment>
				<div onClick={() => this.props.pushNotificationsInitPrompt()}>
					{this.state.children}
				</div>
			</React.Fragment>
		)
	}
}

AskNotification.propTypes = {
	children: PropTypes.any.isRequired,
}

const mapStateToProps = state => {
	return {
		notificationsEnabled: state.PushNotifications.notificationsEnabled,
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			pushNotificationsInitPrompt
			// add other watcher sagas to this object to map them to props
		},
		dispatch
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AskNotification))
