import { LOADED_REMOTE_DATA } from '@Store/remoteStorage/actionTypes'

const INIT_STATE = {
	remoteStorage: false
}

const RemoteStorage = (state = INIT_STATE, action) => {
	switch (action.type) {
		case LOADED_REMOTE_DATA:
			return {
				...state,
				remoteStorage: {
					...state.remoteStorage,
					...action.payload
				}
			}
		default:
			return state
	}
}

export default RemoteStorage
