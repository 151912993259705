import i18n from 'i18n'

export const shareMessage = (referralCode, usePrompt = false) => {
	const urlShare = shareLink(referralCode)

	const text = usePrompt ? 'cardShare.WriteNamesShareMessagePrompt' : 'cardShare.WriteNamesShareMessage'

	return i18n.t(text, { link: urlShare })
}

export const shareLink = (referralCode) => {
	return `${process.env.REACT_APP_WIRAKI_WEBSITE}/invite/${referralCode}`
}

export const shareLinkedin = url => {
	return `https://www.linkedin.com/shareArticle?mini=true&url=${url}`
}
