import { ADD_CERTIFICATE, SAVE_USER_DATA, SAVE_USER_DATA_SAGA, UPDATE_ENTRY_BONUS_COUNTDOWN } from '@Store/user/actionTypes'

const INIT_STATE = {
	user: {
		wRank: {
			rank: 0
		},
		voterRank: {},
		certificates: {}
	},
	entryBonusCountdown: null
}

const User = (state = INIT_STATE, action) => {
	switch (action.type) {
		case SAVE_USER_DATA_SAGA:
		case SAVE_USER_DATA:
			return {
				...state,
				user: {
					...state.user,
					...action.payload
				}
			}
		case ADD_CERTIFICATE:
			return {
				...state,
				user: {
					...state.user,
					certificates: {
						...state.user.certificates,
						[action.payload.certificateCode]: action.payload.data
					}
				}
			}
		case UPDATE_ENTRY_BONUS_COUNTDOWN:
			return {
				...state,
				entryBonusCountdown: action.payload
			}
		default:
			return state
	}
}

export default User
