import { all, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { SAVE_USER_DATA, SAVE_USER_DATA_SAGA } from '@Store/user/actionTypes'
import { MODAL_FIRST_VOTE, MODAL_WELCOME_VERIFY_EMAIL } from '@Modals/ModalInfos/modals'
import { balanceResetData, changeLayout, saveRemoteData, toggleModal, tutorialClaimEnable } from '@Store/actions'
import { LOADED_REMOTE_DATA } from '@Store/remoteStorage/actionTypes'
import { SAVE_LIST_PARTICIPANTS } from '@Store/network/actionTypes'
import _ from 'lodash'
import { CLOSE_WELCOME_PAGE, TOGGLE_MODAL } from '@Store/template/actionTypes'

const getStateUser = (state) => state.User.user
const getStateRemoteData = (state) => state.RemoteStorage.remoteStorage
const getTemplateData = (state) => state.Template.modal

function * initialModals () {
	try {
		let dataUser = _.cloneDeep(yield select(getStateUser))
		let dataRemoteStorage = _.cloneDeep(yield select(getStateRemoteData))
		let dataTemplate = _.cloneDeep(yield select(getTemplateData))

		/* If remoteStorage or dataUser is not initialized, return */
		if (!dataRemoteStorage || !dataUser) return

		/* REMOVE MODAL VERIFIED */
		if (dataUser.verified && (!dataTemplate[MODAL_WELCOME_VERIFY_EMAIL] || dataTemplate[MODAL_WELCOME_VERIFY_EMAIL]?.open)) {
			yield put(toggleModal(MODAL_WELCOME_VERIFY_EMAIL, { open: false }))
		}

		if (dataUser.verified === false) {
			/* SHOW INITIAL MODAL */
			return yield put(toggleModal(MODAL_WELCOME_VERIFY_EMAIL, { dismissable: false }))
		}

	} catch (error) {}
}

function * checkChangeLayout ({ payload: listParticipants }) {
	if (listParticipants.length >= 12) {
		yield put(changeLayout('fullNetwork'))
	}
}

/* CALL FUNCTION TO INITIALIZE MODALS DATA AFTER HAS BEEN CLOSED BY USER */
function * cleanDataOnCloseModal (payload) {
	if (payload.status === false) {
		yield all([
			put(balanceResetData())
		])
	}
}

function * triggerCloseWelcomePage () {
	try {
		const actions = []
		actions.push(put(saveRemoteData({ completedWelcome: true })))
		if(!getStateRemoteData.tutorialClaim) actions.push(put(tutorialClaimEnable()))

		yield all(actions)
	} catch (error) {}
}

function * TemplateSaga () {
	yield all([
		takeEvery(TOGGLE_MODAL, cleanDataOnCloseModal),
		takeLatest(SAVE_USER_DATA, initialModals),
		takeLatest(SAVE_USER_DATA_SAGA, initialModals),
		takeLatest(LOADED_REMOTE_DATA, initialModals),
		takeEvery(CLOSE_WELCOME_PAGE, triggerCloseWelcomePage),

		takeLatest(SAVE_LIST_PARTICIPANTS, checkChangeLayout),
	])
}

export default TemplateSaga
