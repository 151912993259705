import React from 'react'
import PropTypes from 'prop-types'

const TableJobOffers = props => {

	const { jobOffers } = props

	return (
		<table className="table-no-pointer" align="center" width="100%" cellPadding="0" cellSpacing="0">
			<tbody>
			{jobOffers.map((offer, k) => (
				<tr key={k}>
					<td style={{ verticalAlign: 'top', paddingTop: '20px' }}>
						<img alt="" width="75px" src={offer.logo}/>
					</td>
					<td style={{ width: '20px' }}/>
					<td className="text-left">
						<p className="mb-1"><strong>{offer.company}</strong></p>
						<p>{offer.description}</p>
						<p>Open positions:
						{offer.openPositions.map((pos, key) => (
							<React.Fragment key={key}>
								<br/><i>- {pos}</i>
							</React.Fragment>
						))}
						</p>
					</td>
				</tr>
			))}
			</tbody>
		</table>
	)
}

TableJobOffers.propTypes = {
	jobOffers: PropTypes.array.isRequired,
}

export default TableJobOffers
