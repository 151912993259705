import { EMAIL_VERIFY_SENT, RESULT_SUBMIT_REQUEST_REWARD, TUTORIAL_CLAIM_DISABLE, TUTORIAL_CLAIM_ENABLE } from '@Store/global/actionTypes'

const INIT_STATE = {
	emailVerifySent: false,
	action: '',
	tutorialClaim: false
}

const Global = (state = INIT_STATE, action) => {
	switch (action.type) {

		case RESULT_SUBMIT_REQUEST_REWARD:
			return {
				...state,
				rewardSubmitted: action.payload.error === '',
			}
		case EMAIL_VERIFY_SENT:
			return {
				...state,
				emailVerifySent: true
			}
		case TUTORIAL_CLAIM_ENABLE:
			return {
				...state,
				tutorialClaim: true
			}
		case TUTORIAL_CLAIM_DISABLE:
			return {
				...state,
				tutorialClaim: false
			}
		default:
			return state
	}
}

export default Global
